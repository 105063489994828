import React, { useCallback, useEffect, useRef, useState } from 'react'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import { Button, Card, Col, Collapse, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import './index.css'
import { AlertCircle, Check, ChevronDown, ChevronRight, Eye, X } from 'react-feather'
import { cancelReason, customerProducts, orderNotes, trackingDetails } from '../../../ApiConfigs/ApiConfig'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import MyOrderCard, { getDeliveryStatus } from '../MyOrderCard'
import { MdOutlineFeedback } from "react-icons/md";
import CancelModal from './CancelModal'
import { convertTo12HourFormat, formatDate } from '..'
import ChangeModel from '../changeAddress'
import ReviewModal from './ReviewModal'
import UpdateReviewModal from './UpdateReviewModal'
import { multiImageUpload } from '../../admin/upload'
import { RiImageAddFill } from 'react-icons/ri'
import Preview from '../preview'

function ViewOrderDetail() {
    const [dataa, setData] = useState([]);
    const [trackingData, setTrackingData] = useState();
    const [cancelData, setCancelData] = useState();
    const [loading, setLoading] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openChangeAddressModal, setOpenChangeAddressModal] = useState(false);
    const [openReviewModal, setOpenReviewModal] = useState(false);
    const [openEditReviewModal, setOpenEditReviewModal] = useState(false);
    const { id } = useParams()
    const [pdffile, setpdffile] = useState('')
    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };
    const [openNoteModal, setOpenNoteModal] = useState(false);
    const [noteText, setNoteText] = useState('');
    const inputRef1 = useRef(null);
    const handleNoteModal = () => setOpenNoteModal(!openNoteModal);
    const [profileImages, setProfileImages] = useState([]);
    const [openAccordion, setOpenAccordion] = useState(null);
    const [openAccordion1, setOpenAccordion1] = useState(null);

    const toggleAccordion1 = (id) => {
        setOpenAccordion1(openAccordion1 === id ? null : id);
    };
    const orderNotesApi = async () => {
        const payload = {
            notes: noteText,
            img_url: profileImages
        };
        try {
            const response = await axios.post(`${orderNotes}/${id}`, payload);
            handleNoteModal();
            setNoteText('');
            additionalData();
            trackingDataGet();
            cancelDataGet();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const [modal, setModal] = useState(false)
    const [customizeImages, setCustomizeImages] = useState([]);
    const toggle = () => setModal(!modal);

    const viewCustom = (item) => {
        // Log the item for debugging

        // Check if item is defined and if required properties exist
        if (item) {
            // Handle condition when 'customizeImages' array has more than 1 item
            if (Array.isArray(item?.customizeImages) && item?.customizeImages.length > 1) {
                setCustomizeImages(item?.customizeImages?.map((customImage) => customImage?.img_url)); // Set 'customizeImages' state
                toggle(); // Toggle modal or action
            }
            // Handle condition when 'file' array has items
            else if (Array.isArray(item?.file) && item?.file.length > 0) {
                setCustomizeImages(item?.file); // Set 'customizeImages' state
                toggle(); // Toggle modal or action
            }
            // Handle condition when 'product.image' is available
            else if (item?.product?.image) {
                setCustomizeImages([item?.product?.image]); // Ensure it's in an array
                toggle(); // Toggle modal or action
            }
            else {
                // Fallback if no images or files are found
            }
        } else {
            // Handle case where 'item' is undefined or null
            console.error("Invalid item passed to viewCustom");
        }
    };

    const handleProfileUpload = async (e) => {
        if (e.target.files) {
            try {
                setLoading(true);
                const formData = new FormData();

                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append('files', e.target.files[i]);
                }

                const uploadData = await multiImageUpload(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImages = uploadData.result.map(file => file.location);

                    setProfileImages(prevImages => [...prevImages, ...uploadedImages]);
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        // setSelectedImageIndex(null);
    };

    const additionalData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${customerProducts}/${id}`);
            setData(response?.data?.result);
            setpdffile(response?.data?.result?.invoice);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }, [id]);

    const trackingDataGet = useCallback(async () => {
        try {
            const response = await axios.get(`${trackingDetails}/${id}`);
            setTrackingData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }, [id]);

    const cancelDataGet = useCallback(async () => {
        try {
            const response = await axios.get(`${cancelReason}`);
            setCancelData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }, []);

    useEffect(() => {
        additionalData();
        trackingDataGet();
        cancelDataGet();
    }, [additionalData, trackingDataGet, cancelDataGet]);

    // useEffect(() => {
    //     additionalData()
    //     trackingDataGet()
    //     cancelDataGet()
    // }, [id])

    const addressDetails = dataa?.shippingAddress

    const refetchFunc = () => {
        additionalData()
        trackingDataGet()
        cancelDataGet()
    }

    return (
        <>
            <Navbar />
            {loading ? (
                <div
                    style={{
                        height: "80vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Spinner color="primary">Loading...</Spinner>
                </div>
            ) : (
                <div className='pppaa mb-md-0 mb-5' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', background: '#FAFAFA' }}>
                    <div style={{ width: '100%' }} className='d-flex align-items-center pt-md-5 pt-2 mb-3'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>My Orders {dataa?.otherProducts?.length > 0 ? (<span>({dataa?.otherProducts?.length + 1})</span>) : ''}</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                    <div className='d-flex justify-content-end mb-4'>
                        {dataa?.status === "delivered" && (
                            <>
                                {dataa?.status === "delivered" && (
                                    <>
                                        {dataa?.ratingReview ? (
                                            <span
                                                style={{ fontSize: '15px', fontFamily: 'Lato', fontWeight: '500', color: '#E4510B', cursor: 'pointer' }}
                                                onClick={() => setOpenEditReviewModal(true)}
                                            >
                                                <MdOutlineFeedback style={{ color: '#E4510B' }} />
                                                Edit Your Review
                                            </span>
                                        ) : (
                                            <span
                                                style={{ fontSize: '15px', fontFamily: 'Lato', fontWeight: '500', color: '#E4510B', cursor: 'pointer' }}
                                                onClick={() => setOpenReviewModal(true)}
                                            >
                                                <MdOutlineFeedback style={{ color: '#E4510B' }} />
                                                Add Your Review
                                            </span>
                                        )}
                                    </>
                                )}
                            </>

                        )}

                    </div>
                    <Row className='mb-md-0 mb-5'>
                        <Col sm={12} lg={8}>
                            <div className='view-order-card p-3'>
                                <div className='d-flex justify-content-between carda'>
                                    <div className='d-md-flex '>
                                        <div>
                                            {/* {dataa?.customizeImages?.[0] ? (
                                                <img
                                                    alt={`product`}
                                                    src={dataa?.customizeImages?.[0]}
                                                    className='my-order-img'
                                                />
                                            ) : (
                                                <img
                                                    alt={`product`}
                                                    src={dataa?.file?.[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : dataa?.file?.[0]}
                                                    className='my-order-img'
                                                />
                                            )} */}
                                            {dataa?.customizeImages?.[0] ? (
                                                <img
                                                    alt={`product`}
                                                    src={dataa?.customizeImages[0]?.img_url}
                                                    className='produ-img'
                                                />
                                            ) : (
                                                <>
                                                    {/* {dataa?.customizeImages?.length === 0 && dataa?.file?.length === 0 ?
                                                        (
                                                            <img
                                                                alt={`product`}
                                                                src={dataa?.product?.image}
                                                                className='produ-img'
                                                            />
                                                        ) : (
                                                            <img
                                                                alt={`product`}
                                                                src={dataa?.file[0]?.endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : dataa?.file[0]}
                                                                className='produ-img'
                                                            />
                                                        )
                                                    } */}
                                                    {dataa?.customizeImages?.[0] ? (
                                                        <img
                                                            alt="product"
                                                            src={dataa?.customizeImages?.[0]}
                                                            className="produ-img"
                                                        />
                                                    ) : (
                                                        <>
                                                            {dataa?.customizeImages?.length === 0 && dataa?.file?.length === 0 ? (
                                                                <img
                                                                    alt="product"
                                                                    src={dataa?.product?.image}
                                                                    className="produ-img"
                                                                />
                                                            ) : (
                                                                <img
                                                                    alt="product"
                                                                    src={
                                                                        dataa?.file?.[0]?.endsWith('.pdf')
                                                                            ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png'
                                                                            : dataa?.file?.[0]
                                                                    }
                                                                    className="produ-img"
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                                // <a href={eachItem?.file?.[0]} target='_blank'>

                                                // </a>
                                            )}
                                            <div className='d-flex mt-2' style={{ alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                                <span style={{ cursor: 'pointer' }} onClick={() => viewCustom(dataa)}><Eye style={{ color: '#E4510B' }} /> Preview</span>
                                            </div>
                                            {/* {(dataa?.status !== "ordercompleted" && dataa?.status !== "cancelled") &&
                                                <div className='d-flex mt-2'>
                                                    <button style={{ border: 'none', background: '#fafafa' }}>
                                                        <a
                                                            target='_blank'
                                                            href={pdffile}
                                                            rel="noreferrer"
                                                            style={{ background: 'transparent', color: '#E4510B', border: 'none', textDecoration: 'underline' }}
                                                            className='text-center'
                                                        >
                                                            Download Invoice
                                                        </a>
                                                    </button>
                                                </div>
                                            } */}

                                        </div>
                                        <div className='ms-md-3 heaa s'>
                                            <h1 className='h5'>{`${dataa?.product?.name}`}</h1>
                                            <p style={{ color: '#858585' }} className='p-0 m-0 mb-2'>Quantity : {`${dataa?.quantity}`}</p>
                                            {dataa?.sizeWithQuantity?.length > 0 &&
                                                <>
                                                    {dataa?.sizeWithQuantity[0]?.quantity ? (
                                                        <div className='mt-2'>
                                                            <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                <div className='d-flex' onClick={() => toggleAccordion1(dataa?._id)}>
                                                                    <h6 style={{ color: '#858585', fontWeight: '400' }} className=' p-0'>Size with Quantity</h6>
                                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                                </div>
                                                            </div>
                                                            <Collapse isOpen={openAccordion1 === dataa?._id}>
                                                                <div className='accordion-body'>
                                                                    <div className='mt-2 mb-2'>
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>S. No</th>
                                                                                    <th>Size</th>
                                                                                    <th>Quantity</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {dataa?.sizeWithQuantity?.map((item, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{item.size || 'N/A'}</td>
                                                                                        <td>{item.quantity || 'N/A'}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    ) : (
                                                        <div className='mt-2'>
                                                            <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                <div className='d-flex' onClick={() => toggleAccordion1(dataa?._id)}>
                                                                    <h6 style={{ color: '#858585', fontWeight: '400' }} className=' p-0'>Names & Numbers</h6>
                                                                    <span className='ms-2 p-0'><ChevronDown /></span>
                                                                </div>
                                                            </div>
                                                            <Collapse isOpen={openAccordion1 === dataa?._id}>
                                                                <div className='accordion-body'>
                                                                    <div className='mt-2 mb-2'>
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>S. No</th>
                                                                                    <th>Name</th>
                                                                                    <th>Number</th>
                                                                                    <th>Size</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {dataa?.sizeWithQuantity?.map((item, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{item.name || 'N/A'}</td>
                                                                                        <td>{item.number || 'N/A'}</td>
                                                                                        <td>{item.size || 'N/A'}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    )}
                                                </>
                                            }
                                            {dataa?.field?.length > 0 &&
                                                <div className='mt-2'>
                                                    <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                        <div className='d-flex' onClick={() => toggleAccordion(dataa?._id)}>
                                                            <h6 style={{ color: '#858585', fontWeight: '400' }} className=' p-0'>Product Specifications</h6>
                                                            <span style={{ marginTop: '-2px' }} className='ms-2 p-0'><ChevronDown size={20} /></span>
                                                        </div>
                                                    </div>
                                                    <Collapse isOpen={openAccordion === dataa?._id}>
                                                        <div className='accordion-body'>
                                                            <div>
                                                                {/* <p style={{ color: '#e6703a' }}>Edit Details</p> */}

                                                                {dataa?.field?.map((eachDetail, k) => (
                                                                    <div className='d-flex justify-content-between' key={k}>
                                                                        <p className='text-secondary'><ChevronRight /> {eachDetail?.fieldType?.name}</p>
                                                                        <p className='pe-5 text-dark' style={{ fontWeight: '500' }}>- {eachDetail?.option?.name}</p>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            }
                                            <h1 className='h6'>Order placed on : {`${convertTo12HourFormat(dataa?.date)}, ${formatDate(dataa?.date)}`}</h1>
                                            <div className='d-flex text-center'>
                                                <h1 className='h5 text-success p-0 m-0'>
                                                    ₹{`${parseFloat(dataa?.total).toFixed(2)}`}
                                                </h1>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='d-flex flex-column justify-content-between align-items-end state'>
                                        <div className='mb-2'>   {getDeliveryStatus(dataa?.status)}</div>
                                        {trackingData?.[4]?.date && (
                                            <h1 className='h6 m-0 p-0'>Delivery expected by {formatDate(trackingData?.[4]?.date)}</h1>
                                        )}
                                    </div>
                                </div>
                                <div className='mt-4 mb-3' style={{ backgroundColor: 'rgb(225, 225, 225)', height: '1px', width: '100%' }}></div>
                                {dataa?.isCancelled ? (
                                    <div className='w-100'>
                                        <div>
                                            <div className='d-flex justify-content-between' >
                                                <h1 className='h6 ms-md-3'>Order Confirmed</h1>
                                                <h1 className='h6 me-md-2'>Cancelled</h1>
                                            </div>
                                            <div className='d-flex justify-content-between me-lg-4 ms-lg-5 mt-2 me-sm-2 ms-sm-3'>
                                                {/* First Tick Box */}
                                                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div className="tick-red ms-md-1 ms-3">
                                                        <span><Check size={26} /></span>
                                                    </div>

                                                    <div
                                                        className="bar-line"
                                                        style={{
                                                            flexGrow: 1,   // This makes the line grow to fill the space between the ticks
                                                            height: '6px', // Thickness of the line
                                                            backgroundColor: 'red'
                                                        }}
                                                    ></div>

                                                    <div className="tick-red me-md-1 me-3">
                                                        <span><Check size={26} /></span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='d-flex justify-content-between s' >
                                                <h1 style={{ color: '#858585' }} className='h6 ms-md-3 mt-3 time'>{convertTo12HourFormat(dataa?.date)}, {formatDate(dataa?.date)}</h1>
                                                <h1 style={{ color: '#858585' }} className='h6 mt-3 time'>{convertTo12HourFormat(dataa?.cancelDetails?.date)}, {formatDate(dataa?.cancelDetails?.date)}</h1>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='d-flex align-items-end justify-content-between position-relative'>
                                        <div className='overflow-auto'>
                                            <div className='d-flex' style={{ gap: '130px' }}>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <h1 key={i} className='h6'>{item?.title}</h1>
                                                ))}
                                            </div>
                                            <div className='d-flex ms-3 mt-2'>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <div>
                                                        <div className='d-flex align-items-center justify-content-end'>
                                                            {i !== 0 && (
                                                                <div className={`bar-line ${item?.status ? 'bar-line-clr-2' : 'bar-line-clr-1'}`}></div>
                                                            )}
                                                            <div className={` ${item?.status ? 'tick-green' : 'tick-normal'}`}>
                                                                <span><Check size={26} /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex' style={{ gap: '100px' }}>
                                                {trackingData?.slice(0, 4)?.map((item, i) => (
                                                    <h1 key={i} style={{ color: '#858585', minWidth: '100px' }} className='h6 mt-3 time'>{item?.date ? (<span>{convertTo12HourFormat(item?.date)}, <br /> {formatDate(item?.date)}</span>) : ''}</h1>
                                                ))}
                                            </div>
                                        </div>
                                        {/* {trackingData?.[1]?.status !== true && (
                                            <button
                                                className='order-cancel-btn'
                                                onClick={() => setOpenCancelModal(true)}
                                            >
                                                Cancel
                                            </button>
                                        )} */}
                                    </div>
                                )}
                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <div className='mt-3 mt-lg-0' style={{ border: '1px solid rgb(225, 225, 225)', borderRadius: '15px' }}>
                                <div style={{ borderBottom: '1px solid #DFDFDF' }} className=' p-3'>
                                    <h5>Order Summary</h5>
                                    <hr className='p-0 m-0 mb-3' />
                                    <div className='d-flex justify-content-between'>
                                        <p className='cart-summary-para'>Item Subtotal</p>
                                        <p>{dataa?.total?.toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className='cart-summary-para'>Delivery Fee</p>
                                        <p>{dataa?.shippingCharge?.toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</p>
                                    </div>
                                    <hr className='p-0 m-0 mb-3' />
                                    <div className='d-flex justify-content-between'>
                                        <h6>Total <span style={{ color: '#888888' }}>(inclusive of all tax)</span></h6>
                                        <h5 style={{ color: '#37A003' }} className='fw-bold ms-3'>{dataa?.totalAmount?.toLocaleString('en-IN', {
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'INR'
                                        })}</h5>
                                    </div>
                                </div>
                                <div className='mt-3 p-3'>
                                    <h5>Payment Information</h5>
                                    <hr className='p-0 m-0 mb-3' />
                                    <div className='d-flex justify-content-between'>
                                        <p className='cart-summary-para'>Payment Method :</p>
                                        <p>{dataa?.paymentDetails?.method}</p>
                                    </div>
                                    {dataa?.paymentDetails?.method !== "cash" &&
                                        <div className='d-flex justify-content-between'>
                                            <p className='cart-summary-para'>Transaction ID :</p>
                                            <p>{dataa?.paymentDetails?.transactionId}</p>
                                        </div>
                                    }

                                </div>
                                {(dataa?.status !== "ordercompleted" && dataa?.status !== "cancelled") &&
                                    <div style={{ borderTop: '1px solid #DFDFDF' }} className='d-flex mt-2 justify-content-between p-3'>
                                        <p className='cart-summary-para'>Invoice :</p>
                                        <div>
                                            <button style={{ border: 'none', background: '#fafafa' }}>
                                                <a
                                                    target='_blank'
                                                    href={pdffile}
                                                    rel="noreferrer"
                                                    style={{ background: 'transparent', color: '#E4510B', border: 'none', textDecoration: 'underline' }}
                                                    className='text-center'
                                                >
                                                    Download Invoice
                                                </a>
                                            </button>
                                        </div>

                                    </div>
                                }
                            </div>
                        </Col>
                        <Col sm={12} lg={8} className='mt-3'>
                            <div className='view-order-card p-3 h-100 mt-md-0'>
                                <h1 className='h5'>Delivery Address</h1>
                                {/* <div className='d-flex justify-content-between align-items-center mt-3'>
                                    <h1 style={{ fontSize: '16px' }} className='p-0 m-0'>{addressDetails?.firstName} {addressDetails?.lastName}</h1>
                                    {trackingData?.[1]?.status !== true && (
                                        <button
                                            className='order-change-btn'
                                            onClick={() => setOpenChangeAddressModal(true)}
                                        >
                                            Change
                                        </button>
                                    )}
                                </div> */}
                                <div className='w-75 mt-4'>
                                    <h1 style={{ fontSize: '16px' }} className=''>{addressDetails?.address}, {addressDetails?.landmark}, {addressDetails?.city} - {addressDetails?.pincode}, {addressDetails?.state}.</h1>
                                </div>

                                <h1 style={{ fontSize: '16px' }} className='mt-4'>Phone Number : {addressDetails?.mobile} </h1>
                                {addressDetails?.alternateMobile &&
                                    <h1 style={{ fontSize: '16px' }} className=''>Alternate Phone Number : {addressDetails?.alternateMobile} </h1>
                                }

                                {(dataa?.status === "delivered" || dataa?.status === "shipped") && (
                                    <>
                                        <h1 className='h5 mt-4'>Shipping Details</h1>

                                        <h1 style={{ fontSize: '16px' }} className='mt-3'>Tracking Number : {dataa?.shipmentDetails?.trackingId} </h1>
                                        <h1 style={{ fontSize: '16px' }} className=''>Logistics Name  : {dataa?.shipmentDetails?.logisticName} </h1>
                                    </>
                                )}
                            </div>
                        </Col>
                        {dataa?.orderNotes?.length > 0 && (
                            <Col sm={12} lg={4} className='mt-3'>
                                <div className='view-order-card p-3 h-100 mt-md-0'>
                                    <h1 className='h5 mb-3'>Notes</h1>
                                    <div>
                                        {dataa?.orderNotes?.map((item, i) => (
                                            <>
                                                {/* <p key={i} className='cart-summary-para'><span className='text-danger'><AlertCircle size={18} /></span> {item?.notes}</p> */}
                                                <div style={{ border: '1px solid gray' }} className='mb-3 p-3 text-secondary rounded-1'>
                                                    <div>
                                                        <p className='text-secondary'><span className='text-danger'><AlertCircle size={18} /></span>  {item?.notes}</p>
                                                        <img style={{ width: '100px' }} src={item?.img_url} alt="" />

                                                        {dataa?.status === "ordercompleted" && item?.createdBy === "admin" &&
                                                            <>
                                                                <hr />
                                                                <div>
                                                                    <Button onClick={handleNoteModal} style={{ color: 'white', background: '#E4510B', border: 'none' }}>
                                                                        {/* Print <IoPrintOutline size={25} style={{ color: 'white' }} /> */}
                                                                        Reply Notes
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        }
                                                        {item?.createdBy === "customer" &&
                                                            <div>
                                                                <hr />
                                                                <p>Status:{item?.approvalStatus}</p>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                    {dataa?.otherProducts?.length > 0 && (
                        <>
                            <div style={{ width: '100%' }} className='d-flex align-items-center mt-5 mb-3'>
                                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Other items in this order</h1>
                                <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                            </div>
                            <div>
                                {dataa?.otherProducts?.map((item, i) => (
                                    <div key={i}>
                                        <MyOrderCard data={item} />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div >
            )}
            <FooterSection />
            <ReviewModal openModal={openReviewModal} id={dataa?._id} handleClose={() => setOpenReviewModal(!openReviewModal)} refetch={refetchFunc} />
            <UpdateReviewModal openModal={openEditReviewModal} id={dataa?._id} handleClose={() => setOpenEditReviewModal(!openEditReviewModal)} refetch={refetchFunc} value={dataa?.ratingReview} />
            <CancelModal openModal={openCancelModal} id={dataa?._id} data={cancelData} handleClose={() => setOpenCancelModal(!openCancelModal)} refetch={refetchFunc} />
            <ChangeModel openAddressModal={openChangeAddressModal} handleAddressClose={() => setOpenChangeAddressModal(!openChangeAddressModal)} productId={id} refetch={refetchFunc} />
            <Preview modal={modal} toggle={toggle} cardImages={customizeImages} />
            <Modal isOpen={openNoteModal} toggle={handleNoteModal} className='sidebar-lg' modalClassName='modal-slide-in sidebar-todo-modal' contentClassName='p-0' size='md' >
                <ModalHeader toggle={handleNoteModal}>
                    <div className='d-flex align-items-center justify-content-between mb-1 px-1' style={{ backgroundColor: '#ffff' }}>
                        <p className='modal-title' style={{ fontFamily: 'Lato', fontSize: '20px', fontWeight: '400' }}>
                            <b>Add Note</b>
                        </p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <Label style={{ fontWeight: "500", color: '#262525' }}>
                            <div className='d-flex'>
                                <div>Notes</div>
                            </div>
                        </Label>
                        <textarea onChange={(e) => setNoteText(e.target.value)} style={{ width: '100%', height: '100px', padding: '10px', borderRadius: '4px', border: '1px solid gray' }} placeholder='Enter Notes Here'>

                        </textarea>
                    </div>
                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                        <div className='d-flex'>
                            <div>Reference images:</div>
                        </div>
                    </Label>
                    <Card className='p-3' style={{ display: 'flex', border: '1px dashed #E4510B', width: "100%", justifyContent: 'center', alignItems: 'center' }}>

                        <div className="">
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {profileImages.map((image, index) => (
                                    <div key={index} className="m-2" style={{ position: 'relative' }}>
                                        <img
                                            alt={`preview ${index + 1}`}
                                            src={image}
                                            style={{
                                                width: '100px',
                                                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                cursor: 'pointer',
                                            }}
                                        // onClick={() => setSelectedImageIndex(index)}
                                        />
                                        <div className="cursor-pointer image-close" style={{ position: 'absolute', top: '1px', right: '1px', cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                            <X style={{ width: '16px', height: '16px' }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                <input style={{ display: 'none' }} type="file" ref={inputRef1} accept=".jpeg,.png,.jpg,.jfif" onChange={handleProfileUpload} multiple />
                                {loading ? (
                                    <Spinner />
                                ) : (
                                    <div className='text-center text-primary' onClick={() => inputRef1?.current?.click()}>
                                        {profileImages.length === 0 ? (
                                            <>
                                                <h1> <RiImageAddFill /></h1>
                                                <p>*Upload your Gallery Images</p>
                                                <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                            </>
                                        ) : (
                                            <>
                                                <h1> <RiImageAddFill /></h1>
                                                <p>*Add more images</p>
                                                <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>
                    <div className='d-bloick mt-2'>
                        <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                        <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to update the Note to 'Customer'?</p>
                    </div>
                    <div className='d-flex gap-2 justify-content-end'>
                        <Button onClick={handleNoteModal} style={{ borderColor: '#E4510B', color: '#E4510B', backgroundColor: 'white' }}>Cancel</Button>
                        <Button onClick={orderNotesApi} disabled={!noteText} style={{ backgroundColor: '#E4510B', border: 'none' }}>Submit</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ViewOrderDetail
