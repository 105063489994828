import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar'
// import FooterSection from '../../footer'
import axios from 'axios';
import { deliveryDetails, deliveryType } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import { Col, Input, Row } from 'reactstrap';
import CourierList from './CourierList';
import TitleComponent from '../../homeNew/TitleComponent';
import { FiChevronRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import ShippingCharge from './shippingCharge';

function ShippingIndex() {
    // const [loading, setLoading] = useState(false);
    const [data, setData] = useState()
    // const [isNameNumber, setIsNameNumber] = useState(false)
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate()

    const toggle = () => setModal(!modal)
    const toggle1 = () => setModal1(!modal1)

    const getdata = async () => {
        try {
            // setLoading(true);
            const response = await axios.get(`${deliveryDetails}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            // setLoading(false);
        }
    }


    useEffect(() => {
        getdata()
    }, [])

    const sendApi = async (value) => {
        const payload = {
            deliveryType: value,
        }
        try {
            const response = await axios.post(deliveryType, payload)
            getdata()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg)
        }
    }
    function timeAgo(date) {
        const now = new Date();
        const past = new Date(date);

        const seconds = Math.floor((now - past) / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30); // Approximation
        const years = Math.floor(days / 365); // Approximation

        if (years > 0) return `${years} year${years > 1 ? "s" : ""} ago`;
        if (months > 0) return `${months} month${months > 1 ? "s" : ""} ago`;
        if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
        return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }

    return (
        <div>
            <Navbar />
            <div className='pt-5 ' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', backgroundColor: '#F8F8F8' }}>
                <TitleComponent
                    title={`Shipping Management`}
                    isDesc={true}
                    desc={`Oversee and manage the shipping process with ease. Track shipments, update delivery statuses, and ensure timely and accurate order delivery to customers.`}
                />
                <div>
                    <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Shipping Management</span></p>
                </div>
                <div style={{ width: '100%' }} className='d-flex align-items-center'>
                    <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Shipping Management</h1>
                    {/* <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} /> */}
                </div>
                <div className='mt-5'>
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <div style={{ border: '1px solid grey', borderStyle: 'dashed', borderRadius: '8px' }} className='w-100 p-3 text-capitalize d-flex justify-content-between gap-3'>
                                <div>
                                    <h1 className='fs-5'>{`Manual`}</h1>
                                    {data?.deliveryType === 'manual' ? (
                                        <p className='p-0 m-0'><span>Last Update:</span> {timeAgo(data?.typeUpdatedAt)}</p>
                                    ) : (
                                        <p className='p-0 m-0'>-</p>
                                    )}
                                </div>
                                <div className='d-block'>
                                    <div className='d-flex justify-content-end'>
                                        <Input
                                            type="switch"
                                            checked={data?.deliveryType === 'manual'}
                                            onClick={() => {
                                                sendApi('manual')
                                            }}
                                        />
                                    </div>
                                    <button className='productCategoryBtn mt-3'
                                        onClick={() => {
                                            toggle1()
                                        }}
                                    >Manage</button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div style={{ border: '1px solid grey', borderStyle: 'dashed', borderRadius: '8px' }} className='w-100 p-3 text-capitalize d-flex justify-content-between gap-3'>
                                <div>
                                    <h1 className='fs-5'>Ship Rocket</h1>
                                    {data?.deliveryType === 'shiprocket' ? (
                                        <p className='p-0 m-0'><span>Last Update:</span> {timeAgo(data?.typeUpdatedAt)}</p>
                                    ) : (
                                        <p className='p-0 m-0'>-</p>
                                    )}
                                </div>
                                <div className='d-block'>
                                    <div className='d-flex justify-content-end'>
                                        <Input
                                            type="switch"
                                            checked={data?.deliveryType === 'shiprocket'}
                                            onClick={() => {
                                                // toggle()
                                                sendApi('shiprocket')
                                            }}
                                        />
                                    </div>
                                    <button className='productCategoryBtn mt-3'
                                        onClick={() => {
                                            toggle()
                                        }}
                                    >Manage</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <CourierList modal={modal} toggle={toggle} refetch={getdata} list={data?.deliveryPartner} />
                <ShippingCharge modal={modal1} toggle={toggle1} refetch={getdata} list={data?.manualShippingCharges} />
            </div>
            {/* <FooterSection /> */}
        </div>
    )
}

export default ShippingIndex
