import React, { useState } from 'react'
import SignUp from './SignUp'
import SignIn from './SignIn'
import logo from "../../../assets/images/logo/printon logo-01.png"
import login from "../../../assets/images/banner/login img.png"
import { Col, Modal, ModalBody, } from 'reactstrap'
import MobileOTPModal from './mobileOTP'
import { X } from 'react-feather'
import OTPSend from './mobileOTP/OTPSend'

function FinalAuthModal({ isOpen, /*toggleOTP, setCartKey,*/ toggle, isCart }) {

    const [isMobileOTP, setIsMobileOTP] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [showSignIn, setShowSignIn] = useState(true);
    // const [logval, setLogval] = useState('');
    const toggleOTP = () => setShowOTP(!showOTP);

    const toggleMobileOTP = () => {
        setIsMobileOTP(!isMobileOTP);
    };

    // const handleMobileOTP = () => {
    //     toggle();
    //     toggleMobileOTP()
    // }

    const [isLogin, setIsLogin] = useState(true)

    const toggleAuth1 = () => {
        setShowOTP(true);
        setShowSignIn(true);
    }

    // const handleOTPVerified = () => {
    //     setShowSignIn(false); // Hide the SignIn component after OTP verification
    // };


    const toggleAuth = () => {
        setIsLogin(!isLogin);
    }


    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} size='lg' className="modern-modal" style={{ border: 'none' }}>
                {/* <ModalHeader toggle={toggle} className="modern-modal-header">
                    <div className="d-flex">
                        <img src={logo} alt="logo" height={60} className="me-2" />
                        <h1 className="auth-head mt-2">{isLogin ? 'Login' : 'Sign Up'}</h1>

                    </div>
                </ModalHeader> */}
                <ModalBody className='p-0 m-0 position-relative'>
                    <X style={{ color: 'black', cursor: 'pointer', position: 'absolute', marginTop: '1.5rem', right: '0' }} className=" addmodal-close fw-normal mt-25 me-4" size={25} onClick={toggle} />
                    <div className='d-flex '>
                        {isLogin ? (
                            <Col className="d-none d-lg-flex justify-content-center align-items-center"
                                style={{ background: '#FFEDE1', borderTopLeftRadius: '10px', borderBottomLeftRadius: '15px' }}
                                lg={5}>
                                <div className='p-4 loggg' style={{ width: '100%', maxWidth: '500px' }} >
                                    <div>
                                        <img style={{ width: '200px', height: '60px', marginLeft: '-20px' }} src={logo} alt="" />
                                    </div>
                                    <div className='mt-2'>
                                        <p style={{ color: '#333333', fontSize: '24px', fontWeight: '700' }}>Welcome Back</p>
                                    </div>
                                    <div>
                                        <p style={{ color: '#747474', fontSize: '14px', fontWeight: '400' }}>Log in to access your account and enjoy a personalized shopping experience with Printon. Manage your orders, track shipments, save your favorite products, and more—all from your secure account.</p>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <img style={{ width: '300px', height: '300px' }} src={login} alt="" />
                                    </div>
                                </div>
                            </Col>
                        ) : (
                            <Col className="d-none d-lg-flex justify-content-center align-items-center"
                                style={{ background: '#FFEDE1', borderTopLeftRadius: '10px', borderBottomLeftRadius: '15px' }}
                                lg={5}>
                                <div className='p-4 loggg' style={{ width: '100%', maxWidth: '500px' }} >
                                    <div>
                                        <img style={{ width: '200px', height: '60px', marginLeft: '-20px' }} src={logo} alt="" />
                                    </div>
                                    <div className='mt-2'>
                                        <p style={{ color: '#333333', fontSize: '24px', fontWeight: '700' }}>Welcome to Printon</p>
                                    </div>
                                    <div>
                                        <p style={{ color: '#747474', fontSize: '14px', fontWeight: '400' }}>Create an account to unlock the full potential of Printon! Sign up now to start customizing products, saving your favorite designs, tracking orders, and accessing exclusive deals.</p>
                                    </div>
                                    <div style={{ height: '400px' }} className='d-flex justify-content-center align-items-center'>
                                        <img style={{ width: '100%', maxWidth: '500px' }} src={login} alt="" />
                                    </div>
                                </div>
                            </Col>
                        )}
                        <Col lg={7} md={12} sm={12} className="d-flex justify-content-center align-items-center">
                            <div className='ps-2 pe-2' style={{ width: '100%', maxWidth: '500px' }}>

                                {showOTP ? (
                                    <div>
                                        {isCart ? (
                                            <OTPSend toggleAuth={toggleAuth} toggle1={toggle} toggleOTP={toggleOTP} setCartKey={isCart ? 'yes' : ''} showOTP={setShowOTP} />
                                        ) : (
                                            <OTPSend toggleAuth={toggleAuth} toggle1={toggle} toggleOTP={toggleOTP} />
                                        )}
                                    </div>
                                ) : (
                                    showSignIn && (
                                        isLogin ? (
                                            <SignIn
                                                toggleAuth1={toggleAuth1}
                                                // value={logval}
                                                toggle={toggle}
                                                toggleAuth={toggleAuth}
                                                setCartKey={isCart ? 'yes' : ''}
                                            />
                                        ) : (
                                            <SignUp
                                                toggleAuth={toggleAuth}
                                                toggle={toggleAuth}
                                                toggle1={toggle}
                                                setCartKey={isCart ? 'yes' : ''}
                                            />
                                        )
                                    )
                                )}
                            </div>
                        </Col>
                    </div>
                </ModalBody >
            </Modal >
            {isMobileOTP && <MobileOTPModal isOpen={isMobileOTP} toggleOTP={toggleMobileOTP} />
            }
        </div >
    )
}

export default FinalAuthModal