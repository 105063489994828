import React, { useEffect } from 'react';
import { X } from 'react-feather';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Col, Form, FormFeedback, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { toast } from 'react-hot-toast';
// import Select from 'react-select';
import { metaData } from '../../../../ApiConfigs/ApiConfig';

function MetaDetailsEdit({ open, handleEdit, editData, offerData }) {

    const formSchema = yup.object().shape({
        title: yup
            .string()
            .required('Please Enter Your Meta Title'),
        description: yup.string().required('Please Enter Your Meta Description'),
        keywords: yup.string().required('Please Enter Your Meta Keywords'),
    });

    const {
        reset,
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
    });


    const submitForm = async (data) => {
        const payload = {
            ...data,
            // keywords: data?.keywords.reduce((acc, val, index) => {
            //     return acc + (index > 0 ? ', ' : '') + val;
            // }, ''),
        };
        try {
            const response = await axios.put(`${metaData}/${editData?._id}`, payload);
            handleEdit();
            offerData();
            // setGetData();
            toast.success(response?.data?.result?.msg);
            reset();
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {

        reset({
            title: editData?.title,
            keywords: editData?.keywords?.reduce((acc, val, index) => {
                return acc + (index > 0 ? ', ' : '') + val;
            }, ''),
            description: editData?.description,
            path: editData?.path,
        });
    }, [editData, reset, setValue]);

    return (
        <div className='modal-page'>
            <Modal
                isOpen={open}
                toggle={handleEdit}
                className='sidebar-lg'
                modalClassName='modal-slide-in sidebar-todo-modal'
                contentClassName='p-0'>
                <div
                    className="d-flex align-items-center justify-content-between mb-1 px-1"
                    style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}
                >
                    <h4
                        className="modal-title"
                        style={{
                            marginTop: '23px',
                            marginBottom: '23px',
                            paddingLeft: '10px',
                            fontFamily: 'roboto',
                            color: 'black',
                        }}
                    >
                        Edit Meta Details
                    </h4>
                    <X
                        style={{ color: 'black', cursor: 'pointer' }}
                        className="addmodal-close fw-normal mt-25 me-2"
                        size={25}
                        onClick={handleEdit}
                    />
                </div>
                <ModalBody className='flex-grow-1'>
                    <Form onSubmit={handleSubmit(submitForm)}>
                        <Row>
                            <Col lg={12} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Path</Label>
                                    <Controller
                                        name='path'
                                        id='path'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field} invalid={errors.path && true} placeholder="Enter your Meta Title" />)} />
                                    {errors.path && <FormFeedback>{errors.path.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Title</Label>
                                    <Controller
                                        name='title'
                                        id='title'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field} invalid={errors.title && true} placeholder="Enter your Meta Title" />)} />
                                    {errors.title && <FormFeedback>{errors.title.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Description</Label>
                                    <Controller
                                        name='description'
                                        id='description'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '100px' }} type="textarea" {...field} invalid={errors.description && true} placeholder="Enter your Meta Descritpion" />)} />
                                    {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col lg={12} sm={12}>
                                <div className="">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Keywords</Label>
                                    <div className=" w-100">
                                        <Controller
                                            name='keywords'
                                            id='keywords'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (<Input style={{ height: '150px' }} type="textarea" {...field} invalid={errors.keywords && true} placeholder="Enter your Meta Keywords" />)} />
                                        {errors.keywords && <FormFeedback>{errors.keywords.message}</FormFeedback>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr></hr>
                        <div className='d-flex'>
                            <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={handleEdit}>Cancel</button>
                            <button className='mt-1 w-50 ms-2 add-modal-btn' type='submit'>Submit</button>
                        </div>
                    </Form >
                </ModalBody>

            </Modal>
        </div >
    )
}

export default MetaDetailsEdit;
