import React, { useState } from 'react';
import { otpSend, otpVerify } from '../../../../ApiConfigs/ApiConfig';
import axios from 'axios';
import { Form, FormFeedback, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';

function SignUpOtp({ toggleOTP, setCartKey, toggle1, showOTP }) {
    const [otp, setOtp] = useState('');

    const handleEmailSignIn = async () => {
        const payload = {
            mobile: mobile,
            otp: otp
        }

        try {
            const response = await axios.post(otpVerify, payload);
            toast.success(response?.data?.msg);
            localStorage.setItem('token', response?.data?.result?.tokens?.accessToken);
            localStorage.setItem('refreshToken', response?.data?.result?.tokens?.refreshToken);
            localStorage.setItem('role', response?.data?.result?.user?.role?.name);
            localStorage.setItem('userId', response?.data?.result?.user?._id);
            localStorage.setItem('name', response?.data?.result?.user?.name);
            localStorage.setItem('mobile', response?.data?.result?.user?.mobile);

            if (setCartKey === 'yes') {
                localStorage.setItem('AddCart', true);
            }
            localStorage.removeItem('SignInMobileOrEmail');
            localStorage.removeItem('SignInMobile');
            toggleOTP();
            toggle1();
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    const mobile = localStorage.getItem('signUpMobile');

    const ResendOTP = async () => {
        const payload = {
            mobile: mobile
        }

        try {
            const response = await axios.post(otpSend, payload);
            toast.success(response?.data?.msg);
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const navigate = useNavigate();

    const handleChangeClick = () => {
        // Navigate to the SignIn page with the mobile number passed as state
        showOTP(false);
    };


    const { handleSubmit, formState: { errors } } = useForm();

    return (

        <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
            <div className=" text-center">
                {/* <img src={logo} alt="logo" height={60} className="me-2" /> */}
                <h1 className="auth-head mt-2">OTP</h1>

            </div>
            <div>
                <p className='text-center'>
                    {mobile} <span
                        style={{ color: '#E45D25', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={handleChangeClick}
                    >
                        change
                    </span>
                </p>
            </div>
            <div>
                <p className='text-center' style={{ fontSize: '16px', fontWeight: '400', lineHeight: '25px', color: '#333333', opacity: '0.5' }}>
                    We’ve sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification
                </p>
            </div>
            <Form onSubmit={handleSubmit(handleEmailSignIn)}>

                <div className='' style={{ display: "flex", justifyContent: "center" }}>
                    <div className=''>
                        <Label>Enter OTP</Label>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            separator={<span>-</span>}
                            renderInput={(inputProps, index) => (
                                <input {...inputProps} style={{ padding: "10px", width: '55px', height: '55px', textAlign: "center", borderRadius: "8px", marginLeft: "7px", justifyContent: 'center', fontSize: "16px", color: '#333333' }} />
                            )}
                        />
                        {errors.otp && <FormFeedback>{errors.otp.message}</FormFeedback>}
                    </div>
                </div>
                {/* <h6 className='text-center'>OTP has been sent to this mobile number! <span className='text-primary' style={{ cursor: 'pointer' }} onClick={toggleAuth}>{mobile}</span></h6> */}
                <p className='float-end' style={{ cursor: 'pointer', marginRight: '30px', color: '#E45D25' }} onClick={ResendOTP}>Resend OTP</p>
                <div className='modern-modal-footer'>
                    <button className='auth-button1' type='submit'>Create an account</button>
                </div>
                <div className='mt-3'>
                    <p style={{ fontSize: '16px', fontFamily: 'Lato', fontWeight: '400' }}>
                        By continuing, you agree to Printon’s{' '}
                        <span
                            style={{ color: '#E45D25', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => navigate('/Terms')}
                        >
                            Terms of Use
                        </span>{' '}
                        and{' '}
                        <span
                            style={{ color: '#E45D25', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => navigate('/privacyPolicy')}
                        >
                            Privacy Policy
                        </span>
                    </p>
                </div>
            </Form>
        </div>
    );
}

export default SignUpOtp;

