import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./variables.css";
// import HomeIndex from "./components/home";
import ProductType from "./components/product";
import ProductView from "./components/productView";
import ProductCategoryIndex from "./components/admin/productCategory";
import ProductIndex from "./components/admin/product";
import FieldTypeIndex from "./components/admin/fieldType";
import OtpVerification from "./components/navbar/otp";
import AllProduct from "./components/allProduct";
import AddProductIndex from "./components/admin/product/addProduct";
import MyCartIndex from "./components/cart";
import PlaceOrderIndex from "./components/placeOrder";
import MyOrderIndex from "./components/myOrder";
import PageNotFound from "./components/notFound";
import EditProductIndex from "./components/admin/product/editmodal";
import ProductTypeIndex from "./components/admin/product/productType";
import PopularProducts from "./components/popularProduct";
import ScrollToTop from "./ScrollToTop";
import MyWishListIndex from "./components/MyWishList";
import ReachUs from "./components/navbar/reachUs";
import OfferProduct from "./components/admin/offerProduct";
import OfferTargets from "./components/offerProducts";
import Myqueries from "./components/myQueries";
import ReportIndex from "./components/admin/report";
import PrivacyPolicy from "./components/footer/privacyPolicy";
import SafeShopping from "./components/footer/safeShoping";
import AboutGuarante from "./components/home/about/guarante";
import TermsIndex from "./components/footer/Terms";
import AboutUs from "./components/footer/aboutUs";
import MyProfile from "./components/myProfile";
import Shipping from "./components/footer/Shipping";
import Refund from "./components/footer/delivery/delivery";
import DashBoardViewPage from "./components/admin/dashboard/dashBoardViewModal";
import PrintoutPage from "./components/admin/dashboard/printout";
import ChangestatusPage from "./components/admin/dashboard/changestatus";
import "flatpickr/dist/themes/material_green.css";
import MenuIndex from "./components/admin/menuCombo";
import FqaIndex from "./components/admin/product/faq";
import CategoryFaq from "./components/admin/productCategory/categoryFaq/CategoryFaq";
import MenuProducts from "./components/menuProducts/MenuProducts";
import DescriptionIndex from "./components/admin/product/description";
import CommonLayout from "./components/common/CommonLayout";
// import ProductCustomize from "./components/productView/productCustomize/index";
import AllCategory from "./components/allCategory";
import ViewOrderDetail from "./components/myOrder/view/ViewOrderDetail";
import OrderManagement from "./components/admin/orders/OrderManagement";
// import Reset from "./components/myProfile/ResetPassword";
import NewDashboardIndex from "./components/admin/newDashboard/NewDashboardIndex";
import DynamicStrikers from "./components/admin/productCategory/dynamicStrikers/DynamicStrikers";
import DeliveryLocation from "./components/admin/product/DeliveryLocation";
import { useEffect, useState } from "react";
import MobileCustomization from "./components/productView/mobileCustomization";
import ShippingIndex from "./components/admin/shipping/ShippingIndex";
import ReturnPolicy from "./components/footer/returnPolicy";
import RefundPolicy from "./components/footer/refundPolicy";
import HomeNewIndex from "./components/homeNew/HomeNewIndex";
import TopProductIndex from "./components/admin/topProduct/TopProductIndex";
import TopProductsList from "./components/topProducts/TopProductsList";
import SliderSectionIndex from "./components/admin/sliderSection/SliderSectionIndex";
import SizeChart from "./components/admin/productCategory/dynamicSizeChart/sizeChart";
import CustomizationNew from "./components/customization/CustomizationNew";
import ProductSizeChart from "./components/admin/productCategory/dynamicSizeChart/ProductSizeChart";
import ProductCategoryAdd from "./components/admin/productCategory/addModal";
import ProductCategoryEdit from "./components/admin/productCategory/editModal";
import CouponCode from "./components/admin/Coupon";
import MetaDetails from "./components/admin/metaDetails";
import Payment from "./components/admin/Payment";
import BrowseDesignIndex from "./components/browseDesign/BrowseDesignIndex";
import WhatsappIcon from "./components/WhatsappIcon";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  // Function to check window size
  const checkMobile = () => {
    if (window.innerWidth <= 980) { // You can adjust the width threshold as needed
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkMobile(); // Check on initial render
    window.addEventListener("resize", checkMobile); // Add event listener to handle screen resize

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <CommonLayout /> {/* Included CommonLayout here */}
      <WhatsappIcon />
      <Routes>
        <Route path="/" element={<HomeNewIndex />} />
        <Route path="/otp/verify" element={<OtpVerification />} />
        <Route path="/:id/all" element={<ProductType />} />
        <Route path="/products" element={<AllProduct />} />
        <Route path="/category" element={<AllCategory />} />
        {/* <Route path="/Reset" element={<Reset />} /> */}
        <Route
          path="/:product_url"
          element={
            <>
              <ProductView />{" "}
            </>
          }
        />
        <Route
          path="/products/productCustomize/:id"
          element={
            <>
              {/* {isMobile ? (
                <MobileCustomization />
              ) : ( */}
              {/* // <ProductCustomize /> */}
              <CustomizationNew isMobile={isMobile} />
              {/* )} */}
            </>
          }
        />
        <Route path="/myCart" element={<MyCartIndex />} />
        <Route path="/browseDesign/:id" element={<BrowseDesignIndex />} />
        <Route path="/placeOrder/:cartId" element={<PlaceOrderIndex />} />
        <Route path="/myOrder" element={<MyOrderIndex />} />
        <Route path="/myOrder/view/:id" element={<ViewOrderDetail />} />
        <Route path="/myQueries" element={<Myqueries />} />
        <Route path="/myWishList" element={<MyWishListIndex />} />
        <Route path="/popularProducts" element={<PopularProducts />} />
        <Route path="/topProducts" element={<TopProductsList />} />
        <Route path="/offerProducts" element={<OfferTargets />} />
        <Route path="/reachUs" element={<ReachUs />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/returnPolicy" element={<ReturnPolicy />} />
        <Route path="/refundPolicy" element={<RefundPolicy />} />
        <Route path="/safeShopping" element={<SafeShopping />} />
        <Route path="/shippingPolicy" element={<Shipping />} />
        <Route path="/printonGuarante" element={<AboutGuarante />} />
        <Route path="/refund-return" element={<Refund />} />
        <Route path="/Terms" element={<TermsIndex />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/myProfile" element={<MyProfile />} />
        <Route path="/menuProducts/:id" element={<MenuProducts />} />
        {/* <Route path="/admin/editProduct/id" element={<EditProductIndex />} /> */}
        {/* {role === 'ADMIN' && (
          <> */}

        {/* Admin Routes */}

        <Route path="/admin/dashboard" element={<NewDashboardIndex />} />
        <Route path="/admin/order-management" element={<OrderManagement />} />
        <Route
          path="/admin/dashboardViewData/:id"
          element={<DashBoardViewPage />}
        />
        <Route path="/admin/shipping" element={<ShippingIndex />} />
        <Route
          path="/admin/productCategory"
          element={<ProductCategoryIndex />}
        />
        <Route path="/admin/productCategory/customizationImages/:id" element={<DynamicStrikers />} />
        <Route path="/admin/productCategory/sizeChart/:id" element={<SizeChart />} />
        <Route path="/admin/addProductCategory" element={<ProductCategoryAdd />} />
        <Route path="/admin/editProductCategory/:id" element={<ProductCategoryEdit />} />
        <Route path="/admin/product" element={<ProductIndex />} />
        <Route path="/addProduct" element={<AddProductIndex />} />
        <Route path="/admin/editProduct/:id" element={<EditProductIndex />} />
        <Route path="/admin/product/sizeChart/:id" element={<ProductSizeChart />} />
        <Route path="/admin/productType/:id" element={<ProductTypeIndex />} />
        <Route path="/admin/topProduct" element={<TopProductIndex />} />
        <Route path="/admin/offerProduct" element={<OfferProduct />} />
        <Route path="/admin/sliderSection" element={<SliderSectionIndex />} />
        {/* <Route path="/admin/fieldOption" element={<FieldOptionIndex />} /> */}
        <Route path="/admin/fieldType" element={<FieldTypeIndex />} />
        <Route path="/admin/couponCode" element={<CouponCode />} />
        <Route path="/admin/metaDetails" element={<MetaDetails />} />
        <Route path="/admin/payment" element={<Payment />} />

        <Route path="/admin/report" element={<ReportIndex />} />
        <Route path="/admin/dashboard/Printout" element={<PrintoutPage />} />
        <Route path="/admin/changestatus" element={<ChangestatusPage />} />
        <Route path="/admin/menu" element={<MenuIndex />} />
        {/* <Route path="/admin/newstiker" element={<NewsTikerIndex />} /> */}
        <Route path="/admin/product/faq/:id" element={<FqaIndex />} />
        <Route path="/admin/product/location/:id" element={<DeliveryLocation />} />
        <Route
          path="/admin/productCategory/faq/:id"
          element={<CategoryFaq />} x
        />
        <Route
          path="/admin/product-description/:id"
          element={<DescriptionIndex />}
        />
        {/* </>
        )} */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
