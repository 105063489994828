import React, { useCallback, useEffect, useState } from 'react'
// import FooterSection from '../../../footer'
import Navbar from '../../../navbar'
import { useNavigate, useParams } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import { Col, Label, Row, Spinner } from 'reactstrap'
import { MdOutlineFileUpload } from 'react-icons/md'
import axios from 'axios'
import './index.css'
import { fileUpload, productOG, sizeChartProduct } from '../../../../ApiConfigs/ApiConfig'
import toast from 'react-hot-toast'
import { PlusCircle, Trash2, X } from 'react-feather'
// import './index.css'
// import ClipArtTab from './ClipArtTab'
// import LibraryTab from './LibraryTab'

function ProductSizeChart() {
    const { id } = useParams()
    const [inActive, setInActive] = useState(true)
    const [cmActive, setCmActive] = useState(false)
    const navigate = useNavigate()
    const [uploadedImage, setUploadedImage] = useState(null);

    const [load, setLoad] = useState(false);

    const [description, setDescription] = useState(""); // State to store input value

    const handleInputChange2 = (e) => {
        setDescription(e.target.value); // Update state on input change
    };

    const fetchSizeApi = useCallback(async () => {
        try {
            const response = await axios.get(`${productOG}/${id}`);
            const data = response?.data?.result;
            setDescription(data?.sizeChartDescription);
            setUploadedImage(data?.sizeChartImage);
            setRows(data?.sizeChartInch);
            setRows1(data?.sizeChartCm);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }, [id]); // Add dependencies here

    useEffect(() => {
        fetchSizeApi();
    }, [fetchSizeApi]);


    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        // const reader = new FileReader();
        if (!file) return;

        setLoad(true); // Show the loading spinner

        try {
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append("file", file);

            // Call your upload API
            const response = await axios.post(fileUpload, formData);
            const uploadedImgUrl = response?.data?.result[0]?.location;

            if (!uploadedImgUrl) {
                toast.error("Image upload failed. Please try again.");
                return;
            }

            // if (!response.ok) throw new Error("Upload failed");

            // const data = await response?.data?.result[0]?.location; // Assuming the API returns the uploaded image URL
            setUploadedImage(uploadedImgUrl || ""); // Save the uploaded image URL
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("File upload failed. Please try again.");
        } finally {
            setLoad(false); // Hide the spinner
        }
    };

    const submitForm = async (data) => {
        const sizeChartInch = rows.map((row) => ({
            size: row.size,
            halfChest: row.halfChest,
            bodyLength: row.bodyLength,
        }));

        const sizeChartCm = rows1.map((row) => ({
            size: row.size,
            halfChest: row.halfChest,
            bodyLength: row.bodyLength,
        }));

        const payload = {
            sizeChartImage: uploadedImage,
            sizeChartDescription: description,
            sizeChartInch: sizeChartInch,
            sizeChartCm: sizeChartCm
        };

        try {
            const response = await axios.post(`${sizeChartProduct}/${id}`, payload);
            toast.success(response?.data?.msg);
            navigate('/admin/product');
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    }

    const handleInSizeTable = () => {
        setInActive(true);
        setCmActive(false)
    }

    const handleCmSizeTable = () => {
        setCmActive(true);
        setInActive(false);
    }

    const [rows, setRows] = useState([
        { size: '', halfChest: '', bodyLength: '' }, // Initial row
    ]);

    const [rows1, setRows1] = useState([
        { size: '', halfChest: '', bodyLength: '' }, // Initial row
    ]);

    const addRow = () => {
        setRows([...rows, { size: '', halfChest: '', bodyLength: '' }]); // Add a new empty row
        setRows1([...rows1, { size: '', halfChest: '', bodyLength: '' }]); // Add a new empty row
    };

    // const addRow1 = () => {

    // };

    const handleInputChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value; // Update the specific field in the row
        setRows(updatedRows);
    };

    const deleteRow = (index) => {
        const updatedRows = rows.filter((_, i) => i !== index); // Remove the row at the given index
        setRows(updatedRows);
        const updatedRows1 = rows1.filter((_, i) => i !== index); // Remove the row at the given index
        setRows1(updatedRows1);
    };
    // const deleteRow1 = (index) => {
    //     const updatedRows = rows1.filter((_, i) => i !== index); // Remove the row at the given index
    //     setRows1(updatedRows);
    // };

    const handleInputChange1 = (index, field, value) => {
        const updatedRows = [...rows1];
        updatedRows[index][field] = value; // Update the specific field in the row
        setRows1(updatedRows);
    };

    return (
        <div>
            <Navbar />
            <div className='pt-5' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '15%', backgroundColor: '#F8F8F8' }}>
                <div className='pt-1'>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ cursor: 'pointer', opacity: '0.6' }} onClick={() => navigate('/admin/productCategory')}>Product Category</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Size Chart</span></p>
                    </div>
                </div>
                <div className='mt-4'>
                    <div className='d-flex align-items-center gap-3'>
                        <div className='cursor-pointer'>
                            <h1 className={'text-secondary'} >Size Chart</h1>
                        </div>
                    </div>
                </div>

                <Row>
                    <Col lg={6}>
                        <div>
                            <div className='displayImage' >
                                {uploadedImage ? (
                                    <div
                                        className="image-container size-container mt-2 ms-1"
                                    >
                                        <img
                                            alt={'Size Chart'}
                                            src={uploadedImage}
                                            className='sizeChartImage'
                                        />
                                        <div
                                            className="image-remove"
                                            onClick={() => setUploadedImage(null)}
                                        >
                                            <X />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='upload-image'>
                                        <div className='ps-3 pe-3'>
                                            <div className='mb-4 ps-2 mt-2 upload' style={{ width: '100%', height: '30px', background: '#E4510B', borderRadius: '5px' }}>
                                                <label htmlFor="tshirt-custompicture" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', cursor: 'pointer' }}>
                                                    {load ? (
                                                        <div
                                                            style={{
                                                                height: "auto",
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Spinner color="white" size="">
                                                                Loading...
                                                            </Spinner>
                                                        </div>
                                                    ) : (
                                                        <span className='ms-2' style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', color: 'white' }}>
                                                            <MdOutlineFileUpload />Upload Your Files

                                                        </span>
                                                    )}
                                                    <input
                                                        type="file"
                                                        id="tshirt-custompicture"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileUpload}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='text-secondary text-center'>
                                                <span className='text-danger'>Note:</span> Please Check the guidelines before uploading the files. <br />Supported file formats : PNG, JPG, JEG, JFIF.(Maximum 5 MB /Minimum 224px x 224px)
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='d-flex flex-column'>
                            <div className='d-flex'>
                                <button className={`${inActive ? 'unit-btn' : 'unit-btn1'}`} type='button' onClick={handleInSizeTable}>
                                    IN
                                </button>
                                <button className={`${cmActive ? 'unit-btn' : 'unit-btn1'} ms-3`} type='button' onClick={handleCmSizeTable}>
                                    CM
                                </button>
                                <input
                                    type="text"
                                    placeholder="Enter Description"
                                    value={description} // Bind input value to state
                                    onChange={handleInputChange2}
                                    className='ms-3' // Handle input change
                                    style={{
                                        width: "400px",
                                        padding: "8px",
                                        border: "1px solid #ccc",
                                        borderRadius: "4px",
                                    }}
                                />
                            </div>
                            <div>
                                {inActive &&
                                    <div className="mt-4">
                                        <table style={{ border: '1px solid #E4510B', width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr style={{ background: '#F5F5F5', color: '#333' }}>
                                                    <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '10px', textAlign: 'left' }}>
                                                        <Label>Sizes</Label>
                                                    </th>
                                                    <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '10px', textAlign: 'left' }}>
                                                        <Label>Half Chest (in)</Label>
                                                    </th>
                                                    <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '10px', textAlign: 'left' }}>
                                                        <Label>Body Length (in)</Label>
                                                    </th>
                                                    <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '10px', textAlign: 'center' }}>
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, index) => (
                                                    <tr key={index} style={{ borderBottom: '1px solid #E4510B' }}>
                                                        <td style={{ border: '1px solid #E4510B', padding: '10px' }}>
                                                            <input
                                                                type="text"
                                                                value={row.size}
                                                                onChange={(e) =>
                                                                    handleInputChange(index, 'size', e.target.value)
                                                                }
                                                                placeholder="Enter Size"
                                                                style={{
                                                                    width: '100%',
                                                                    padding: '8px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ border: '1px solid #E4510B', padding: '10px' }}>
                                                            <input
                                                                type="text"
                                                                value={row.halfChest}
                                                                onChange={(e) =>
                                                                    handleInputChange(index, 'halfChest', e.target.value)
                                                                }
                                                                placeholder="Enter Half Chest"
                                                                style={{
                                                                    width: '100%',
                                                                    padding: '8px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ border: '1px solid #E4510B', padding: '10px' }}>
                                                            <input
                                                                type="text"
                                                                value={row.bodyLength}
                                                                onChange={(e) =>
                                                                    handleInputChange(index, 'bodyLength', e.target.value)
                                                                }
                                                                placeholder="Enter Body Length"
                                                                style={{
                                                                    width: '100%',
                                                                    padding: '8px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #E4510B',
                                                                padding: '10px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <button
                                                                onClick={() => deleteRow(index)}
                                                                style={{
                                                                    background: 'none',
                                                                    border: 'none',
                                                                    color: '#AF1B1B',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Trash2 />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button
                                            className="table-button mt-3"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                background: '#E4510B',
                                                color: '#fff',
                                                padding: '8px 12px',
                                                border: 'none',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={addRow}
                                        >
                                            <PlusCircle />
                                        </button>
                                    </div>
                                }
                                {cmActive &&
                                    <div className="mt-4">
                                        <table style={{ border: '1px solid #E4510B', width: '100%', borderCollapse: 'collapse' }}>
                                            <thead>
                                                <tr style={{ background: '#F5F5F5', color: '#333' }}>
                                                    <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '10px', textAlign: 'left' }}>
                                                        <Label>Sizes</Label>
                                                    </th>
                                                    <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '10px', textAlign: 'left' }}>
                                                        <Label>Half Chest (cm)</Label>
                                                    </th>
                                                    <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '10px', textAlign: 'left' }}>
                                                        <Label>Body Length (cm)</Label>
                                                    </th>
                                                    <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '10px', textAlign: 'center' }}>
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows1.map((row, index) => (
                                                    <tr key={index} style={{ borderBottom: '1px solid #E4510B' }}>
                                                        <td style={{ border: '1px solid #E4510B', padding: '10px' }}>
                                                            <input
                                                                type="text"
                                                                value={row.size}
                                                                onChange={(e) =>
                                                                    handleInputChange1(index, 'size', e.target.value)
                                                                }
                                                                placeholder="Enter Size"
                                                                style={{
                                                                    width: '100%',
                                                                    padding: '8px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ border: '1px solid #E4510B', padding: '10px' }}>
                                                            <input
                                                                type="text"
                                                                value={row.halfChest}
                                                                onChange={(e) =>
                                                                    handleInputChange1(index, 'halfChest', e.target.value)
                                                                }
                                                                placeholder="Enter Half Chest"
                                                                style={{
                                                                    width: '100%',
                                                                    padding: '8px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ border: '1px solid #E4510B', padding: '10px' }}>
                                                            <input
                                                                type="text"
                                                                value={row.bodyLength}
                                                                onChange={(e) =>
                                                                    handleInputChange1(index, 'bodyLength', e.target.value)
                                                                }
                                                                placeholder="Enter Body Length"
                                                                style={{
                                                                    width: '100%',
                                                                    padding: '8px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                        </td>
                                                        <td
                                                            style={{
                                                                border: '1px solid #E4510B',
                                                                padding: '10px',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <button
                                                                onClick={() => deleteRow(index)}
                                                                style={{
                                                                    background: 'none',
                                                                    border: 'none',
                                                                    color: '#AF1B1B',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Trash2 />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button
                                            className="table-button mt-3"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                background: '#E4510B',
                                                color: '#fff',
                                                padding: '8px 12px',
                                                border: 'none',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={addRow}
                                        >
                                            <PlusCircle />
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='d-flex fixed-button justify-content-end mt-2'>
                    <button
                        className='overview-btn' style={{ color: 'white', borderRadius: '5px' }} type='button' onClick={submitForm}>Submit
                    </button>
                </div>
            </div>
            {/* <FooterSection /> */}
        </div >
    )
}

export default ProductSizeChart
