import React, { useEffect, useState } from 'react'
import './index.css'
import { NavLink } from 'react-router-dom'
import { Instagram, Youtube } from 'react-feather'
import { FaFacebookSquare } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import whatsapp from "../../assets/images/logo/whatsapp.png"
import { socialMedia } from '../../ApiConfigs/ApiConfig'
import axios from 'axios'
import visa from '../../assets/images/logo/Visa-Logo.png';
// import newsletter from '../../assets/images/banner/newsletter.png';
import master from '../../assets/images/logo/MasterCard_Logo.svg.png';
import paypl from '../../assets/images/logo/068BjcjwBw0snwHIq0KNo5m-15.webp';
import america from '../../assets/images/logo/American_Express-4876c7d0db694256968485f3085dfa78.png';
// import { useForm } from 'react-hook-form';
// import { Col } from 'reactstrap';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import toast from 'react-hot-toast';


function FooterSection({ isLoggedIn }) {
    // const [dataa, setData] = useState([]);
    const [socialMedias, setSocialMedias] = useState({})
    // const [showOtpModal, setShowOtpModal] = useState(false); // State variable for modal visibility
    // const [email, setEmail] = useState("");
    // const [error, setError] = useState('');
    // const [subscribed, setSubscribed] = useState(false);

    // const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // const SignInEmailSchema = yup.object().shape({
    //     mobile: yup.string().required('Enter Your Mobile Number').matches(/^[0-9]{10}$/, 'Invalid mobile number'),
    // });

    // const {
    //     // handleSubmit: handleMobileFormSubmit,
    //     // control: emailControl,
    //     // setValue: setEmailValue,
    //     // formState: { },
    // } = useForm({ mode: 'onChange', resolver: yupResolver(SignInEmailSchema) });

    // const handleMobileSignIn = async (data) => {

    //     try {
    //         const response = await axios.post(otpSend, data);
    //         toast.success(response?.data?.msg);
    //         localStorage.setItem('SignInMobile', data.mobile);
    //         setShowOtpModal(true); // Show the OTP modal after successful submission

    //     }
    //     catch (error) {
    //         toast.error(error?.response?.data?.msg);
    //     }
    // };

    const SocialMediaData = async () => {
        try {

            const response = await axios.get(socialMedia)
            // setData(response?.data?.result)
            setSocialMedias(response.data.result)

        } catch (error) {

        }
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            SocialMediaData()
        }

    }, [])

    // const subscribe = async () => {
    //     const payload = {
    //         email: email
    //     }
    //     try {
    //         if (emailPattern.test(email)) {
    //             setError('');
    //             const response = await axios.post(subscriber, payload);
    //             setSubscribed(true);
    //             setEmail('');
    //         } else {
    //             // Set error message if email is invalid
    //             setError('Please enter a valid email address.');
    //         }
    //     } catch (error) {
    //         toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
    //     }
    // }

    // const handleEmailChange = (e) => {
    //     const emailValue = e.target.value;
    //     setEmail(emailValue);

    //     // Check if the email is valid as the user types
    //     if (emailPattern.test(emailValue)) {
    //         setError(''); // Clear the error if valid
    //     } else {
    //         setError('Please enter a valid email address.'); // Set error message if invalid
    //     }
    // };

    // const token = localStorage.getItem('token')


    return (
        <>
            <div className='footer-bg'>
                <div className='footer-con d-lg-flex d-sm-block'>
                    <div className='footer-top '>
                        <div>
                            <h1 className='footer-head'>Our Company</h1>
                            <p className='footer-para m-0 mb-3'><NavLink to='/aboutUs'>About us</NavLink></p>
                            <p className='footer-para m-0 mb-3'><NavLink to='/products'>All Products</NavLink></p>
                            <p className='footer-para m-0 mb-3'><NavLink to='/safeShopping'>Safe Shopping</NavLink></p>
                        </div>
                    </div>
                    <div className='footer-top'>
                        <div>
                            <h1 className='footer-head'>Support</h1>
                            <p className='footer-para m-0 mb-3'><NavLink to="/reachUs"> Help</NavLink></p>
                            <p className='footer-para m-0 mb-3'><NavLink to="/myQueries"> FAQ</NavLink></p>

                        </div>
                    </div>
                    <div className='footer-top mt-3 mt-md-0'>
                        <div>
                            <h1 className='footer-head'>Customer Service</h1>
                            <p className='footer-para m-0 mb-1'><NavLink to='/Terms'> Terms & Conditions</NavLink></p>
                            <p className='footer-para m-0 mb-1'><NavLink to="/refund-return" >Cookies</NavLink></p>
                            <p className='footer-para m-0 mb-1'><NavLink to="/privacyPolicy"> Privacy policy</NavLink></p>
                            <p className='footer-para m-0 mb-1'><NavLink to="/returnPolicy"> Return policy</NavLink></p>
                            <p className='footer-para m-0 mb-1'><NavLink to="/refundPolicy"> Refund policy</NavLink></p>
                            <p className='footer-para m-0 mb-1'><NavLink to="/shippingPolicy"> Shipping Policy</NavLink></p>
                            {/* <p className='footer-para-m-0 mb-1'><NavLink to="/CookiesIndex">Cookies</NavLink></p> */}

                        </div>
                    </div>
                    <div className='footer-top mt-3 mt-md-0'>
                        <div>
                            <h1 className='footer-head'>Socail & Blogs</h1>
                            <p className='footer-para m-0 mb-3'><NavLink>Our Blog</NavLink></p>
                            <div className='footer-social-con d-flex'>
                                <div className='footer-socail text-primary me-2'>
                                    <a href={`https://www.facebook.com/profile.php?id=${socialMedias?.facebook?.facebookId}`} target='_blank' rel='noopener noreferrer'>
                                        <FaFacebookSquare size={23} style={{ color: 'white' }} />
                                    </a>
                                </div>

                                <div className='footer-socail me-2'>
                                    <a href={`https://twitter.com/${socialMedias?.twitter?.twitterId}`} target='_blank' rel='noopener noreferrer'>
                                        <FaXTwitter size={20} style={{ color: 'white' }} />
                                    </a>
                                </div>

                                <div className='footer-socail'>
                                    <a href={`https://www.youtube.com/${socialMedias?.youtube?.youtubeId}`} target='_blank' rel='noopener noreferrer'>
                                        <Youtube style={{ color: 'white' }} />
                                    </a>
                                </div>
                                <div className='footer-socail text-primary ms-2'>
                                    <a href={`https://www.instagram.com/${socialMedias?.instagram?.instagramId}`} target='_blank' rel='noopener noreferrer'>
                                        <Instagram style={{ color: 'white' }} />
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <hr className='mt-4 mb-4' />
                <div className='footer-bottom'>
                    <div>
                        <p className='footer-bottom-head m-0 mb-2'>© 2024 printon.co.in</p>
                        <div className='d-flex'>
                            <p className='footer-bottom-para me-3'><NavLink>Site Map</NavLink></p>
                            <p className='footer-bottom-para me-3'><NavLink to="/privacyPolicy">Privacy Policy</NavLink></p>
                            <p className='footer-bottom-para me-3'><NavLink>Cookie Policy</NavLink></p>
                            <p className='footer-bottom-para me-3'><NavLink to='/Terms'>Terms</NavLink></p>
                        </div>
                    </div>
                    <div className='footer-bottom-img flex-wrap mt-2 mt-md-0'>
                        <img src={visa} height={40} className='me-3' alt="logo" />
                        <img src={master} height={40} className='me-3' alt="logo" />
                        <img src={paypl} height={40} className='me-3' alt="logo" />
                        <img src={america} height={40} alt="logo" />
                    </div>
                </div>
                {/* <div className='fixed-bottom footer-whatsapp right-50 ' style={{ zIndex: "6", left: "initial", marginLeft: "5px" }}>
                    <a className='whatsappLink' rel="noreferrer" href={`https://wa.me/+919901897005?text=Hello%2C%20I%27m%20interested%20in%20your%20products.%20Can%20you%20provide%20more%20details%3F`} target='_blank'>
                        <img src={whatsapp} width="100" alt='Whatsapp' />
                    </a>
                </div> */}
            </div>
            {/* <div className='d-flex' style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <Col lg={12} md={12} sm={12}>
                    <div className='d-lg-flex d-sm-block newsletter-con'>
                        <Col lg={4}>
                            <div>
                                <img className='email-img' src={newsletter} alt="" />
                            </div>
                        </Col>
                        <Col className='d-flex justify-content-center align-items-center whole-letter' lg={8} md={12} sm={12}>
                            <div className='d-block subscribe'>
                                <div className=''>
                                    <h1 className='email-head'>Signup for ₹999 off your first order*</h1>
                                    <p className='email-para'>₹9999 minimum spend on order. T&Cs apply</p>
                                </div>
                                {!subscribed ? (
                                    <div className='d-flex'>
                                        <input
                                            className='email-input'
                                            type="text"
                                            placeholder='Enter your email address'
                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                            onChange={handleEmailChange}
                                            value={email} // Bind the input value to the state
                                            title="Please enter a valid email address."
                                            style={{ border: error ? '1px solid red' : '1px solid #BCBCBC' }}
                                            required
                                        />
                                        <div className='ps-4'>
                                            <button className='subscribe-btn' disabled={!!error || !email} onClick={subscribe}>
                                                Subscribe
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p className='email-head' style={{ color: '#3BB045' }}>Thanks for Subscribe</p>
                                    </div>
                                )}

                                {error && <p style={{ color: 'red', marginTop: '5px' }}>{error}</p>}
                            </div>
                        </Col>
                    </div>
                </Col>
            </div> */}
        </>
    )
}

export default FooterSection
