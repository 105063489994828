import React, { useEffect, useState } from 'react';
import { Label, Modal, ModalBody, ModalHeader, Input, Form, FormFeedback } from 'reactstrap';
import mobileImage from '../../assets/images/banner/Group 1410109338.png';
import OTPInput from 'react-otp-input';
import { mobileEmailUpdate, mobileEmailVerify } from '../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import axios from 'axios';

function ChangeMobile({ data, mobileModalOpen, mobileModal, refetch }) {
    const [mobile, setMobile] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [isMobileSend, setIsMobileSend] = useState(false)

    const handleMobileChange = (e) => {
        const value = e.target.value;
        setMobile(value);

        // Simple validation (optional)
        if (!/^\d+$/.test(value)) {
            setMobileError('Please enter a valid number');
        } else {
            setMobileError('');
        }
        if (mobile === data?.mobile) {
            setMobileError('Please enter a new number');
        } else {
            setMobileError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation before submission
        if (!/^\d+$/.test(mobile)) {
            setMobileError('Please enter a valid number');
            return;
        }
        if (mobile === data?.mobile) {
            setMobileError('Please enter a new number');
            return;
        }

        try {
            await axios.post(mobileEmailUpdate, { value: mobile });
            setIsMobileSend(true)
        } catch (error) {
            toast.error(error.response?.data?.msg);
        }
    };



    // OTP
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(30);
    const [isOtpComplete, setIsOtpComplete] = useState(false);

    useEffect(() => {
        if (timer > 0 && isDisabled) {
            const interval = setInterval(() => setTimer(timer - 1), 1000);
            return () => clearInterval(interval);
        } else if (timer === 0) {
            setIsDisabled(false);
        }
    }, [timer, isDisabled]);

    const handleOtpChange = (value) => {
        setOtp(value);

        // Check if OTP is complete
        if (value.length === 6) {
            setIsOtpComplete(true);
            setOtpError('');
        } else {
            setIsOtpComplete(false);
        }
    };

    const handleResendOtp = () => {
        if (!isDisabled) {
            setIsDisabled(true);
            setTimer(30);
        }
    };

    const handleSubmitOTP = async (e) => {
        e.preventDefault();

        // Validate OTP
        if (otp.length !== 6) {
            setOtpError('OTP must be 6 digits');
            return;
        }

        const payload = {
            value: mobile,
            otp: otp
        }
        try {
            const response = await axios.post(mobileEmailVerify, payload);
            toast.success(response?.data?.result?.msg);
            mobileModal();
            refetch();
            setMobile('')
            setOtp('')
            setIsMobileSend(false)
        } catch (error) {
            toast.error(error.response?.data?.msg);
        }
    };
    return (
        <Modal isOpen={mobileModalOpen} toggle={mobileModal}>
            <ModalHeader toggle={mobileModal}>Edit Your Mobile Number</ModalHeader>
            <ModalBody>
                {isMobileSend ? (

                    <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                        <div className=" text-center">
                            {/* <img src={logo} alt="logo" height={60} className="me-2" /> */}
                            <h1 className="auth-head mt-2">OTP</h1>

                        </div>
                        <div>
                            <p className='text-center' style={{ fontSize: '16px', fontWeight: '400', lineHeight: '25px', color: '#333333', opacity: '0.5' }}>
                                We’ve sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification
                            </p>
                        </div>
                        <Form onSubmit={handleSubmitOTP}>
                            <div className='d-flex justify-content-center' style={{ width: '100%' }}>
                                <div>
                                    <Label>Enter OTP</Label>
                                    <OTPInput
                                        value={otp}
                                        onChange={handleOtpChange}
                                        numInputs={6}
                                        separator={<span>-</span>}
                                        renderInput={(inputProps) => (
                                            <input
                                                {...inputProps}
                                                style={{
                                                    padding: '10px',
                                                    width: '50px',
                                                    height: '50px',
                                                    textAlign: 'center',
                                                    borderRadius: '8px',
                                                    marginLeft: '7px',
                                                    fontSize: '16px',
                                                    color: '#333333',
                                                    border: otpError ? '1px solid red' : '',
                                                }}
                                            />
                                        )}
                                    />
                                    {otpError && <FormFeedback style={{ color: 'red' }}>{otpError}</FormFeedback>}
                                </div>
                            </div>
                            <p
                                className='float-end'
                                style={{
                                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                                    marginRight: '30px',
                                    color: isDisabled ? 'gray' : '#E45D25',
                                }}
                                onClick={handleResendOtp}
                            >
                                Resend OTP {isDisabled && `(${timer}s)`}
                            </p>
                            <div className='p-3'>
                                <button
                                    className='auth-button1'
                                    type='submit'
                                    disabled={!isOtpComplete}
                                    style={{
                                        backgroundColor: isOtpComplete ? '#E4510B' : '#DFDFDF',
                                        color: isOtpComplete ? 'white' : '#666666',
                                        cursor: isOtpComplete ? 'pointer' : 'not-allowed',
                                        width: '100%',
                                        padding: '10px',
                                        borderRadius: '8px',
                                    }}
                                >
                                    Verify
                                </button>
                            </div>
                        </Form>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <img
                            src={mobileImage}
                            className='mobile-modal'
                            style={{ height: '200px', width: '200px', display: 'block', margin: 'auto' }}
                            alt="mobile verification"
                        />
                        <h5 className='text-center mt-2'>Verify Your Mobile Number <br /> To Proceed</h5>
                        <p style={{ fontSize: '14px' }}>
                            We require this verification in order to confirm that you are a genuine user.
                        </p>
                        <h1 className='fs-6 text-center'>Old Mobile: <span className='text-secondary'>{data?.mobile || "-"}</span></h1>
                        <Label for="mobile">New Mobile</Label>
                        <Input
                            type="text"
                            id="mobile"
                            value={mobile}
                            onChange={handleMobileChange}
                            invalid={!!mobileError}
                            placeholder="Enter Your New Mobile"
                        />
                        {mobileError && <FormFeedback>{mobileError}</FormFeedback>}
                        <button
                            type='submit'
                            className='mt-3'
                            style={{ width: '100%', backgroundColor: '#e4510b', border: 'none', padding: '8px', borderRadius: '7px', color: 'white' }}
                        >
                            Generate OTP
                        </button>
                    </Form>
                )}
            </ModalBody>
        </Modal >
    );
}

export default ChangeMobile;
