import React from 'react';
import { X } from 'react-feather';
import { Col, Modal, ModalBody, Row } from 'reactstrap';


function CouponView({ open, handleAdd, editData }) {

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;
    };
    const startDate = new Date(editData?.startDate);
    const formattedstartDate = formatDate(startDate);

    const expiryDate = new Date(editData?.expiryDate);
    const formattedexpiryDate = formatDate(expiryDate);
    return (
        <div className="modal-page">
            <Modal
                isOpen={open}
                toggle={handleAdd}
                size='lg'
                className="sidebar-lg"
                modalClassName="modal-slide-in sidebar-todo-modal"
                contentClassName="p-0"
            >
                <div
                    className="d-flex align-items-center justify-content-between mb-1 px-1"
                    style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}
                >
                    <h4
                        className="modal-title"
                        style={{
                            marginTop: '23px',
                            marginBottom: '23px',
                            paddingLeft: '10px',
                            fontFamily: 'roboto',
                            color: 'black',
                        }}
                    >
                        Coupon Details
                    </h4>
                    <X
                        style={{ color: 'black', cursor: 'pointer' }}
                        className="addmodal-close fw-normal mt-25 me-2"
                        size={25}
                        onClick={handleAdd}
                    />
                </div>
                <ModalBody className="flex-grow-1">
                    <div>
                        <Row>
                            <Col lg={6} sm={12}>
                                <div className='w-100'>
                                    <img className='w-100' src={editData?.img_url} alt="" />
                                </div>
                            </Col>
                            <Col lg={6} sm={12}>
                                <div>
                                    <div className='d-flex flex-column p-3' style={{ background: '#f9e1e1' }}>
                                        <p><span style={{ fontWeight: '700' }}>Coupon Title:</span> {editData?.title}</p>
                                        <p><span style={{ fontWeight: '700' }}>Coupon Code:</span> {editData?.code}</p>
                                        <p><span style={{ fontWeight: '700' }}>Coupon Type:</span> {editData?.couponType}</p>
                                        <p><span style={{ fontWeight: '700' }}>Cart Quantity:</span> {editData?.cartQuantity}</p>
                                        <p><span style={{ fontWeight: '700' }}>Remainig Limits:</span> {editData?.balanceCounts}</p>
                                        <p>
                                            <span style={{ fontWeight: '700' }}>Discount:</span>
                                            {editData?.discount} {editData?.discountType === "percentage" ? "%" : 'Flat'}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <div className='d-flex flex-column p-3 mt-3' style={{ background: '#f9e1e1' }}>

                                <p><span style={{ fontWeight: '700' }}>Discount Type:</span> {editData?.discountType}</p>
                                <p><span style={{ fontWeight: '700' }}>inimum Purchase Value:</span> {editData?.minimumPurchase}</p>
                                <p><span style={{ fontWeight: '700' }}>Remianing Days:</span> {editData?.remainingDays}</p>
                                <p><span style={{ fontWeight: '700' }}>Usage Limit Per User:</span> {editData?.usageLimitPerUser}</p>
                                <p><span style={{ fontWeight: '700' }}>Coupon Usage Limit:</span> {editData?.usageLimitOverall}</p>
                                <p><span style={{ fontWeight: '700' }}>Coupon Start Date:</span> {formattedstartDate}</p>
                                <p><span style={{ fontWeight: '700' }}>Coupon Expiry Date:</span> {formattedexpiryDate}</p>
                                <p><span style={{ fontWeight: '700' }}>Free Shipping:</span> {editData?.freeShipping === false ? "No" : "Yes"}</p>
                                <p><span style={{ fontWeight: '700' }}>Coupon Hidden:</span> {editData?.isHidden === false ? "No" : "yes"}</p>
                                <p>
                                    <span style={{ fontWeight: '700' }}>Products: </span>{editData?.products?.map((product) => product?.name).join(", ")}
                                </p>
                                <p>
                                    <span style={{ fontWeight: '700' }}>Eligibility Customer:</span>
                                    {editData?.customerEligibility === "both"
                                        ? "Both New And Old Customers"
                                        : editData?.customerEligibility === "new"
                                            ? "New Customers"
                                            : "Old Customers"}
                                </p>
                                <p><span style={{ fontWeight: '700' }}>Description:</span> {editData?.description}</p>
                                <p>
                                    <span style={{ fontWeight: '700' }}> Instruction:</span><div>
                                        {editData?.instructions?.map((product, index) => (
                                            <div key={index}>{product?.description}</div>
                                        ))}
                                    </div>
                                </p>
                            </div>

                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default CouponView;
