import React, { useEffect, useState } from 'react'
import TitleComponent from './TitleComponent'
import { Col, Row } from 'reactstrap'
import ProductCard from './ProductCard'
import { ArrowRight } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import axios from 'axios'
import { product } from '../../ApiConfigs/ApiConfig'

function OfferProductSection() {
    // const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    const navigate = useNavigate()

    const offerProductData = async () => {
        try {
            // setLoading(true);
            const response = await axios.get(product);
            const productsWithOffer = response?.data?.result?.filter(product => product.offer);
            setData(productsWithOffer);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            // setLoading(false);
        }
    };
    useEffect(() => {
        offerProductData()
    }, [])

    return (
        <div className='py-5 container-custom bg-add'>
            <TitleComponent title={`Offer Products`} />
            <div className='mt-3 mt-md-5'>
                <Row>
                    {dataa?.slice(0, 8)?.map((item, i) => (
                        <Col key={i} sm={12} md={6} lg={4} xl={3}>
                            <ProductCard data={item} />
                        </Col>
                    ))}
                </Row>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-3'>
                <button onClick={() => navigate('/offerProducts')} className='explore-btn'>Explore All <ArrowRight size={18} /></button>
            </div>
        </div>
    )
}

export default OfferProductSection
