import React, { useRef, useState } from 'react';
import { X } from 'react-feather';
import './index.css';
import { fileUpload } from '../../../../ApiConfigs/ApiConfig';
import axios from 'axios';
import toast from 'react-hot-toast';
import { FormGroup, Input } from 'reactstrap';

function UploadTool({ handleActiveToolsTab, onImageAdd, images, setImages, deleteImageFromCanvas, isEdit, unselect, replaceImage }) {
    const fileInputRef = useRef(null);
    const [isRemoveBackgroundEnabled, setIsRemoveBackgroundEnabled] = useState(false);

    const handleFileChange = async (e) => {
        const file = e.target.files[0]; // Only one file is allowed
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            try {
                // Upload the image to the API
                const response = await axios.post(fileUpload, formData);
                const uploadedImgUrl = response?.data?.result[0]?.location;

                if (uploadedImgUrl) {
                    // Add the uploaded image to the canvas via parent
                    onImageAdd(uploadedImgUrl);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            }
        }
    };

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteImage = (id) => {
        // Remove the image from the canvas via parent
        deleteImageFromCanvas(id);
    };

    console.log(isEdit);

    const handleRemoveBackgroundFromUrl = async (imageUrl) => {
        if (!imageUrl) return alert("Please provide an image URL!");

        try {
            // Fetch the image from the provided URL
            const imageResponse = await fetch(imageUrl);
            if (!imageResponse.ok) {
                throw new Error('Failed to fetch the image from the URL');
            }

            const imageBlob = await imageResponse.blob();

            const formData = new FormData();
            formData.append('image_file', imageBlob);

            // Call the Remove.bg API
            const response = await fetch('https://api.remove.bg/v1.0/removebg', {
                method: 'POST',
                headers: {
                    'X-Api-Key': '9QfbfozdkszREv2FzAmHLWqD', // Replace with your Remove.bg API key
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to remove background');
            }

            const processedBlob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(processedBlob);
            reader.onloadend = () => {
                replaceImage(isEdit.id, reader.result);
            };
            setIsRemoveBackgroundEnabled(false)
        } catch (error) {
            console.error('Error removing background:', error);
            alert('Error removing background. Please try again.');
        }
    };


    const handleImageReplace = () => {
        if (!isEdit || isEdit.type !== 'image') {
            console.error('No image selected!');
            return;
        }

        // Get the URL of the selected image
        const currentImageUrl = isEdit.getSrc();

        console.log('Selected Image URL:', currentImageUrl);

        handleRemoveBackgroundFromUrl(currentImageUrl)

        // Replace the image

    };

    const handleToggle = () => {
        setIsRemoveBackgroundEnabled(!isRemoveBackgroundEnabled);
        if (!isRemoveBackgroundEnabled) {
            handleImageReplace(); // Call the function when toggled ON
        }
    };

    return (
        <div>
            <div>
                <h1 className='tool-title'>UPLOAD ART</h1>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='tool-sub-title'>{isEdit ? "Edit Your Artwork" : "Choose file to upload"}</h1>
                    {isEdit ? (
                        <span
                            onClick={unselect}
                            className='cursor-action'
                        >
                            <X />
                        </span>
                    ) : (
                        <span
                            onClick={() => handleActiveToolsTab('product')}
                            className='cursor-action'
                        >
                            <X />
                        </span>
                    )}
                </div>
                {isEdit ? (
                    <p className="tool-desc">
                        Our design professionals will select ink colors for you or tell us your preferred colors at checkout.
                    </p>
                ) : (
                    <p className="tool-desc">
                        Upload <strong className='upload-img-label'>.png</strong>,{' '}
                        <strong className='upload-img-label'>.jpg</strong>,{' '}
                        <strong className='upload-img-label'>.jpeg</strong>, and files
                        less than <strong className='upload-img-label'>5MB</strong>.
                    </p>
                )}
                <div className='tool-bar-line'></div>
            </div>
            {!isEdit && (
                <>
                    <div className='mt-4 tool-upload-con p-4'>
                        <h1 className='tool-upload-label'>
                            Drag & Drop Artwork Files or
                        </h1>
                        <button
                            className='tool-upload-btn'
                            onClick={handleFileButtonClick}
                        >
                            Choose File
                        </button>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            accept=".png,.jpg,.jpeg"
                        />
                    </div>
                    {images.length > 0 && (
                        <div className='uploaded-images-list mt-4'>
                            <h1 className='tool-upload-label'>Uploaded Images</h1>
                            <div className='d-flex gap-3 flex-wrap mt-4'>
                                {images.map((image) => (
                                    <div className='image-con-cus shadow'>
                                        <img src={image.url} alt="Uploaded" className='img-cus' />
                                        <div onClick={() => handleDeleteImage(image.id)} className='image-con-cus-cancel'>
                                            <span><X size={20} /></span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
            {isEdit && (
                <>
                    <div className='mt-4'>
                        <div className='mb-3 d-flex justify-content-between align-items-center gap-3'>
                            <label className='text-editor-label cursor-pointer'>Remove Background</label>
                            <FormGroup switch>
                                <Input
                                    style={{ width: '50px' }}
                                    type="switch"
                                    role="switch"
                                    checked={isRemoveBackgroundEnabled}
                                    onChange={handleToggle} // Call the toggle handler here
                                />
                            </FormGroup>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default UploadTool;
