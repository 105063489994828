import React, { useState, useEffect, useRef } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import './index.css';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel'

const ImageSlider = ({ images }) => {
    const [selectedImage, setSelectedImage] = useState(images?.[0] || null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    // const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

    // Handle screen size changes
    // useEffect(() => {
    //     const handleResize = () => setIsLargeScreen(window.innerWidth > 768);
    //     window.addEventListener('resize', handleResize);

    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    // Automatic image slider (paused on hover)
    useEffect(() => {
        if (images?.length > 0 && !isHovered) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [images, isHovered]);

    // Update selected image when index changes
    useEffect(() => {
        if (images?.[currentIndex]) {
            setSelectedImage(images[currentIndex]);
        }
    }, [currentIndex, images]);

    if (!images || images.length === 0) {
        return null; // Return nothing if no images are provided
    }

    const handleImageClick = (image, index) => {
        setSelectedImage(image);
        setCurrentIndex(index);
    };

    const responsiveTestimonial = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            {/* Desktop Version */}
            <div className="d-none d-lg-block">
                <div className="position-relative">
                    <div
                        className="main-image-container position-relative"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {/* {selectedImage && (
                            <div className="main-image">
                                {isLargeScreen ? (
                                    <ReactImageMagnify
                                        {...{
                                            smallImage: {
                                                alt: 'main',
                                                isFluidWidth: true,
                                                src: selectedImage,
                                            },
                                            largeImage: {
                                                src: selectedImage,
                                                width: 1000,
                                                height: 1300,
                                            },
                                        }}
                                    />
                                ) : (
                                    <img src={selectedImage} alt="main" className="main-image" />
                                )}
                            </div>
                        )} */}
                        {selectedImage && (
                            <ReactImageMagnify
                                {...{
                                    smallImage: {
                                        alt: 'main',
                                        isFluidWidth: true,
                                        src: selectedImage
                                    },
                                    largeImage: {
                                        src: selectedImage,
                                        width: 800,  // Full-size image width
                                        height: 1000  // Full-size image height
                                    },
                                    enlargedImagePosition: "over" // Places magnified image over the small image
                                }}
                                className="main-image"
                            />
                        )}
                    </div>
                    <div className="image-slider position-absolute top-0 start-0">
                        <div className="thumbnail-container">
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`thumbnail-${index}`}
                                    className={`thumbnail ${selectedImage === image ? 'selected-thumbnail' : ''}`}
                                    onClick={() => handleImageClick(image, index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-lg-none'>
                <div className='position-relative'>
                    <Carousel
                        ref={carouselRef}
                        responsive={responsiveTestimonial}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        arrows={false}
                        autoPlaySpeed={2000}
                        infinite={true}
                        dotListClass=""
                        itemClass=""
                        className=''
                    >
                        {images?.map((image, i) => (
                            <img
                                style={{ width: '100%', height: '380px', objectFit: 'contain' }}
                                key={i}
                                src={image}
                                alt={`thumbnail-${i}`}
                            />
                        ))}
                    </Carousel>
                    <img alt='frame' onClick={() => carouselRef.current.previous()} className='left-arrow-cat cursor-pointer' height={40} src='https://printontesting.s3.ap-south-1.amazonaws.com/printon/common_20241121173320239/Frame%201597886257.png' />
                    <img alt='frame' onClick={() => carouselRef.current.next()} className='right-arrow-cat cursor-pointer' height={40} src='https://printontesting.s3.ap-south-1.amazonaws.com/printon/common_20241121173320239/Frame%201597886258.png' />
                </div>
            </div>
        </>
    );
};

export default ImageSlider;