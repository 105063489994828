import React, { useEffect, useState } from 'react'
import './index.css'
import logo from '../../../../assets/images/logo/printon.png'
import whatsappIcon from "../../../../assets/images/landing/icons/ic_baseline-whatsapp.png"
import chervonIcon from "../../../../assets/images/landing/icons/gridicons_dropdown.png"
import userIcon from "../../../../assets/images/landing/icons/iconamoon_profile-light.png"
import { Link } from 'react-router-dom'

function MobileCustomizationHeader() {
    const [name, setName] = useState()
    const [mobile, setMobile] = useState()

    useEffect(() => {
        const name = localStorage.getItem('name');
        const mobile = localStorage.getItem('mobile');

        setName(name);
        setMobile(mobile);
    }, []);
    return (
        <div className='mobile-customization-header shadow-sm'>
            <Link to={'/'}>
                <img alt='logo' className='img-fluid logo-mobile' src={logo} />
            </Link>
            <div className='cursor-action d-flex align-items-center'>
                <div>
                    <div className='d-flex align-items-center'>
                        <p style={{ color: '#E45D25' }} className='nav-list-new p-0 m-0 '>Design Support</p>
                        <img alt='' height={25} src={chervonIcon} />
                    </div>
                    <div className='d-flex gap-1 align-items-center'>
                        <img alt='' height={26} src={whatsappIcon} />
                        <p className='m-0 p-0 wht-app-no'>+91 7708517447</p>
                    </div>
                </div>
                <div style={{ padding: '5px 10px 5px 10px', height: '55px' }} className='d-flex gap-1 align-items-center justify-content-center cursor-action'>
                    <img alt='' height={25} src={userIcon} />
                    {localStorage.getItem('token') ? (
                        <p className='nav-list-new m-0 p-0'>
                            {!name ? (`***${mobile?.slice(6) || ''}`) : (name || '')}
                        </p>
                    ) : (
                        <p className='nav-list-new m-0 p-0'>
                            Login
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MobileCustomizationHeader
