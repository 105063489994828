import React, { useCallback, useEffect, useState } from 'react'
import { ArrowLeft, X } from 'react-feather'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import './index.css'
import { product, productCategory } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FinalAuthModal from '../../navbar/finalAuth';

function ChooseProductModal({ isOpen, toggle }) {
    const [dataa, setData] = useState();
    const [prodtData, setProductData] = useState();
    // const [loading, setLoading] = useState(false);
    // const [active    Tab, setActiveTab] = useState(0);
    const [activeTabId, setActiveTabId] = useState();
    const [isCardSelected, setIsCardSelected] = useState(false);
    const [cardData, setCardData] = useState();
    const navigate = useNavigate()
    const [isToggle, setToggle] = useState(false)
    const isLogin = localStorage.getItem('role')
    const toggleEmail = () => {
        setToggle(!isToggle)
    }

    const categoryData = useCallback(async () => {
        try {
            const response = await axios.get(productCategory);
            const categories = response?.data?.result;
            setData(categories);

            // Only set activeTabId if it's not already set
            if (categories.length > 0 && !activeTabId) {
                setActiveTabId(categories[0]?._id);  // Set the first category as default
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }, [activeTabId]); // Include activeTabId as a dependency since it's used in the function.

    useEffect(() => {
        categoryData();
    }, [categoryData]);

    const productData = async (categoryId) => {
        try {
            // setLoading(true);
            const url = product;
            const response = await axios.get(`${url}?productCategory=${categoryId}`);
            setProductData(response?.data?.result);
        } catch (error) {
            toast.error("Failed to load product data");
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        if (activeTabId) {
            productData(activeTabId);
        }
    }, [activeTabId]);

    const navToPage = (id) => {
        navigate(`/products/productCustomize/${id}`)
    }

    const unSelect = () => {
        setIsCardSelected(false)
        setCardData('')
    }
    const selectedCard = (data) => {
        setIsCardSelected(true)
        setCardData(data)
    }

    const navigate1 = (data) => {
        navigate(`/${cardData?.product_url}`);
        toggle();
    }

    const handleClick = () => {
        if (!isLogin) {
            // toast.error('Please login');
            toggleEmail();
        } else {
            navToPage(cardData?._id);
        }
    };

    return (
        <>        <Modal isOpen={isOpen} toggle={toggle} size='lg' className="modern-modal" style={{ border: 'none' }}>
            <ModalBody className='p-0 m-0 position-relative'>
                <div className='p-3 border-bottom border-1 d-flex justify-content-between align-items-center'>
                    <h1 className='h4 m-0 p-0'>What product are looking for?</h1>
                    <span className='cursor-pointer' onClick={toggle}><X size={25} /></span>
                </div>
                <div>
                    <Row>
                        <Col sm={12} md={4}>
                            <div className='border-end border-1'>
                                <div className='border-bottom border-1 p-3'>
                                    <h1 className='h5 p-0 m-0'>CATEGORIES</h1>
                                </div>
                                <div className='category-list'>
                                    {dataa?.map((eachItem, i) => (
                                        <div
                                            key={i}
                                            onClick={() => {
                                                setActiveTabId(eachItem?._id)
                                                setIsCardSelected(false)
                                            }}
                                            className={`p-3 hover-category ${activeTabId === eachItem?._id ? 'active-cat' : ''}`}>
                                            <p className='p-0 m-0'>{eachItem?.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={8} style={{ minHeight: '300px' }}>
                            {isCardSelected ? (
                                <div className='h-100 w-100 d-flex flex-column justify-content-center align-items-center pt-5 pb-5'>
                                    <div className='w-75 d-flex'>
                                        <span className='cursor-pointer modal-txt-design-2' onClick={unSelect}><ArrowLeft size={22} /> Back</span>
                                    </div>
                                    <h1 className='h5 mb-3 mt-4 text-center w-75'>Please choose either "View Product" or "Customize Design" to continue.</h1>
                                    <div className='cursor-pointer mb-2 d-flex flex-column align-items-center text-center'>
                                        <img className='product-img-rnd' src={cardData?.image} alt='banner-logo' />
                                        <h1 className='title-product h6'>{cardData?.name}</h1>
                                        <div className='mt-2'>
                                            {/* <button onClick={() => navigate(`/${cardData?.product_url}`)} className='modal-btn-design-1 ps-3 pe-3 me-1'>Upload Design</button> */}
                                            <button onClick={navigate1} className='modal-btn-design-1 ps-3 pe-3 me-1'>View Product</button>
                                            {cardData?.customizeProduct &&
                                                <button onClick={handleClick} className='modal-btn-design-2 ps-3 pe-3'>Customize Design</button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            ) : (
                                <Row className='pt-3 pb-3 product-list-modal'>
                                    {prodtData?.map((eachProduct, i) => (
                                        <Col sm={12} md={6} lg={4}>
                                            <div key={i} onClick={() => selectedCard(eachProduct)} className='cursor-pointer mb-3 d-flex flex-column align-items-center text-center'>
                                                <img className='product-img-rnd' src={eachProduct?.image} alt='banner-logo' />
                                                <h1 className='title-product mt-1'>{eachProduct?.name}</h1>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            </ModalBody>
        </Modal >
            <FinalAuthModal isOpen={isToggle} toggle={toggleEmail} />
        </>

    )
}

export default ChooseProductModal
