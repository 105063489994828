import React, { useCallback, useEffect, useState } from 'react'
import FooterSection from '../footer'
import Navbar from '../navbar'
import TitleComponent from '../homeNew/TitleComponent'
import { AlertCircle, Search } from 'react-feather'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import axios from 'axios'
import { product, template } from '../../ApiConfigs/ApiConfig'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

function BrowseDesignIndex() {
    const [loading, setLoading] = useState(false);
    const [templateDetails, setTemplateDetails] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    // const [selectedStatusDate, setSelectedDate] = useState('allDates');
    const [searchTerm, setSearchTerm] = useState('');
    const { id } = useParams()
    const navigate = useNavigate()
    const [modal, setModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [searchParams] = useSearchParams();
    const category = searchParams.get('category');
    const [dataa, setData] = useState([]);
    const toggle = () => {
        setModal(!modal)
    }

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value); // Update state with input value
    };
    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        setSelectedStatus(name);  // Only store the clicked checkbox value
    };
    // const handleCheckboxChange1 = (e) => {
    //     const { name } = e.target;
    //     setSelectedDate(name);  // Only store the clicked checkbox value
    // };

    const formatString = (str) => {
        return str
            .split('-') // Split by hyphen
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
            .join(' '); // Join back with spaces
    };

    // GET - Template Details
    useEffect(() => {
        setLoading(true);
        const templateDataGet = async () => {
            try {
                const response = await axios.get(`${template}?product_url=${selectedStatus === 'all' ? '' : selectedStatus || id}&perPage=1000&category_url=${category}&search=${searchTerm}`);
                setTemplateDetails(response?.data?.result?.rows);
            } catch (error) {
                console.error('Error fetching product data:', error);
            } finally {
                setLoading(false);
            }
        };
        templateDataGet();
    }, [id, selectedStatus, searchTerm, category]); // Added 'category' to dependencies

    useEffect(() => {
        setSelectedStatus(id)
    }, [id])

    const additionalData = useCallback(async () => {
        try {
            setLoading(true);
            let url = product;
            const response = await axios.get(`${url}?productCategory=${category}`);
            setData(response?.data?.result);
        } catch (error) {
            console.error("Error fetching additional data", error);
        } finally {
            setLoading(false);
        }
    }, [category]); // Memoize with category as the dependency

    useEffect(() => {
        additionalData();
    }, [category, additionalData]); // Now additionalData is memoized and stable

    const handlePreview = (item) => {
        setSelectedTemplate(item)
        toggle()
    }

    const loadBtnFunc = () => {
        navigate(`/products/productCustomize/${selectedTemplate?.product?._id}?template=${selectedTemplate?._id}`)
    }

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    return (
        <div>
            <Navbar />
            <div className='pt-5' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', background: '#FAFAFA' }}>
                <div>
                    <TitleComponent title={selectedStatus === 'all' ? formatString(category) : formatString(selectedStatus)} isDesc={true} desc={`Explore our collection of pre-designed templates to customize your products effortlessly. Choose from a variety of professionally crafted designs and personalize them to suit your needs. Start creating unique and stunning designs in just a few clicks!`} />
                </div>
                <div style={{ width: '100%' }} className='d-flex align-items-center mt-4 mt-lg-3'>
                    <h4 className="">Browse Design</h4>
                    {/* <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} /> */}
                </div>
                <div className='mt-3 order'>
                    <Row>
                        <Col sm={12} lg={3}>
                            <div className='filtedr-con'>
                                <div className='p-3 border-bottom border-1'>
                                    <h1 className='h4 p-0 m-0'>Filters</h1>
                                </div>
                                <div className='p-3 pt-4 pb-4 border-1 border-bottom'>
                                    <h1 className='h5 p-0 m-0' style={{ font: 'Manrope', }}>Substrate Color</h1>
                                    <div className='mb-2 mt-3'>
                                        <Input
                                            id="checkAll"
                                            name="all"
                                            type="checkbox"
                                            checked={selectedStatus === 'all'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="checkAll">
                                            All
                                        </Label>
                                    </div>
                                    {dataa?.map((item, i) => (
                                        <div key={i} className='mb-2 mt-3 d-flex'>
                                            <Input
                                                id="checkAll"
                                                name={item?.product_url}
                                                type="checkbox"
                                                checked={selectedStatus === item?.product_url}
                                                onChange={handleCheckboxChange}
                                            />
                                            <Label className='ms-3 mt-1  fw-medium' check for="checkAll">
                                                {item?.name}
                                            </Label>
                                        </div>
                                    ))}
                                </div>
                                {/* <div className='p-3 pb-4 pt-4 border-1 border-bottom'>
                                    <h1 className='h5 p-0 m-0' style={{ font: 'Manrope', }}>Type</h1>
                                    <div className='mb-2 mt-3'>
                                        <Input
                                            id="AllOrders"
                                            name="allDates"
                                            type="checkbox"
                                            checked={selectedStatusDate === 'allDates'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="AllOrders">
                                            All
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last24hours"
                                            name="1"
                                            type="checkbox"
                                            checked={selectedStatusDate === '1'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1 fw-medium' check for="Last24hours">
                                            Last 24 hours
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last3days"
                                            name="3"
                                            type="checkbox"
                                            checked={selectedStatusDate === '3'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last3days">
                                            Last 3 days
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last7days"
                                            name="7"
                                            type="checkbox"
                                            checked={selectedStatusDate === '7'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last7days">
                                            Last 7 days
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last14days"
                                            name="14"
                                            type="checkbox"
                                            checked={selectedStatusDate === '14'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last14days">
                                            Last 14 days
                                        </Label>
                                    </div>
                                </div> */}
                            </div>
                        </Col>
                        <Col sm={12} lg={9}>
                            <div className='d-flex flex-column flex-lg-row  seaaa'>
                                <input
                                    className='my-order-search'
                                    type='text'
                                    placeholder='Search...'
                                    value={searchTerm} // Controlled input
                                    onChange={handleInputChange} // Call the handler on change
                                />
                                <button className='my-order-search-btn mt-2 mt-lg-0'><Search size={26} /> Search Order</button>
                            </div>
                            {loading ? (
                                <div
                                    style={{
                                        width: '100%',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: '50px'
                                    }}
                                >
                                    <Spinner color="primary">Loading...</Spinner>
                                </div>
                            ) : (
                                <>
                                    {templateDetails.length > 0 ? (
                                        <div className='mt-3'>
                                            <Row className=''>
                                                {templateDetails?.map((item, i) => (
                                                    <Col
                                                        onClick={() => handlePreview(item)}
                                                        sm={12} md={4}
                                                        className='mb-3 template-con-1'
                                                    >
                                                        <img alt='template' draggable={false} className='img-fluid template-img-1 shadow-sm' src={item?.templateImages?.[0]?.img_url} />
                                                        <p className='text-center p-0 m-0 mt-2 template-text-1'>{item?.title}</p>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    ) : (
                                        <h1 className='h5 text-center mt-5'><AlertCircle size={30} /> No Data Found!!!</h1>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>{selectedTemplate?.title}</ModalHeader>
                    <ModalBody>
                        <Carousel
                            responsive={responsive}
                            autoPlay={true}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            arrows={true}
                            autoPlaySpeed={2000}
                            infinite={true}
                        >
                            {selectedTemplate?.templateImages.map((image, index) => (
                                <div
                                    key={index}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                >
                                    <img
                                        className="mb-3"
                                        style={{ width: '300px' }}
                                        alt=""
                                        src={
                                            typeof image?.img_url === 'string' && image?.img_url.endsWith('.pdf')
                                                ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png'
                                                : image?.img_url
                                        }
                                    />
                                </div>
                            ))}
                        </Carousel>
                        <div style={{ justifyContent: 'end' }} className="modal-footer mt-3">
                            <Button onClick={loadBtnFunc} style={{ backgroundColor: "#E4510B", border: 'none' }} >
                                <span >Load Template</span>
                            </Button>
                            <Button color="secondary" onClick={toggle} >
                                Cancel
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
            <FooterSection />
        </div>
    )
}

export default BrowseDesignIndex
