import React, { useState } from 'react'
import './index.css'
import { X } from 'react-feather'
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

function TemplateTool({ handleActiveToolsTab, templateDetails, loadTemplateToCanvas, toggleTools }) {
    const [modal, setModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState()
    const toggle = () => {
        setModal(!modal)
    }
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const handlePreview = (item) => {
        setSelectedTemplate(item)
        toggle()
    }

    const loadBtnFunc = () => {
        const canvasData = JSON.parse(selectedTemplate?.data)
        loadTemplateToCanvas(canvasData, selectedTemplate)
        toggle()
        toggleTools()
    }
    return (
        <>
            <div>
                <h1 className='tool-title'>Template</h1>
                <div className='d-flex justify-content-between align-items-center'>
                    <h1 className='tool-sub-title'>Choose Perfect Template</h1>
                    <span onClick={() => handleActiveToolsTab('product')} className='cursor-action'><X /></span>
                </div>
                <Row className='mt-4'>
                    {templateDetails?.map((item, i) => (
                        <Col onClick={() => handlePreview(item)} sm={12} md={6} className='mb-3 template-con-1'>
                            <img alt='template' draggable={false} className='img-fluid template-img-1 shadow-sm' src={item?.templateImages?.[0]?.img_url} />
                            <p className='text-center p-0 m-0 mt-2 template-text-1'>{item?.title}</p>
                        </Col>
                    ))}
                </Row>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>{selectedTemplate?.title}</ModalHeader>
                <ModalBody>
                    <Carousel
                        responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        arrows={true}
                        autoPlaySpeed={2000}
                        infinite={true}
                    >
                        {selectedTemplate?.templateImages.map((image, index) => (
                            <div
                                key={index}
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <img
                                    className="mb-3"
                                    style={{ width: '300px' }}
                                    alt=""
                                    src={
                                        typeof image?.img_url === 'string' && image?.img_url.endsWith('.pdf')
                                            ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png'
                                            : image?.img_url
                                    }
                                />
                            </div>
                        ))}
                    </Carousel>
                    <div style={{ justifyContent: 'end' }} className="modal-footer mt-3">
                        <Button onClick={loadBtnFunc} style={{ backgroundColor: "#E4510B", border: 'none' }} >
                            <span >Load Template</span>
                        </Button>
                        <Button color="secondary" onClick={toggle} >
                            Cancel
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default TemplateTool
