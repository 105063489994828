import React from 'react';
import logo1 from '../assets/images/whatsapp (2).png';
import { useLocation } from 'react-router-dom';

function WhatsappIcon() {
    const { pathname } = useLocation();

    // Check if the path matches the pattern /products/productCustomize/:id
    const isProductCustomizePath = /^\/products\/productCustomize\/[a-zA-Z0-9]+$/.test(pathname);

    return (
        <div>
            {!isProductCustomizePath && ( // Conditionally render the icon
                <a
                    className='whatsappLink'
                    rel="noreferrer"
                    href={`https://wa.me/+919901897005?text=Hello%2C%20I%27m%20interested%20in%20your%20products.%20Can%20you%20provide%20more%20details%3F`}
                    target='_blank'>
                    <img className='whatsapp-sticky' src={logo1} alt='whatsapp-icon' />
                </a>
            )}
        </div>
    );
}

export default WhatsappIcon;
