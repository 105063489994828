// export const baseUrl = 'https://apiprinton.aata.in'
// export const baseUrl = 'http://192.168.0.124:7011'
// export const baseUrl = "https://api.printon.co.in";
export const domainUrl = "https://printon.co.in";
export const baseUrl = 'https://devapi.printon.co.in';

const productCategory = `${baseUrl}/auth/productCategory`;
const productCategoryAdmin = `${baseUrl}/productCategory`;
const productDraft = `${baseUrl}/product-draft`;
const couponCode = `${baseUrl}/couponCode`;
const razorpay = `${baseUrl}/order/razorpay`;
const callbackrazor = `${baseUrl}/callback/razorpay`;
const duplicateProduct = `${baseUrl}/duplicateProduct`;
const paymentOptions1 = `${baseUrl}/paymentOptions`;
const paymentOptionStatus = `${baseUrl}/paymentOptions-status`;
const metaData = `${baseUrl}/metadata`;
const sizeChartProduct = `${baseUrl}/sizeChartProduct`;
const sizeChartCategory = `${baseUrl}/sizeChartCategory`;
const product = `${baseUrl}/auth/product`;
const position = `${baseUrl}/viewPosition`;
const productOG = `${baseUrl}/product`;
const fieldTypeApiNew = `${baseUrl}/auth/fieldType`;
const order = `${baseUrl}/auth/order`;
const fieldOption = `${baseUrl}/auth/fieldOptions`;
const fileUpload = `${baseUrl}/auth/upload`;
const otpSend = `${baseUrl}/otp/send`;
const otpVerify = `${baseUrl}/otp/verify`;
const signIn = `${baseUrl}/signin`;
const signUp = `${baseUrl}/signup`;
const ChangeAddress = `${baseUrl}/changeAddress`;
const CheckPincode = `${baseUrl}/deliveryCharge`;
const CheckPincode1 = `${baseUrl}/auth/deliveryCharge`;
const GstTotal = `${baseUrl}/totalAmount`;
const defaultAddress = `${baseUrl}/defaultAddress`;
const checkMobile = `${baseUrl}/auth/mobileCheck`;
const checkEmail = `${baseUrl}/auth/checkEmail `;
const addCart = `${baseUrl}/addCart`;
const productSats = `${baseUrl}/product-stats`;
const cart = `${baseUrl}/cart`;
const shippingAddress = `${baseUrl}/shippingAddress`;
const orderPost = `${baseUrl}/order`;
const placeOrder = `${baseUrl}/placeOrder`;
const cartCount = `${baseUrl}/cart-count-update`;
const productCategoryApi = `${baseUrl}/productCategory`;
const productCategoryStatus = `${baseUrl}/category-status`;
const fieldOptionApi = `${baseUrl}/fieldOptions`;
const fieldTypeApi = `${baseUrl}/fieldType`;
const productApi = `${baseUrl}/product`;
const removeCart = `${baseUrl}/remove-cart`;
const forgetPassword = `${baseUrl}/forgot/password`;
const resetPassword = `${baseUrl}/reset/password`;
const orderStats = `${baseUrl}/order-stats`;
const productType = `${baseUrl}/productType`;
const productRateUpdate = `${baseUrl}/productRateUpdate`;
const quantityCountApi = `${baseUrl}/auth/product-dropdown`;
const speedSale = `${baseUrl}/auth/product?speedSale=yes`;
const productFieldType = `${baseUrl}/productFieldType`;
const getCartRate = `${baseUrl}/auth/getCartRate`;
const getOfferProduct = `${baseUrl}/offer`;
const wishList = `${baseUrl}/wishList`;
const socialMedia = `${baseUrl}/socialMedia`;
const questions = `${baseUrl}/question`;
const askedQueAns = `${baseUrl}/askedQueAns`;
const answer = `${baseUrl}/answer`;
const payId = `${baseUrl}/pay`;
const rateandreview = `${baseUrl}/ratingReview`;
const deliveryDays = `${baseUrl}/deliveryDetails `;
const subscriber = `${baseUrl}/auth/subscriber`;
const mobileCheck = `${baseUrl}/auth/mobileCheck`;
const emailChecking = `${baseUrl}/auth/emailCheck`;
const productSearch = `${baseUrl}/auth/productSearch`;
const productTypeSearch = `${baseUrl}/auth/productType`;
const multiUpload = `${baseUrl}/auth/mulit_upload`;
const orderStatus = `${baseUrl}/order-status`;
const profile = `${baseUrl}/profile`;
const state = `${baseUrl}/state`;
const district = `${baseUrl}/district`;
const profileEdit = `${baseUrl}/profile-update`;
const getFieldTypeOptions = `${baseUrl}/auth/getFieldType`;
const textPosition = `${baseUrl}/textPosition`;
const viewPosition = `${baseUrl}/viewPosition`;
const addQuantityBasedAmount = `${baseUrl}/addQuantity`;
const editQuantityBasedAmount = `${baseUrl}/quantityBasedAmount`;
const productTable = `${baseUrl}/productTable`;
const customerProducts = `${baseUrl}/customerProducts`;
const menuProduct = `${baseUrl}/auth/menuCombo`;
const menuProductAd = `${baseUrl}/menuCombo`;
const productDropdown = `${baseUrl}/auth/dropdown-product`;
const categoryDropdown = `${baseUrl}/auth/category-dropdown`;
const news = `${baseUrl}/newsTiker`;
const newsTikerStatus = `${baseUrl}/newsTikerStatus`;
const cusNewsTiker = `${baseUrl}/auth/newsTiker`;
const productFAQ = `${baseUrl}/productFAQ`;
const productDescription = `${baseUrl}/productDescription`;
const categoryFAQ = `${baseUrl}/categoryFAQ`;
const menusStatus = `${baseUrl}/menuComboStatus`;
const fieldType = `${baseUrl}/fieldType-table`;
const categoryGet = `${baseUrl}/category-table`;
const offerPro = `${baseUrl}/offer-table`;
const productStatus = `${baseUrl}/product-status`;
const couponStatus = `${baseUrl}/couponCode/status`;
const orderNotesStatusUpate = `${baseUrl}/orderNote-approve`;
const trackingDetails = `${baseUrl}/trackingDetails`;
const cancelOrder = `${baseUrl}/cancel-product`;
const cancelReason = `${baseUrl}/cancelReason`;
const productCategoryStats = `${baseUrl}/productCategory-stats`;
const dashboardStats = `${baseUrl}/dashboard-stats`;
const pincode1 = `${baseUrl}/pincode`;
const customizeImage = `${baseUrl}/customizeImage`;
const customizeDesign = `${baseUrl}/customizeDesign`;
const ratingReview = `${baseUrl}/ratingReview`;
const notification = `${baseUrl}/notification`;
const allNotification = `${baseUrl}/clear/notification`;
const deliveryPincode = `${baseUrl}/deliveryPincode`;
const orderNotes = `${baseUrl}/orderNotes`;
const addDistrict1 = `${baseUrl}/add/district`;
const deleteDistrict1 = `${baseUrl}/remove/district`;
const addPincode = `${baseUrl}/add/pincode`;
const deletePincode = `${baseUrl}/remove/pincode`;
const statusUpdate = `${baseUrl}/deliveryPincode/status`;
const locationStatus = `${baseUrl}/customLocation`;
const courierList = `${baseUrl}/courierList`;
const deliveryType = `${baseUrl}/deliveryType`;
const deliveryPartner = `${baseUrl}/deliveryPartner`;
const shippingCharge = `${baseUrl}/shippingCharge-add`;
const deliveryDetails = `${baseUrl}/deliveryDetails`;
const mobileEmailUpdate = `${baseUrl}/mobileEmail-update`;
const mobileEmailVerify = `${baseUrl}/mobileEmail-verify`;
const topProducts = `${baseUrl}/topProducts`;
const topProductsAuth = `${baseUrl}/auth/topProducts`;
const topProductsAdd = `${baseUrl}/topProducts-add`;
const topProductsRemove = `${baseUrl}/topProducts-remove`;
const categories = `${baseUrl}/auth/categories`;
const categoriesAdd = `${baseUrl}/categories-add`;
const canvasData = `${baseUrl}/canvasData`;
const template = `${baseUrl}/template`;
const templateType = `${baseUrl}/templateType`;

export {
  addPincode,
  CheckPincode1,
  deliveryPartner,
  razorpay,
  callbackrazor,
  shippingCharge,
  paymentOptions1,
  metaData,
  paymentOptionStatus,
  couponCode,
  duplicateProduct,
  textPosition,
  viewPosition,
  productDraft,
  position,
  locationStatus,
  sizeChartProduct,
  couponStatus,
  orderNotesStatusUpate,
  sizeChartCategory,
  statusUpdate,
  addDistrict1,
  deleteDistrict1,
  deletePincode,
  productCategory,
  product,
  subscriber,
  checkMobile,
  ChangeAddress,
  defaultAddress,
  checkEmail,
  productSats,
  deliveryDays,
  fieldTypeApiNew,
  CheckPincode,
  GstTotal,
  fieldOption,
  order,
  forgetPassword,
  resetPassword,
  state,
  district,
  fileUpload,
  addCart,
  otpSend,
  otpVerify,
  cart,
  orderPost,
  placeOrder,
  cartCount,
  productCategoryApi,
  productCategoryStatus,
  fieldOptionApi,
  fieldTypeApi,
  productApi,
  removeCart,
  orderStats,
  productType,
  productRateUpdate,
  signIn,
  speedSale,
  productFieldType,
  quantityCountApi,
  signUp,
  getCartRate,
  getOfferProduct,
  wishList,
  socialMedia,
  payId,
  questions,
  askedQueAns,
  answer,
  rateandreview,
  mobileCheck,
  emailChecking,
  productSearch,
  productTypeSearch,
  multiUpload,
  orderStatus,
  profile,
  shippingAddress,
  profileEdit,
  productCategoryAdmin,
  getFieldTypeOptions,
  addQuantityBasedAmount,
  editQuantityBasedAmount,
  productTable,
  customerProducts,
  menuProduct,
  productDropdown,
  categoryDropdown,
  news,
  newsTikerStatus,
  cusNewsTiker,
  productFAQ,
  categoryFAQ,
  menusStatus,
  menuProductAd,
  fieldType,
  categoryGet,
  offerPro,
  productStatus,
  productDescription,
  trackingDetails,
  cancelOrder,
  productOG,
  cancelReason,
  productCategoryStats,
  dashboardStats,
  pincode1,
  customizeImage,
  customizeDesign,
  ratingReview,
  notification,
  allNotification,
  deliveryPincode,
  orderNotes,
  courierList,
  deliveryType,
  deliveryDetails,
  mobileEmailUpdate,
  mobileEmailVerify,
  topProducts,
  topProductsAdd,
  topProductsRemove,
  categories,
  topProductsAuth,
  categoriesAdd,
  canvasData,
  template,
  templateType
};
