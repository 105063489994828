import React, { useCallback, useEffect, useState } from 'react'
import './index.css'
// import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { customerProducts } from '../../ApiConfigs/ApiConfig';
import { Col, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import Navbar from '../navbar';
import FooterSection from '../footer';
import { AlertCircle, Filter, Search, X } from 'react-feather';
import toast from 'react-hot-toast';
// import Preview from './preview';
// import categoryLeft from "../../assets/images/banner/Category-left.png";
// import categoryRight from "../../assets/images/banner/Category-right.png";
import MyOrderCard from './MyOrderCard';
// import PaginationCommon from './PaginationCommon';
import ReactPaginate from 'react-paginate';
import TitleComponent from '../homeNew/TitleComponent';

export const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Add 'th', 'st', 'nd', 'rd' based on the day
    const getDayWithSuffix = (day) => {
        if (day > 3 && day < 21) return day + 'th'; // Handle 11th to 20th
        switch (day % 10) {
            case 1: return day + 'st';
            case 2: return day + 'nd';
            case 3: return day + 'rd';
            default: return day + 'th';
        }
    };
    return `${getDayWithSuffix(day)} ${month}, ${year}`;
};

export function convertTo12HourFormat(dateString) {
    const date = new Date(dateString);

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM or PM
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour time to 12-hour time
    hours = hours % 12;
    hours = hours ? hours : 12; // Adjust 0 to 12 for 12 AM and 12 PM

    // Add leading zero to minutes if needed
    const minutesString = minutes < 10 ? `0${minutes}` : minutes;

    // Return formatted time
    return `${hours}:${minutesString} ${ampm}`;
}

function MyOrderIndex() {

    const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    // const [selectedRating, setSelectedRating] = useState();
    // const [cartData, setCartData] = useState([]);
    // const [modal, setModal] = useState(false);
    // const [customizeImages, setCustomizeImages] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [selectedStatusDate, setSelectedDate] = useState('allDates');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 3;
    const [modal, setModal] = useState(false)

    const toggleModal = () => setModal(!modal)
    // const [rowsPerPage, setRowsPerPage] = useState(3);

    // Handle the input change
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value); // Update state with input value
    };

    const handleCheckboxChange = (e) => {
        const { name } = e.target;
        setSelectedStatus(name);  // Only store the clicked checkbox value
    };

    const handleCheckboxChange1 = (e) => {
        const { name } = e.target;
        setSelectedDate(name);  // Only store the clicked checkbox value
    };


    // const navigate = useNavigate()

    const additionalData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${customerProducts}?status=${selectedStatus}&orderBy=${selectedStatusDate}&search=${searchTerm}&perPage=${rowsPerPage}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}`);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }, [selectedStatus, selectedStatusDate, searchTerm, rowsPerPage, currentPage]);

    useEffect(() => {
        additionalData();
    }, [additionalData]);


    // const handleStarClick = async (ratingValue, id, userId) => {
    //     setSelectedRating(ratingValue);

    //     const payload = {
    //         user: userId,
    //         order: id,
    //         rating: ratingValue,
    //         review: 'good product'
    //     }
    //     try {
    //         setLoading(true);
    //         await axios.post(rateandreview, payload);
    //     } catch (error) {
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    // const toggle = () => setModal(!modal);

    // const viewCustom = (item) => {

    //     if (item?.customizeImages?.length > 1) {
    //         setCustomizeImages(item?.customizeImages);
    //         toggle()
    //     } else {
    //         setCustomizeImages(item?.file);
    //         toggle()
    //     }

    // };

    // useEffect(() => {
    //     additionalData();
    // }, [selectedStatus, selectedStatusDate, searchTerm, currentPage, rowsPerPage])

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    }

    const CustomPagination = (pageCount) => {
        return (
            <>
                <ReactPaginate
                    nextLabel="Next"
                    breakLabel="..."
                    previousLabel="Prev"
                    pageCount={pageCount || 1}
                    activeClassName="active"
                    breakClassName="page-item"
                    pageClassName={'page-item'}
                    breakLinkClassName="page-link"
                    nextLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    nextClassName={'page-item next'}
                    previousLinkClassName={'page-link'}
                    previousClassName={'page-item prev'}
                    onPageChange={(page) => handlePagination(page)}
                    forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                    containerClassName={'pagination react-paginate justify-content-end p-1'}
                />
            </>
        );
    };

    return (
        <>
            <Navbar />
            <div className='pt-5' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%' }}>
                <div className='d-none d-lg-block'>
                    <TitleComponent title={`My Orders`} isDesc={true} desc={`Welcome to your Orders! Track your purchases, view statuses, access invoices, and manage returns with ease.`} />
                </div>
                <div style={{ width: '100%' }} className='d-flex align-items-center mt-0 mt-lg-3'>
                    <h4 className="">My Orders</h4>
                    {/* <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} /> */}
                </div>
                <div className='mt-3 order'>
                    <Row>
                        <Col sm={12} lg={3} className='d-none d-lg-block'>
                            <div className='filtedr-con'>
                                <div className='p-3 border-bottom border-1'>
                                    <h1 className='h4 p-0 m-0'>Filters</h1>
                                </div>
                                <div className='p-3 pt-4 pb-4 border-1 border-bottom'>
                                    <h1 className='h5 p-0 m-0' style={{ font: 'Manrope', }}>ORDER STATUS</h1>
                                    <div className='mb-2 mt-3'>
                                        <Input
                                            id="checkAll"
                                            name="all"
                                            type="checkbox"
                                            checked={selectedStatus === 'all'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="checkAll">
                                            All
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="checkInProgress"
                                            name="process"
                                            type="checkbox"
                                            checked={selectedStatus === 'process'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1 fw-medium' check for="checkInProgress">
                                            In progress
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="shipped"
                                            name="shipped"
                                            type="checkbox"
                                            checked={selectedStatus === 'shipped'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1 fw-medium' check for="shipped">
                                            Shipped
                                        </Label>
                                    </div>

                                    <div className='mb-2'>
                                        <Input
                                            id="delivered"
                                            name="delivered"
                                            type="checkbox"
                                            checked={selectedStatus === 'delivered'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="delivered">
                                            Delivered
                                        </Label>
                                    </div>

                                    {/* <div>
                                        <Input
                                            id="cancelled"
                                            name="cancelled"
                                            type="checkbox"
                                            checked={selectedStatus === 'cancelled'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="cancelled">
                                            Cancelled
                                        </Label>
                                    </div> */}
                                </div>
                                <div className='p-3 pb-4 pt-4 border-1 border-bottom'>
                                    <h1 className='h5 p-0 m-0' style={{ font: 'Manrope', }}>Date Ordered</h1>
                                    <div className='mb-2 mt-3'>
                                        <Input
                                            id="AllOrders"
                                            name="allDates"
                                            type="checkbox"
                                            checked={selectedStatusDate === 'allDates'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="AllOrders">
                                            All
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last24hours"
                                            name="1"
                                            type="checkbox"
                                            checked={selectedStatusDate === '1'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1 fw-medium' check for="Last24hours">
                                            Last 24 hours
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last3days"
                                            name="3"
                                            type="checkbox"
                                            checked={selectedStatusDate === '3'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last3days">
                                            Last 3 days
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last7days"
                                            name="7"
                                            type="checkbox"
                                            checked={selectedStatusDate === '7'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last7days">
                                            Last 7 days
                                        </Label>
                                    </div>
                                    <div className='mb-2'>
                                        <Input
                                            id="Last14days"
                                            name="14"
                                            type="checkbox"
                                            checked={selectedStatusDate === '14'}
                                            onChange={handleCheckboxChange1}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="Last14days">
                                            Last 14 days
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={9}>
                            <div className='d-flex flex-wrap flex-lg-nowrap align-items-center gap-2'>
                                <input
                                    className='my-order-search'
                                    type='text'
                                    placeholder='Search...'
                                    value={searchTerm} // Controlled input
                                    onChange={handleInputChange} // Call the handler on change
                                />
                                <button className='my-order-search-btn'><Search size={26} /> Search Order</button>
                                <span onClick={() => setModal(true)} className='cursor-pointer d-lg-none'><Filter size={28} /></span>
                            </div>
                            {loading ? (
                                <div
                                    style={{
                                        width: '100%',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: '50px'
                                    }}
                                >
                                    <Spinner color="primary">Loading...</Spinner>
                                </div>
                            ) : (
                                <>
                                    {dataa?.rows?.length > 0 ? (
                                        <div className='mt-3'>
                                            {dataa?.rows?.map((eachItem, i) => (
                                                <div key={i}>
                                                    <MyOrderCard data={eachItem} />
                                                </div>
                                            ))}
                                            {/* <PaginationCommon
                                                handlePagination={handlePagination}
                                                pageCount={dataa?.pagination?.pages || 1}
                                                currentPage={currentPage}
                                            /> */}
                                            {CustomPagination(dataa?.pagination?.pages || 1)}
                                        </div>
                                    ) : (
                                        <h1 className='h5 text-center mt-5'><AlertCircle size={30} /> No Data Found!!!</h1>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
                {/* <Preview modal={modal} toggle={toggle} cardImages={customizeImages} /> */}
            </div >
            <FooterSection />

            <Modal isOpen={modal} toggle={toggleModal} className='sidebar-lg'
                modalClassName='modal-slide-in sidebar-todo-modal'
                contentClassName='p-0'>
                <ModalHeader toggle={toggleModal}>Filter</ModalHeader>
                <ModalBody className='flex-grow-1'>
                    <div className='filtedr-con d-lg-none'>
                        <div className='p-3 pt-4 pb-4 border-1 border-bottom'>
                            <h1 className='h5 p-0 m-0' style={{ font: 'Manrope', }}>ORDER STATUS</h1>
                            <div className='mb-2 mt-3'>
                                <Input
                                    id="checkAll"
                                    name="all"
                                    type="checkbox"
                                    checked={selectedStatus === 'all'}
                                    onChange={handleCheckboxChange}
                                />
                                <Label className='ms-3 mt-1  fw-medium' check for="checkAll">
                                    All
                                </Label>
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="checkInProgress"
                                    name="process"
                                    type="checkbox"
                                    checked={selectedStatus === 'process'}
                                    onChange={handleCheckboxChange}
                                />
                                <Label className='ms-3 mt-1 fw-medium' check for="checkInProgress">
                                    In progress
                                </Label>
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="shipped"
                                    name="shipped"
                                    type="checkbox"
                                    checked={selectedStatus === 'shipped'}
                                    onChange={handleCheckboxChange}
                                />
                                <Label className='ms-3 mt-1 fw-medium' check for="shipped">
                                    Shipped
                                </Label>
                            </div>

                            <div className='mb-2'>
                                <Input
                                    id="delivered"
                                    name="delivered"
                                    type="checkbox"
                                    checked={selectedStatus === 'delivered'}
                                    onChange={handleCheckboxChange}
                                />
                                <Label className='ms-3 mt-1  fw-medium' check for="delivered">
                                    Delivered
                                </Label>
                            </div>

                            {/* <div>
                                        <Input
                                            id="cancelled"
                                            name="cancelled"
                                            type="checkbox"
                                            checked={selectedStatus === 'cancelled'}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Label className='ms-3 mt-1  fw-medium' check for="cancelled">
                                            Cancelled
                                        </Label>
                                    </div> */}
                        </div>
                        <div className='p-3 pb-4 pt-4 border-1 border-bottom'>
                            <h1 className='h5 p-0 m-0' style={{ font: 'Manrope', }}>Date Ordered</h1>
                            <div className='mb-2 mt-3'>
                                <Input
                                    id="AllOrders"
                                    name="allDates"
                                    type="checkbox"
                                    checked={selectedStatusDate === 'allDates'}
                                    onChange={handleCheckboxChange1}
                                />
                                <Label className='ms-3 mt-1  fw-medium' check for="AllOrders">
                                    All
                                </Label>
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="Last24hours"
                                    name="1"
                                    type="checkbox"
                                    checked={selectedStatusDate === '1'}
                                    onChange={handleCheckboxChange1}
                                />
                                <Label className='ms-3 mt-1 fw-medium' check for="Last24hours">
                                    Last 24 hours
                                </Label>
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="Last3days"
                                    name="3"
                                    type="checkbox"
                                    checked={selectedStatusDate === '3'}
                                    onChange={handleCheckboxChange1}
                                />
                                <Label className='ms-3 mt-1  fw-medium' check for="Last3days">
                                    Last 3 days
                                </Label>
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="Last7days"
                                    name="7"
                                    type="checkbox"
                                    checked={selectedStatusDate === '7'}
                                    onChange={handleCheckboxChange1}
                                />
                                <Label className='ms-3 mt-1  fw-medium' check for="Last7days">
                                    Last 7 days
                                </Label>
                            </div>
                            <div className='mb-2'>
                                <Input
                                    id="Last14days"
                                    name="14"
                                    type="checkbox"
                                    checked={selectedStatusDate === '14'}
                                    onChange={handleCheckboxChange1}
                                />
                                <Label className='ms-3 mt-1  fw-medium' check for="Last14days">
                                    Last 14 days
                                </Label>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex mt-2'>
                        <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={() => {
                            setSelectedStatus('all')
                            setSelectedDate('allDates')
                        }}>Reset</button>
                        <button className='mt-1 w-50 ms-2 add-modal-btn' onClick={toggleModal}>Save</button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default MyOrderIndex