import React, { useCallback, useEffect, useState } from 'react'
import { categoryGet, productCategoryApi, productCategoryStats, productCategoryStatus } from '../../../ApiConfigs/ApiConfig';
import DataTable from 'react-data-table-component';
import { AlertCircle, Plus, RotateCcw } from 'react-feather';
import axios from 'axios'
import { toast } from 'react-hot-toast'
import './index.css'
// import ProductCategoryAdd from './addModal';
import { Button, Modal, ModalBody, Spinner } from 'reactstrap'
// import ProductCategoryEdit from './editModal';
import Navbar from '../../navbar';
// import FooterSection from '../../footer';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { FiChevronRight } from 'react-icons/fi';
// import img1 from '../../../assets/images/order-icons/Group 1597883090 (1).png'
// import img2 from '../../../assets/images/order-icons/Group 1597883092.png'
// import img3 from '../../../assets/images/order-icons/Group 1597883065 (1).png'
import StatsCard from '../orders/StatsCard';
import { GoTrash } from 'react-icons/go';
import { BiEditAlt } from 'react-icons/bi';
import TitleComponent from '../../homeNew/TitleComponent';

function ProductCategoryIndex() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [statsData, setStatsData] = useState();
    const [deleteData, setDeleteData] = useState();
    const rowsPerPage = 10;
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    // const [resetKey, setResetKey] = useState(0);
    const [sorts, setSorts] = useState('latest')


    const additionalData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${categoryGet}?search=${searchKey}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}&perPage=${rowsPerPage}&sortBy=${sorts}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            setLoading(false);
        }
    }, [searchKey, currentPage, rowsPerPage, sorts]);

    const getStatsData = async () => {
        try {
            const response = await axios.get(`${productCategoryStats}`);
            setStatsData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    // useEffect with additionalData as dependency
    useEffect(() => {
        additionalData(searchKey);
        getStatsData();
    }, [additionalData, searchKey, sorts, currentPage, rowsPerPage]);

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#FCEDE6',
                color: '#333333',
                fontWeight: '500',
                fontSize: '16px',
            },
        },
    };

    // const [editData, setEditData] = useState([])
    // const handleAdd = () => setAddModal(!addModal)
    // const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    // const [editModal, setEditModal] = useState(false)

    const openDeleteModal = (id) => {
        setDeleteData(id);
        setDeleteModal(true);
    }
    const closeDeleteModal = () => {
        setDeleteModal(false);
    }
    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${productCategoryApi}/${deleteData}`)
            closeDeleteModal()
            additionalData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)

        }

    }

    // const openEditModal = (id) => {
    //     getEditModal(id)
    //     setEditModal(true);
    // };

    // const closeEditModal = () => {
    //     setEditModal(false);
    // };

    // const getEditModal = async (id) => {
    //     try {
    //         await axios.get(`${productCategory}/${id}`)
    //         // setEditData(response?.data?.result)
    //     } catch (error) {
    //         toast.error(error.response.data.msg)
    //     }

    // }

    const updateStatus = async (id) => {
        try {
            const response = await axios.post(`${productCategoryStatus}/${id}`);
            additionalData()
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    // const [showFullDescription, setShowFullDescription] = useState(false);

    const truncateDescription = (description, maxLength) => {
        return description.length > maxLength
            ? `${description.slice(0, maxLength)}...`
            : description;
    };


    const additionalTable = [
        {
            name: 'S No',
            maxWidth: '20px',
            cell: (row, i) =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{(i + 1).toString().padStart(2, '0')}</p>
                </>
        },
        {
            name: 'Category Name',
            sortable: false,
            maxWidth: '200px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.name}</p>
                </>
        },
        {
            name: 'Category Image & Description',
            sortable: false,
            maxWidth: '750px',
            cell: row =>
                <>
                    <div className='d-flex'>
                        <img className='d-flex align-items-center' src={row?.image} height={60} width={60} alt="" style={{ padding: '5px' }} />
                        <span className='mt-2 mb-2 ms-2' style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>
                            {truncateDescription(row?.description, 40)}</span>
                    </div>
                </>,
        },
        {
            name: 'Price',
            sortable: false,
            maxWidth: '100px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.minimumPrice}</p>
                </>
        },
        {
            name: 'FAQ',
            sortable: false,
            maxWidth: '100px',
            cell: row => (
                <div className='d-flex'>
                    <button style={{ background: '#E4510B24', border: 'none', width: '40px', height: '40px' }} onClick={() => navigate(`/admin/productCategory/faq/${row?._id}`)}>
                        <Plus color='#E4510B' size={25} />
                    </button>
                </div>
            )
        },
        {
            name: 'Size Chart',
            sortable: false,
            maxWidth: '100px',
            cell: row => (
                <div className='d-flex'>
                    <button style={{ background: '#E4510B24', border: 'none', width: '40px', height: '40px' }} onClick={() => navigate(`/admin/productCategory/sizeChart/${row?._id}`)}>
                        <Plus color='#E4510B' size={25} />
                    </button>
                </div>
            )
        },

        {
            name: 'Status',
            maxWidth: '150px',
            cell: (row) => (
                // <>
                //     <div class="switch d-flex align-items-center">
                //         <Input
                //             type="switch"
                //             id="switch-success"
                //             name="success"
                // onChange={async (e) => {
                //     const result = window.confirm(
                //         `Are you sure you want to make as ${row.status === 'active' ? 'Inactive' : 'Active'}`
                //     );
                //     if (result) {
                //         await updateStatus(row._id);
                //     } else {

                //         e.target.checked = !e.target.checked;
                //     }
                // }}
                //             defaultChecked={row.status === 'active'}
                //         />
                //         <span
                //             className="ms-2"
                //             style={{
                //                 color: row.status === 'active' ? 'green' : 'red',
                //                 fontWeight: 'bold',
                //                 fontSize: '16px'
                //             }}
                //         >
                //             {row.status === 'active' ? 'Active' : 'Inactive'}
                //         </span>
                //     </div>

                // </>
                <div className="switch d-flex align-items-center">
                    <label className="switch-label">
                        <input
                            type="checkbox"
                            className="switch-input"
                            id="switch-success"
                            name="success"
                            onChange={async (e) => {
                                const result = window.confirm(
                                    `Are you sure you want to make as ${row.status === 'active' ? 'Inactive' : 'Active'}`
                                );
                                if (result) {
                                    await updateStatus(row._id);
                                } else {

                                    e.target.checked = !e.target.checked;
                                }
                            }}
                            defaultChecked={row.status === 'active'}
                        />

                        <span className="slider"></span>
                    </label>
                    <span
                        className="ms-2 d-flex"
                        style={{
                            color: row.status === 'active' ? 'green' : 'red',
                            fontWeight: '400',
                            fontSize: '14px',
                            flexDirection: 'row' // Ensure horizontal alignment
                        }}
                    >
                        {row.status === 'active' ? 'Active' : 'Inactive'}
                    </span>
                </div>
            )
        },
        {
            name: 'Customization',
            sortable: false,
            maxWidth: '150px',
            cell: row => (
                <div className='d-flex'>
                    <button style={{ background: '#E4510B24', border: 'none', width: '40px', height: '40px' }} onClick={() => navigate(`/admin/productCategory/customizationImages/${row?._id}`)}>
                        <Plus color='#E4510B' size={25} />
                    </button>
                </div>
            )
        },
        {
            name: "Action",
            minWidth: "100px",
            cell: (row) => (
                <>
                    <button className='ms-2' style={{ background: '#EEEEEE', border: 'none', width: '40px', height: '40px' }} onClick={() => (navigate(`/admin/editProductCategory/${row._id}`))}>
                        <BiEditAlt color='#4E4E4E' size={25} />
                    </button>
                    <button className='ms-2' style={{ background: '#D5383826', border: 'none', width: '40px', height: '40px' }} onClick={() => openDeleteModal(row?._id)}>
                        <GoTrash color='#E42525' size={25} />
                    </button>
                </>
            )
        }
    ]

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const CustomPagination = (pageCount) => {
        return (
            <>
                <ReactPaginate
                    nextLabel="Next"
                    breakLabel="..."
                    previousLabel="Prev"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePagination}
                    containerClassName="pagination justify-content-end p-1"
                    activeClassName="active"
                    pageClassName="page-item"
                    breakClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    forcePage={currentPage - 1}
                />
            </>
        );
    };

    const refetch = () => {
        setSearchKey('')
        setSorts('latest')
    }

    return (

        <>
            <Navbar />
            <div className='pt-5' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%', backgroundColor: '#F8F8F8' }}>
                <TitleComponent
                    title={`Product Category Management`}
                    isDesc={true}
                    desc={`Organize your products into categories for better navigation and visibility. Create, edit, or delete categories to ensure a structured and user-friendly shopping experience.`}
                />
                <div className='pt-1'>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Product Category</span></p>
                    </div>
                    <div style={{ width: '100%' }} className='d-flex align-items-center mt-4'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Product Category</h1>
                        {/* <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} /> */}
                    </div>
                    <div className='mt-3'>
                        <StatsCard data={statsData} md={6} xl={4} />
                    </div>
                </div>
                <div className='mt-4 order-table-con  shadow-sm'>
                    <div className='d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <div className='red-line me-2'></div>
                            <h1 className='p-0 m-0 h5'>Category List</h1>
                        </div>
                        <span onClick={refetch} className='cursor-pointer'><RotateCcw color='#E4510B' /></span>
                    </div>
                    <div className='line-hr-new'></div>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <div className='d-flex gap-3'>
                                <div className='d-flex flex-column'>
                                    <label style={{ color: '#333333' }} className='fw-medium'>Search</label>
                                    <input
                                        type='text'
                                        className='search-order'
                                        placeholder='Search by category name'
                                        value={searchKey}
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex flex-column'>
                                        <label style={{ color: '#333333' }} className='fw-medium'>Sort By</label>
                                        <select
                                            className='select-order'
                                            value={sorts}
                                            onChange={(e) => setSorts(e.target.value)}
                                        >
                                            <option value='latest'>Latest</option>
                                            <option value='oldest'>Oldest</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <button className='productCategoryBtn mt-3' onClick={() => (navigate('/admin/addProductCategory'))}><Plus size={20} /> Add Category</button>
                        </div>
                        {loading ? (
                            <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner
                                    color="primary"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <DataTable
                                pagination
                                paginationServer
                                noHeader
                                highlightOnHover
                                fixedHeader
                                fixedHeaderScrollHeight='130vh'
                                data={data?.rows}
                                columns={additionalTable}
                                paginationDefaultPage={currentPage}
                                customStyles={customStyles}
                                paginationComponent={() => CustomPagination(data?.pagination?.pages)}
                                noDataComponent={<h1 className='h5 text-center mt-5'><AlertCircle size={30} /> No Data Found!!!</h1>}
                            />
                        )}
                    </div>
                </div>

                {/* <ProductCategoryAdd open={addModal} handleAdd={handleAdd} additionalData={additionalData} statsFetch={getStatsData} /> */}
                {/* <ProductCategoryEdit open={editModal} handleEdit={closeEditModal} editData={editData} additionalData={additionalData} statsFetch={getStatsData} /> */}
                <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                    <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>Are you sure you want to delete this Category?</ModalBody>
                    <div style={{ justifyContent: 'center' }} className="modal-footer">
                        <Button style={{ backgroundColor: "#E4510B", border: 'none' }} onClick={handleConfirmDelete} >
                            Confirm
                        </Button>
                        <Button color="secondary" onClick={closeDeleteModal} >
                            Cancel
                        </Button>
                    </div>
                </Modal>


            </div >

            {/* <FooterSection /> */}
        </>
    )
}

export default ProductCategoryIndex
