import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../../navbar'
// import FooterSection from '../../footer'
import DataTable from 'react-data-table-component'
import { metaData } from '../../../ApiConfigs/ApiConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Plus, RotateCcw } from 'react-feather'
import { Button, Modal, ModalBody, Spinner } from 'reactstrap'
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import { BiEditAlt } from 'react-icons/bi'
import { GoTrash } from 'react-icons/go'
import TitleComponent from '../../homeNew/TitleComponent'
import MetaDetailsAdd from './addMetaDetails'
import MetaDetailsEdit from './EditMeatDetails'

export const truncateDescription = (description, maxLength) => {
    if (!description) return ''; // Return empty string if description is null/undefined
    return typeof description === 'string' && description.length > maxLength
        ? `${description.slice(0, maxLength)}...`
        : description;
};

export const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#FCEDE6',
            color: '#333333',
            fontWeight: '500',
            fontSize: '16px',
        },
    },
};

function MetaDetails() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [deleteData, setDeleteData] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editData, setEditData] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const handleAdd = () => setAddModal(!addModal)
    const [sorts, setSorts] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const rowsPerPage = 10;
    const token = localStorage.getItem('token');

    // Fetch data from the API
    const offerData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${metaData}?search=${searchKey}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}&perPage=${rowsPerPage}&sortBy=${sorts}`);
            if (response?.data?.result) {
                setData(response?.data?.result);
            } else {
                setData([]); // Set empty array if no data
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg || 'Error fetching data');
            setData([]); // Set empty array on error
        } finally {
            setLoading(false);
        }
    }, [searchKey, currentPage, rowsPerPage, sorts]);

    // Call API only when token is available
    useEffect(() => {
        if (token) {
            offerData();
        }
    }, [token, offerData]); // Token dependency added here

    // Handle reset for search and sorts
    const handleReset = () => {
        setSearchKey('');
        setSorts('');
        offerData();
    };

    // const [showFullDescription, setShowFullDescription] = useState(false);

    // Handle pagination
    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const CustomPagination = ({ pageCount }) => {
        if (!pageCount || pageCount <= 0) return null;

        return (
            <ReactPaginate
                nextLabel="Next"
                breakLabel="..."
                previousLabel="Prev"
                pageCount={Math.max(1, pageCount)} // Ensure pageCount is at least 1
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePagination}
                containerClassName="pagination justify-content-end p-1"
                activeClassName="active"
                pageClassName="page-item"
                breakClassName="page-item"
                pageLinkClassName="page-link"
                breakLinkClassName="page-link"
                nextLinkClassName="page-link"
                previousLinkClassName="page-link"
                forcePage={Math.max(0, currentPage - 1)} // Ensure forcePage is not negative
            />
        );
    };

    const openDeleteModal = (id) => {
        setDeleteData(id);
        setDeleteModal(true);
    };
    const closeDeleteModal = () => setDeleteModal(false);
    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${metaData}/${deleteData}`);
            closeDeleteModal();
            offerData();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg || 'Error deleting data');
        }
    };

    const openEditModal = (id) => {
        getEditModal(id);
        setEditModal(true);
    };
    const closeEditModal = () => setEditModal(false);

    const getEditModal = async (id) => {
        try {
            const response = await axios.get(`${metaData}/${id}`);
            setEditData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg || 'Error fetching edit data');
        }
    };

    const additionalTable = [
        {
            name: 'S No',
            minWidth: '50px',
            cell: (row, i) => (
                <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>
                    {(i + 1).toString().padStart(2, '0')}
                </p>
            )
        },
        {
            name: 'Path',
            sortable: false,
            minWidth: '80px',
            cell: row => (
                <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.path}</p>
            )
        },
        {
            name: 'Meta Title',
            sortable: false,
            minWidth: '200px',
            cell: row => (
                <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>
                    {/* {row?.title} */}
                    {`${row?.title.slice(0, 40)}...`}
                </p>
            )
        },
        {
            name: 'Meta Description',
            sortable: false,
            minWidth: '300px',
            cell: row => (
                <div className='d-flex'>
                    <span className='mt-2 mb-2' style={{ cursor: 'pointer' }}>
                        {truncateDescription(row?.description, 40)}
                    </span>
                </div>
            ),
        },
        {
            name: 'Meta Keyword',
            sortable: false,
            minWidth: '80px',
            cell: row => (
                <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>
                    {`${row.keywords.slice(0, 4)}...`}
                </p>
            )
        },
        {
            name: "Action",
            minWidth: "200px",
            cell: (row) => (
                <>
                    <button className='ms-2' style={{ background: '#EEEEEE', border: 'none', width: '40px', height: '40px' }} onClick={() => openEditModal(row?._id)}>
                        <BiEditAlt color='#4E4E4E' size={25} />
                    </button>
                    <button className='ms-2' style={{ background: '#D5383826', border: 'none', width: '40px', height: '40px' }} onClick={() => openDeleteModal(row?._id)}>
                        <GoTrash color='#E42525' size={25} />
                    </button>
                </>
            )
        }
    ];

    return (
        <div>
            <Navbar />
            <div className='pt-5 s' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', backgroundColor: '#F8F8F8' }}>
                <TitleComponent
                    title={`Meta Details Management`}
                    isDesc={true}
                    desc={`treamline your meta details management with advanced tools. Easily create, edit, and organize metadata to enhance SEO, improve content visibility, and ensure a seamless user experience across your digital platform.`}
                />
                <div>
                    <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Meta Details Management</span></p>
                </div>
                <div className='mt-4 order-table-con  shadow-sm'>
                    <div className='d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <div className='red-line me-2'></div>
                            <h1 className='p-0 m-0 h5'>Meta Details List</h1>
                        </div>
                        <span onClick={handleReset} className='cursor-pointer'><RotateCcw color='#E4510B' /></span>
                    </div>
                    <div className='line-hr-new'></div>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <div className='d-flex gap-3'>
                                <div className='d-flex flex-column'>
                                    <label style={{ color: '#333333' }} className='fw-medium'>Search</label>
                                    <input
                                        type='text'
                                        className='search-order'
                                        placeholder='Search by Meta Title'
                                        value={searchKey}
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex flex-column'>
                                        <label style={{ color: '#333333' }} className='fw-medium'>Sort By</label>
                                        <select
                                            className='select-order'
                                            value={sorts}
                                            onChange={(e) => setSorts(e.target.value)}
                                        >
                                            <option value='latest'>Latest</option>
                                            <option value='oldest'>Oldest</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <button className='productCategoryBtn mt-3' onClick={handleAdd}><Plus size={20} /> Add Meta</button>
                        </div>
                        {loading ? (
                            <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner
                                    color="primary"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <DataTable
                                pagination
                                paginationServer
                                noHeader
                                highlightOnHover
                                fixedHeader
                                fixedHeaderScrollHeight='130vh'
                                data={data?.rows}
                                columns={additionalTable}
                                paginationDefaultPage={currentPage}
                                customStyles={customStyles}
                                paginationComponent={() => CustomPagination({
                                    pageCount: data?.pagination?.pages || 0
                                })}
                            />
                        )}
                    </div>
                </div>
                <MetaDetailsAdd open={addModal} handleAdd={handleAdd} offerData={offerData} />

                {/* edit modal */}
                <MetaDetailsEdit open={editModal} handleEdit={closeEditModal} editData={editData} offerData={offerData} />
                {/* delete modal */}
                <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                    <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>Are you sure you want to delete this Offer Product?</ModalBody>
                    <div style={{ justifyContent: 'center' }} className="modal-footer">
                        <Button style={{ backgroundColor: "#E4510B", border: 'none' }} onClick={handleConfirmDelete} >
                            Confirm
                        </Button>
                        <Button color="secondary" onClick={closeDeleteModal} >
                            Cancel
                        </Button>
                    </div>
                </Modal>
            </div>
            {/* <FooterSection /> */}
        </div>
    )
}

export default MetaDetails
