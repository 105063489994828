import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Button, FormFeedback, Form, Input, Label, Row, Col, Spinner, Card, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { PlusCircle, Trash2, X } from 'react-feather';
import { district, fieldOption, getFieldTypeOptions, pincode1, position, productApi, productCategory, productCategoryApi, productDraft, state } from '../../../../ApiConfigs/ApiConfig';
import { multiImageUpload, uploadImage } from '../../upload/index';
import { useNavigate } from 'react-router-dom';
import './index.css';
import Navbar from '../../../navbar';
import { RiImageAddFill } from 'react-icons/ri';
import { TagsInput } from 'react-tag-input-component';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import he from 'he';


function AddProductIndex() {

    // const location = useLocation();
    const [getData, setGetData] = useState([])
    const [commonData, setCommonData] = useState()
    const imageRef = useRef(null);
    const imageRef1 = useRef(null);
    const imageRefs = useRef([]);
    const [image, setImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [feildOption, setFieldOption] = useState([])
    const [position1, setPosition1] = useState([])
    const [getOptionsFieldType, setOptionsFieldType] = useState([])
    const [getState, setState] = useState([]);
    const [getDistrict, setDistrict] = useState([]);
    const [editorValue, setEditorValue] = useState('');
    const [getPincode, setPincode] = useState([]);
    const [getEditDraftData, setGetEditDraftData] = useState()
    const [isKg, setIsKg] = useState('kg')
    const [isManualLocations, setIsManualLocations] = useState(false);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedPincodes, setSelectedPincodes] = useState([]);
    const inputRef1 = useRef(null);
    const [showCustomUpload, setShowCustomUpload] = useState(false);
    // const [selectedValue, setSelectedValue] = useState(null);
    // const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    // const [selectedCustomImageIndex, setSelectedCustomImageIndex] = useState(null);
    const [profileImages, setProfileImages] = useState([]);
    // const [customImages, setCustomImages] = useState([]);
    const [loader, setloader] = useState(false)
    const [loads, setloads] = useState(false)
    // const [loaders, setloaders] = useState(false)
    const [loading, setLoading] = useState(false)
    const toolbarOptions = [['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image', 'video', 'formula'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']]
    const module = {
        toolbar: toolbarOptions,
    }
    // const [load, setLoad] = useState(false)
    const [fieldTypeIds, setFieldTypeIds] = useState([]);
    const [typeIds, setTypeIds] = useState([])
    const navigate = useNavigate()
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [profileIconUrl, setProfileIconUrl] = useState('');
    const [colorProducts, setColorProducts] = useState([
        {
            colourCode: '',
            images: [{ name: '', image: '' }]
        }
    ]);
    // const [states, setStates] = useState([]);  // Holds the states fetched from the API
    // const [selectedStates, setSelectedStates] = useState([]);
    // const [metaKeywords, setMetaKeywords] = useState([]);
    const [customizeImageSize, setCustomizeImageSize] = useState([]);
    const [modal, setModal] = useState(false)
    const toggleModal = () => setModal(!modal)
    const [groupChange, setGroupChange] = useState('all')
    const [isComboTees, setIsComboTees] = useState(false)

    const formSchema = yup.object().shape({

        productCategory: yup.mixed().required('Please Enter Your productCategory '),
        name: yup.string().required('Please Enter Your productName').matches(/^[a-zA-Z0-9\s]+$/, 'Product Name should not contain special characters'),
        // fullDescription: yup.string().required('Please Enter Your fullDescription'),
        // gstType: yup
        //     .string()
        //     .required('Please select GSTIN Type'),
        // quantityType: yup.string().required('Please Select Your Quantity Type'),
        gstPercentage: yup
            .number()
            .required('GST Percentage is required')
            .oneOf([5, 12, 18], 'Please select a valid GST Percentage (5, 12, or 18)')
            .positive('Value cannot be negative'),
        minimunQuantity: yup.number().min(0, 'Value cannot be negative').required('Please Enter Minimum Quantity'),
        maximunQuantity: yup.number().min(0, 'Value cannot be negative').required('Please Enter Maximum Quantity'),
        amount: yup.number().min(0, 'Value cannot be negative').required('Please Enter the Base Amount'),
        productWeight: yup.number().min(0, 'Value cannot be negative').required('Please Enter the Product Weight'),
        noShipChargeAbove: yup.number().min(0, 'Value cannot be negative').required('Please Enter the No Shipping Charge Amount'),
        icon: yup.mixed().required("Please upload an icon image"),
        // length: yup.number().min(0, 'Value cannot be negative'),
        // width: yup.number().min(0, 'Value cannot be negative'),
        // height: yup.number().min(0, 'Value cannot be negative'),
        image: yup.mixed().required("Please upload an Product image"),
        upload: yup
            .boolean()
            .required('Please select an option'),
        customizeProduct: yup
            .boolean()
            .required('Please select an option'),
        customizeLocations: yup
            .boolean()
            .required('Please select an option'),
        // quantityBasedAmount: yup.array().of(
        //     yup.object({
        //         from: yup
        //             .number()
        //             .required("Quantity From is required")
        //             .min(0, 'Value cannot be negative'),
        //         to: yup
        //             .number()
        //             .required("Quantity To is required")
        //             .min(yup.ref('from'), "Quantity To must be greater than or equal to Quantity From"),
        //         amount: yup
        //             .number()
        //             .required("Amount is required")
        //             .positive("Amount must be a positive number"),
        //         type: yup
        //             .string()
        //             .required("Type is required")
        //             .oneOf(['type1', 'type2', 'type3'], 'Invalid Type'), // Update 'type1', 'type2', etc. to your valid types
        //     })
        // ),
        // fieldType: yup.array()
        //     .of(
        //         yup.object().shape({
        //             name: yup.object()
        //                 .nullable()
        //                 .required("Field type is required"), // Ensure the field type is selected
        //             options: yup.array()
        //                 .min(1, "At least one option must be selected") // At least one option required
        //                 .required("Options are required"), // Ensure options are provided
        //         })
        //     )
        //     .min(1, "At least one field type must be added") // Ensure at least one field type is present
        //     .required("Field Types are required"),
        // profileImages: yup.array()
        //     .of(
        //         yup.object().shape({
        //             file: yup.mixed()
        //                 .required("Please upload an image")
        //                 .test("fileType", "Only JPEG, PNG, JPG, and JFIF formats are allowed", (value) => {
        //                     if (!value) return false; // File is required
        //                     const supportedFormats = ["image/jpeg", "image/png", "image/jpg", "image/jfif"];
        //                     return supportedFormats.includes(value.type);
        //                 })
        //                 .test("fileSize", "File size must be less than 2MB", (value) => {
        //                     if (!value) return false; // File is required
        //                     const fileSizeInMB = value.size / 1024 / 1024; // Convert bytes to MB
        //                     return fileSizeInMB < 2;
        //                 }),
        //         })
        //     )
        //     .min(1, "Please upload at least one image")
        //     .required("Product Gallery Images are required"),
        productOverview: yup.array()
            .of(
                yup.object().shape({
                    description: yup.string()
                        .required("Description is required")
                        .min(10, "Description must be at least 10 characters")
                        .max(200, "Description must be less than 200 characters"),
                })
            )
            .min(1, "At least one product overview is required"),
        metaTitle: yup
            .string()
            .required('Please Enter Your Meta Title'),
        // .matches(/^[a-zA-Z0-9\s|\\-]+$/, 'Meta Name should only contain letters, numbers, spaces, and the special characters "|", "-", and "\\"'),
        metaDescription: yup.string().required('Please Enter Your Meta Description'),
        metaKeywords: yup.string().required('Please Enter Your Meta Keywords'),
        // // shortDescription: yup.string().required('Please Enter Your shortDescription'),
        // // amount: yup.mixed().required('Please Enter Your amount'),
        // // quantityType: yup.mixed().required('Please Enter Your quantityType'),
    });

    const formSchema1 = yup.object().shape({
        productCategory: yup.mixed().required('Please Enter Your productCategory '),
        name: yup.string().required('Please Enter Your productName').matches(/^[a-zA-Z0-9\s]+$/, 'Product Name should not contain special characters'),
        gstPercentage: yup
            .number()
            .required('GST Percentage is required')
            .oneOf([5, 12, 18], 'Please select a valid GST Percentage (5, 12, or 18)')
            .positive('Value cannot be negative'),
        amount: yup.number().min(0, 'Value cannot be negative').required('Please Enter the Base Amount'),
        productWeight: yup.number().min(0, 'Value cannot be negative').required('Please Enter the Product Weight'),
        noShipChargeAbove: yup.number().min(0, 'Value cannot be negative').required('Please Enter the No Shipping Charge Amount'),
        productOverview: yup.array()
            .of(
                yup.object().shape({
                    description: yup.string()
                        .required("Description is required")
                        .min(10, "Description must be at least 10 characters")
                        .max(200, "Description must be less than 200 characters"),
                })
            )
            .min(1, "At least one product overview is required"),
        metaTitle: yup
            .string()
            .required('Please Enter Your Meta Title'),
        // .matches(/^[a-zA-Z0-9\s|\\-]+$/, 'Meta Name should only contain letters, numbers, spaces, and the special characters "|", "-", and "\\"'),
        metaDescription: yup.string().required('Please Enter Your Meta Description'),
        metaKeywords: yup.string().required('Please Enter Your Meta Keywords'),
    });

    const {
        // reset,
        control,
        watch,
        reset,
        resetField,
        setValue,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema1),
        defaultValues: {
            fieldType: [{ name: null, options: [] }], // Open one field by default
        },
    });


    const editDraftData = async () => {
        try {
            const response = await axios.get(`${productDraft}`)
            setGetEditDraftData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        editDraftData()
    }, [])

    const getProduct = useCallback(async () => {
        try {

            const response = await axios.get(productCategory)
            setGetData(response?.data?.result)
        } catch (error) {
            toast.error(error.response.data.msg)
        }
    }, [])

    useEffect(() => {
        const getConstData = async () => {
            try {
                const response = await axios.get(`${productCategoryApi}/${watch('productCategory')?.value}`)
                setCommonData(response?.data?.result)
            } catch (error) {
            }
        }
        getConstData()
    }, [watch('productCategory')?.value])

    useEffect(() => {
        if (commonData?.productDetails) {
            const formattedFieldTypes = commonData?.fieldType?.map((field) => {
                // Ensure each field has a valid name and options; otherwise, provide defaults
                const fieldName = field.name
                    ? { label: field.name.name, value: field.name._id }
                    : {}; // Default name

                const fieldOptions = field.options?.map((option) => ({
                    label: option.name,
                    value: option._id,
                })) || []; // Default options as an empty array

                return { name: fieldName, options: fieldOptions };
            }) || [];
            const formattedGstType = {
                label: commonData?.gstType === "inclusive" ? "Inclusive" : "Exclusive",
                value: commonData?.gstType === "inclusive" ? "inclusive" : "exclusive"
            };
            const formattedQuantityBasedAmount = commonData?.quantityBasedAmount?.map(amountData => ({
                from: amountData.from || '', // Ensure 'from' is populated or empty
                to: amountData.to || '', // Ensure 'to' is populated or empty
                amount: amountData.amount || '', // Ensure 'amount' is populated or empty
                type: { label: amountData.type, value: amountData.type }, // Map type as a Select option
            })) || [];
            console.log(formattedFieldTypes);
            const formattedProductOverview = commonData?.productOverview?.map(item => ({ description: item.description })) || []
            const decodedFullDescription = he.decode(commonData?.fullDescription || '');

            setValue('gstPercentage', commonData?.gstPercentage)
            setValue('gstType', formattedGstType)
            setValue('quantityBasedAmount', formattedQuantityBasedAmount)
            setValue('fieldType', formattedFieldTypes)
            setValue('productOverview', formattedProductOverview)
            setEditorValue(decodedFullDescription);
            setValue('amount', commonData?.amount)
            setValue('quantityType', { label: commonData?.quantityType, value: commonData?.quantityType })
            setValue('minimunQuantity', commonData?.minimunQuantity)
            setValue('maximunQuantity', commonData?.maximunQuantity)
            setValue('productWeight', commonData?.productWeight)
            setIsKg(commonData?.weightType)
            setValue('noShipChargeAbove', commonData?.noShipChargeAbove)
            setValue('length', commonData?.length)
            setValue('height', commonData?.height)
            setValue('width', commonData?.width)
            setCustomizeImageSize(commonData?.customizeImageSize);
        }
    }, [commonData])


    // useEffect(() => {
    //     if (getEditDraftData) {
    //         const formattedFieldTypes = getEditDraftData?.fieldType?.map((field) => {
    //             // Ensure each field has a valid name and options; otherwise, provide defaults
    //             const fieldName = field.name
    //                 ? { label: field.name.name, value: field.name._id }
    //                 : {}; // Default name

    //             const fieldOptions = field.options?.map((option) => ({
    //                 label: option.name,
    //                 value: option._id,
    //             })) || []; // Default options as an empty array

    //             return { name: fieldName, options: fieldOptions };
    //         }) || [];

    //         const formattedQuantityBasedAmount = getEditDraftData?.quantityBasedAmount?.map(amountData => ({
    //             from: amountData.from || '', // Ensure 'from' is populated or empty
    //             to: amountData.to || '', // Ensure 'to' is populated or empty
    //             amount: amountData.amount || '', // Ensure 'amount' is populated or empty
    //             type: { label: amountData.type, value: amountData.type }, // Map type as a Select option
    //         })) || [];

    //         const formattedGstType = {
    //             label: getEditDraftData?.gstType === "inclusive" ? "Inclusive" : "Exclusive",
    //             value: getEditDraftData?.gstType === "inclusive" ? "inclusive" : "exclusive"
    //         };

    //         const decodedFullDescription = he.decode(getEditDraftData?.fullDescription || '');
    //         const isCustomizeProductTrue = getEditDraftData?.customizeProduct?.toString() === 'true';
    //         setShowCustomUpload(isCustomizeProductTrue);
    //         reset({
    //             name: getEditDraftData?.name,
    //             customizeLocations: getEditDraftData?.customizeLocations || false,
    //             fieldType: formattedFieldTypes,
    //             quantityBasedAmount: formattedQuantityBasedAmount,
    //             amount: getEditDraftData?.amount,
    //             quantityType: { label: getEditDraftData?.quantityType, value: getEditDraftData?.quantityType },
    //             minimunQuantity: getEditDraftData?.minimunQuantity,
    //             maximunQuantity: getEditDraftData?.maximunQuantity,
    //             upload: getEditDraftData?.upload || false, // Ensure boolean is converted to string
    //             customizeProduct: getEditDraftData?.customizeProduct || false,
    //             productWeight: getEditDraftData?.productWeight,
    //             noShipChargeAbove: getEditDraftData?.noShipChargeAbove,
    //             productCategory: { label: getEditDraftData?.productCategory?.name, value: getEditDraftData?.productCategory?._id },
    //             // fullDescription: getEditDraftData?.fullDescription,
    //             shortDescription: getEditDraftData?.shortDescription,
    //             gstType: formattedGstType,
    //             length: getEditDraftData?.length,
    //             width: getEditDraftData?.width,
    //             height: getEditDraftData?.height,
    //             gstPercentage: getEditDraftData?.gstPercentage,
    //             productOverview: getEditDraftData?.productOverview?.map(item => ({ description: item.description })) || [],
    //             description: getEditDraftData?.description?.map(item => ({ description: item.description })) || [],
    //             metaTitle: getEditDraftData?.metaTitle,
    //             metaKeywords: getEditDraftData?.metaKeywords,
    //             metaDescription: getEditDraftData?.metaDescription,
    //         });

    //         setProfileImages(getEditDraftData?.galleryImages || []);
    //         setEditorValue(decodedFullDescription);
    //         setIsKg(getEditDraftData?.weightType || []);
    //         setIsManualLocations(getEditDraftData?.customizeLocations || null);
    //         setProfileImageUrl(getEditDraftData?.image);
    //         setProfileIconUrl(getEditDraftData?.icon);
    //         setImage(getEditDraftData?.image);
    //         setIcon(getEditDraftData?.icon);
    //         setCustomizeImageSize(getEditDraftData?.customizeImageSize);


    //         const formattedColorProducts = getEditDraftData?.customizeImages?.map(item => ({
    //             colourCode: item.colourCode || '',
    //             images: item.images?.map(image => ({
    //                 name: image.name?._id || '',
    //                 image: image.image || ''
    //             })) || []
    //         })) || [];

    //         setColorProducts(formattedColorProducts);
    //     }
    // }, [getEditDraftData, reset, setValue, setProfileImages, setIsKg, setProfileImageUrl, setProfileIconUrl, setImage, setIcon, setCustomizeImageSize]);

    const { fields: fieldTypesFields, append: appendFieldType, remove: removeFieldType } = useFieldArray({
        control,
        name: "fieldType",
    });

    const { fields: deliveryLocationFields, append: appendDeliveryLocation, remove: removeDeliveryLocation } = useFieldArray({
        control,
        name: "deliveryLocation",
        defaultValue: [{ state: [], district: [], pincode: [] }],
    });
    const { fields: quantityBasedAmountFields, append: appendQuantityBasedAmount, remove: removeQuantityBasedAmount } = useFieldArray({
        control,
        name: "quantityBasedAmount",
        defaultValue: [{ from: '', to: '', amount: '', type: '' }],
    });

    console.log(fieldTypesFields);


    // useEffect(() => {
    //     if (deliveryLocationFields.length === 0) {
    //         appendDeliveryLocation({ state: [], district: [], pincode: [] });
    //     }
    //     if (fieldTypesFields.length === 0) {
    //         appendFieldType({ name: '', options: [] });
    //     }
    //     if (quantityBasedAmountFields.length === 0) {
    //         appendQuantityBasedAmount({ from: '', to: '', amount: '', type: '' });
    //     }
    // }, [deliveryLocationFields, appendDeliveryLocation, fieldTypesFields, appendFieldType, quantityBasedAmountFields, appendQuantityBasedAmount]);




    const submitForm = async (data) => {
        const productCategory = data?.productCategory?.value;
        delete data.img_url;
        delete data.image;
        // const { deliveryLocation, fieldTypes } = data;

        // Ensure deliveryLocation exists before mapping
        const formattedLocations = data.deliveryLocation?.map(location => ({
            state: location.state ? location.state.label : '', // Access label directly
            districts: Array.isArray(location.district) ? location.district.map(d => d.label) : [], // Ensure district is an array
            exceptPincodes: Array.isArray(location.pincode) ? location.pincode.map(p => p.label) : [] // Ensure pincode is an array
        })) || [];

        const quantityType = data?.quantityType?.value;
        const quantityBasedAmount = {
            quantityBasedAmount: data?.quantityBasedAmount?.map(quantityAmount => ({
                from: parseFloat(quantityAmount?.from),
                to: parseFloat(quantityAmount?.to),
                amount: parseFloat(quantityAmount?.amount),
                type: quantityAmount?.type?.value
            }))
        }
        const fieldType = {
            fieldType: watch('fieldType')?.map(type => ({
                name: type?.name?.value, // Access the value of the name
                options: type?.options?.map(option => option?.value) // Directly return the value of the option
            }))
        };

        const customImage = {
            customizeImages: colorProducts.map(colorProduct => ({
                colourCode: colorProduct.colourCode,
                images: colorProduct.images.map(imageData => ({
                    name: imageData?.name,
                    image: imageData?.image
                }))
            }))
        };
        const payload = {
            ...data,
            comboTees: isComboTees,
            fullDescription: editorValue,
            amount: parseFloat(data.amount),
            maximunQuantity: parseFloat(data.maximunQuantity),
            minimunQuantity: parseFloat(data.minimunQuantity),
            productWeight: parseFloat(data.productWeight),
            noShipChargeAbove: parseFloat(data.noShipChargeAbove),
            locations: data?.customizeLocations ? formattedLocations : [], // Add formattedLocations here
            fieldType: fieldType?.fieldType || [],
            gstPercentage: parseFloat(data?.gstPercentage),
            quantityBasedAmount: quantityBasedAmount?.quantityBasedAmount,
            metaKeywords: data?.metaKeywords,
            icon: profileIconUrl,
            customizeImageSize: customizeImageSize,
            customizeImages: data?.customizeProduct ? customImage.customizeImages : [],
            gstType: data?.gstType?.value,
            weightType: isKg,
            updateType: groupChange,
            productCategory,
            galleryImages: profileImages?.map((el) => el),
            image: profileImageUrl,
            quantityType,
        };
        const payload1 = {
            ...data,
            comboTees: isComboTees,
            fullDescription: editorValue,
            amount: parseFloat(data.amount),
            productWeight: parseFloat(data.productWeight),
            noShipChargeAbove: parseFloat(data.noShipChargeAbove),
            locations: data?.customizeLocations ? formattedLocations : [], // Add formattedLocations here
            fieldType: fieldType?.fieldType || [],
            gstPercentage: parseFloat(data?.gstPercentage),
            metaKeywords: data?.metaKeywords,
            icon: profileIconUrl,
            gstType: data?.gstType?.value,
            weightType: isKg,
            updateType: groupChange,
            productCategory,
            galleryImages: profileImages?.map((el) => el),
            image: profileImageUrl,
            quantityType,
            upload: false,
            customizeProduct: false
        };
        delete payload.deliveryLocation;
        delete payload.imageName;
        delete payload.fieldTypes;

        if (isComboTees) {
            delete payload1.quantityBasedAmount;
            delete payload1.minimunQuantity;
            delete payload1.maximunQuantity;

            delete payload1.deliveryLocation;
            delete payload1.imageName;
            delete payload1.fieldTypes;
        }

        const finalPayload = isComboTees ? payload1 : payload
        try {
            const response = await axios.post(productApi, finalPayload);
            toast.success(response?.data?.msg);
            navigate('/admin/product');
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    };

    const triggerSubmit = () => {
        handleSubmit(submitForm)();
    };

    console.log(profileImages);

    const handleProfileUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setloader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloader(false)
            }
        }
    };

    const handleIconUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setIcon(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setloads(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileIconUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloads(false)
            }
        }
    };

    const handleCustomImageUpload = async (e, colorIndex, imageIndex) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const localImageUrl = URL.createObjectURL(file); // Create a local URL for the image

            try {
                // setloaders(true);
                const formData = new FormData();
                formData.append('file', file);

                // Perform the image upload to your server
                const uploadData = await uploadImage(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImageUrl = uploadData.result[0].location;

                    // Set both the local image URL and the uploaded image URL
                    const updatedColorProducts = [...colorProducts];
                    updatedColorProducts[colorIndex].images[imageIndex] = {
                        name: updatedColorProducts[colorIndex].images[imageIndex]?.name || '',
                        image: uploadedImageUrl,
                        localImageUrl, // Use localImageUrl to preview before upload completes
                    };

                    setColorProducts(updatedColorProducts);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                // setloaders(false);
            }
        }
    };




    const handleImageUpload = async (e) => {
        if (e.target.files) {
            try {
                setLoading(true);

                // Initialize FormData
                const formData = new FormData();

                // Append each selected file to FormData
                Array.from(e.target.files).forEach((file) => {
                    formData.append('files', file);
                });

                // Call the upload function
                const uploadData = await multiImageUpload(formData);

                if (uploadData?.result?.length) {
                    // Extract only the image URLs
                    const uploadedUrls = uploadData.result.map((file) => file.location);

                    // Add the new URLs to the existing profileImages state
                    setProfileImages((prevImages) => [...prevImages, ...uploadedUrls]);
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoading(false);
            }
        }
    };


    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        // setSelectedImageIndex(null);
    };





    const options1 = [
        {
            label: "Inclusive",
            value: "inclusive"
        },
        {
            label: "Exclusive",
            value: "exclusive"
        }
    ];



    useEffect(() => {
        async function fetchStates() {
            try {
                const response = await axios.get(state);
                response.data.result.map((state) => ({
                    label: state.state,
                    value: state.state,
                }));
                // setStates(stateOptions);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        }
        fetchStates();
    }, []);

    useEffect(() => {
        async function fetchCustomSide() {
            try {
                const response = await axios.get(position);
                setPosition1(response?.data?.result)
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        }
        fetchCustomSide();
    }, []);

    const options = getData?.map((item) =>
    (
        {
            label: item?.name,
            value: item?._id
        }))

    const customSide = position1?.map((item) =>
    (
        {
            label: item?.name,
            value: item?._id
        }))

    useEffect(() => {
        if (fieldTypeIds.length) {
            let value = fieldTypeIds.map(id => id.id);
            setTypeIds(value)
        }
    }, [fieldTypeIds])
    const getFieldType = useCallback(async (id, ind) => {
        try {
            let payload = [];
            if (id) {
                payload = typeIds.filter((val) => val.toString() !== id.toString());
            } else {
                payload = typeIds;
            }
            const response = await axios.post(`${getFieldTypeOptions}`, { fieldType: payload });
            const optionsFieldType = response?.data?.result?.map((item) => ({
                label: item?.name,
                value: item?._id,
            }));
            setOptionsFieldType(optionsFieldType);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    }, [typeIds]);

    const getStateOption = useCallback(async (searchValue = "", index) => {
        try {
            const response = await axios.get(`${state}?search=${searchValue}`);
            const optionsState = response?.data?.result?.map((item) => ({
                label: item?.state,
                value: item?._id,
            }));

            const selectedFlat = selectedState.flat(); // Flatten selected states from all fields
            const availableStates = optionsState.filter((state) => !selectedFlat.includes(state.label)); // Filter out already selected states

            setState(availableStates); // Set the filtered state options
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    }, [selectedState]);

    // Handle state change to store selected states for each index
    const handleStateChange1 = (selectedOption = null, index) => {
        const updatedStates = [...selectedState]; // Ensure selectedState is always an array
        updatedStates[index] = selectedOption ? [selectedOption] : []; // Store the selected option as an array or empty array
        setSelectedState(updatedStates); // Update the selected states in state

        // Clear district and pincode selections when a new state is selected
        const updatedDistricts = [...selectedDistricts];
        const updatedPincodes = [...selectedPincodes];
        updatedDistricts[index] = []; // Clear districts for this index
        updatedPincodes[index] = []; // Clear pincodes for this index
        setSelectedDistricts(updatedDistricts);
        setSelectedPincodes(updatedPincodes);

        // Extract the value of the selected option
        const selectedStateValues = selectedOption ? [selectedOption.label] : []; // Ensure it's an array
        if (selectedStateValues.length > 0) {
            // Call the district fetch for the selected state's value
            getDistrictOption(selectedStateValues, index); // Load districts based on selected state's value
        }
    };

    // Fetch district options based on selected state
    const getDistrictOption = async (stateValues, searchValue = '') => {
        try {
            const response = await axios.get(`${district}?state=${stateValues.join(",")}`);
            const options = response?.data?.result?.rows?.map((item) => ({
                label: item?.district_name_english,
                value: item?._id,
            }));

            const selectedFlat = selectedDistricts.flat(); // Flatten selected districts from all fields
            const availableDistricts = options.filter((district) => !selectedFlat.includes(district.label)); // Filter out already selected districts

            setDistrict(availableDistricts); // Set the filtered district options
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };


    // Handle district change to store selected districts for each index
    const handleDistrictChange = (selectedOption, index) => {
        const updatedDistricts = [...selectedDistricts];
        updatedDistricts[index] = selectedOption ? selectedOption.map(s => s.label) : [];
        setSelectedDistricts(updatedDistricts);

        // Clear pincode selection when districts change
        const updatedPincodes = [...selectedPincodes];
        updatedPincodes[index] = []; // Clear pincodes for this index
        setSelectedPincodes(updatedPincodes);

        // Fetch pincodes for the newly selected districts
        getPincodeOption(updatedDistricts, index); // Update the selected districts in state
    };

    // Fetch pincode options based on selected districts
    // const getPincodeOption = async (updatedDistricts, index) => {
    //     if (!updatedDistricts?.[index]) return; // Ensure there's a selected district
    //     try {
    //         const response = await axios.get(`${pincode1}?districts=${updatedDistricts[index].join(",")}`); // Adjust the API URL
    //         const options = response?.data?.result?.map((item) => ({
    //             label: item?.pincode,
    //             value: item?._id,
    //         }));

    //         const selectedFlat = selectedPincodes.flat();
    //         const availablePincodes = options.filter((pincode) => !selectedFlat.includes(pincode.label)); // Filter out already selected pincodes

    //         setPincode(availablePincodes); // Set the filtered pincode options
    //     } catch (error) {
    //         console.error('Error fetching pincodes:', error);
    //     }
    // };
    const getPincodeOption = async (updatedDistricts, searchValue = '', index) => {
        if (!updatedDistricts?.[index]) return; // Ensure there's a selected district
        try {
            const districtValues = updatedDistricts[index].join(",");
            const response = await axios.get(`${pincode1}?districts=${districtValues}&search=${searchValue}`); // Include search value
            const options = response?.data?.result?.map((item) => ({
                label: item?.pincode,
                value: item?._id,
            }));

            const selectedFlat = selectedPincodes.flat();
            const availablePincodes = options.filter((pincode) => !selectedFlat.includes(pincode.label)); // Filter out already selected pincodes

            setPincode(availablePincodes); // Set the filtered pincode options
        } catch (error) {
            console.error("Error fetching pincodes:", error);
        }
    };

    // Handle pincode change to store selected pincodes for each index
    const handlePincodeChange = (selectedOption, index) => {
        const updatedPincodes = [...selectedPincodes];
        updatedPincodes[index] = selectedOption ? selectedOption.map(s => s.value) : [];
        setSelectedPincodes(updatedPincodes); // Update the selected pincodes in state
    };

    const getDatas = async (id, index) => {

        if (!fieldTypeIds.length) {
            setFieldTypeIds([{ index: index, id }]);
        } else if (fieldTypeIds[index]?.index === index) {
            setFieldTypeIds(prevState => {
                const updatedState = [...prevState];
                updatedState[index] = { index: index, id };
                return updatedState;
            });
        } else {
            fieldTypeIds.push({ index, id })
            // /(prevState => [...prevState, index, id]);
        }
    };

    useEffect(() => {
        getFieldType();
        getStateOption();
    }, [getFieldType, getStateOption]);

    useEffect(() => {
        getStateOption();
    }, [getStateOption]);


    const getFieldOption = async (id) => {
        try {
            const response = await axios.get(`${fieldOption}?fieldType=${id}`)
            setFieldOption(response?.data?.result)
        } catch (error) {

        }
    }
    const fieldoption = feildOption?.map((item) => (
        {
            label: item?.name,
            value: item?._id
        }
    ))



    const quantityBasedAmountType = [
        {
            name: "Recommended",
            value: "recemended"
        },
        {
            name: "General",
            value: "general"
        },
        {
            name: "BestPrice",
            value: "bestprice"
        },
        {
            name: "BulckPrice",
            value: "bulckprice"
        },

    ]

    const quantityBasedType = quantityBasedAmountType?.map((item) => (
        {
            label: item?.name,
            value: item?.value
        }
    ))

    const quantityTypeData = [
        {
            name: "dropdown",
            value: "dropdown"
        },
        {
            name: "manual",
            value: "manual"
        }
    ]

    const quantityTypeDrop = quantityTypeData?.map((item) => (
        {
            label: item?.name,
            value: item?.name
        }
    ))

    useEffect(() => {
        const customizeLocations = watch('customizeLocations'); // Extract the value

        if (customizeLocations === 'false') {
            setSelectedState([]);
            setSelectedDistricts([]);
            setSelectedPincodes([]);
        }
    }, [watch]); // Only include `watch` as the dependency
    // Use the extracted variable in the dependency array

    useEffect(() => {
        getProduct()
    }, [getProduct])

    const handleTypeChange = (selectedOption, index) => {
        setValue(`fieldType[${index}].options`, []);
        getFieldOption(selectedOption?.value);
        getDatas(selectedOption?.value, index);
    };

    // const handleRadioChange = (value) => {
    //     setSelectedValue(value);
    // };



    // const getStateName = (stateId) => {
    //     const state = getState.find(s => s.value === stateId);
    //     return state ? state.label : '';
    // };

    // const getDistrictName = (districtId) => {
    //     const district = getDistrict.find(d => d.value === districtId);
    //     return district ? district.label : '';
    // };

    // const getPincodeName = (pincodeId) => {
    //     const pincode = getPincode.find(p => p.value === pincodeId);
    //     return pincode ? pincode.label : '';
    // };


    // const handleMetaKeywordsChange = (keywords) => setMetaKeywords(keywords);
    const handleSizeChange = (ImageSize) => setCustomizeImageSize(ImageSize);

    const autoSubmit = useCallback(async () => {
        const customImage = {
            customizeImages: colorProducts.map(colorProduct => ({
                colourCode: colorProduct.colourCode,
                images: colorProduct.images.map(imageData => ({
                    name: imageData?.name || null,
                    image: imageData?.image
                }))
            }))
        };
        const formattedDeliveryLocation = watch("deliveryLocation")?.map((location) => ({
            state: location.state ? location.state.value : '', // Access label directly
            districts: Array.isArray(location.district) ? location.district.map(d => d.value) : [], // Ensure district is an array
            exceptPincodes: Array.isArray(location.pincode) ? location.pincode.map(p => p.value) : [] // Ensure pincode is an array
        }));

        const fieldType = {
            fieldType: watch('fieldType')?.map(type => ({
                name: type?.name?.value, // Access the value of the name
                options: type?.options?.map(option => option?.value) // Directly return the value of the option
            }))
        };

        const quantityBasedAmount = {
            quantityBasedAmount: watch("quantityBasedAmount")?.map(quantityAmount => ({
                from: parseFloat(quantityAmount?.from),
                to: parseFloat(quantityAmount?.to),
                amount: parseFloat(quantityAmount?.amount),
                type: quantityAmount?.type?.value
            }))
        }

        const gstType = watch('gstType')?.value || "";

        const payload = {
            name: watch('name') || "",
            amount: parseFloat(watch('amount')) || null,
            customizeLocations: watch('customizeLocations') || null,
            customizeProduct: watch('customizeProduct') || null,
            locations: formattedDeliveryLocation || [],
            description: watch('description') || [],
            fieldType: fieldType?.fieldType || [],
            fullDescription: editorValue || "",
            gstPercentage: parseFloat(watch('gstPercentage')) || null,
            gstType: gstType,
            height: watch('height') || "",
            width: watch('width') || "",
            length: watch('length') || "",
            icon: profileIconUrl || "",
            image: profileImageUrl || "",
            galleryImages: profileImages || [],
            maximunQuantity: parseFloat(watch('maximunQuantity')) || null,
            minimunQuantity: parseFloat(watch('minimunQuantity')) || null,
            metaTitle: watch('metaTitle') || "",
            metaDescription: watch('metaDescription') || "",
            metaKeywords: watch('metaKeywords') || "",
            noShipChargeAbove: parseFloat(watch('noShipChargeAbove')) || null,
            productCategory: watch('productCategory')?.value || null,
            productOverview: watch('productOverview') || [],
            productWeight: parseFloat(watch('productWeight')) || null,
            quantityBasedAmount: quantityBasedAmount?.quantityBasedAmount || [],
            quantityType: watch('quantityType')?.value || "",
            upload: watch('upload') || null,
            customizeImageSize: customizeImageSize || [],
            customizeImages: customImage?.customizeImages || [],
            weightType: isKg || ""
        }
        try {
            await axios.post(productDraft, payload);
        } catch (error) {

        }

    }, [colorProducts, profileIconUrl, profileImageUrl, profileImages, isKg, customizeImageSize, editorValue, watch]);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         autoSubmit(); // Call autoSubmit every 10 seconds
    //     }, 10000);

    //     return () => clearInterval(interval); // Cleanup interval on component unmount or dependencies change
    // }, [autoSubmit]);


    const customStyles = (isMulti) => ({
        control: (provided, state) => ({
            ...provided,
            height: 'auto', // Set the height to auto
            // minHeight: isMulti ? '38px' : '40px', // Set a minimum height
            padding: '5px', // Add some padding
            overflow: 'hidden', // Prevent overflow
        }),
        multiValue: (provided) => ({
            ...provided,
            maxWidth: '100%', // Allow multi values to take full width
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            overflow: 'hidden',
            whiteSpace: 'nowrap', // Prevent text wrapping
            textOverflow: 'ellipsis', // Add ellipsis for overflow text
        }),
    });

    const getUsedOptions = (colorIndex) => {
        const used = colorProducts[colorIndex].images.map((img) => img.name).filter(Boolean); // Get all selected names for the current color
        return used;
    };

    const filterOptions = (colorIndex, imageIndex) => {
        const usedOptions = getUsedOptions(colorIndex);
        return customSide.filter((option) => !usedOptions.includes(option.value)); // Exclude already selected options
    };


    return (
        <>
            <Navbar />

            <div className='pt-5 pb-5' style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                <div className='d-flex fw-medium' style={{ color: '#E4510B' }} ><p style={{ cursor: 'pointer', color: '#333333' }} onClick={() => navigate('/admin/product')}>Product  {`>`}</p> Add Product</div>
                <h1 className='mb-5'>Add Product</h1>

                <Form>
                    <Card className='p-4'>
                        <h1 className='fs-3 mb-4'>Product Details</h1>
                        <Row >
                            <Col sm={12} md={6} lg={6}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Choose Category <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='productCategory'
                                        control={control}
                                        defaultValue={
                                            getEditDraftData?.productCategory
                                                ? { label: getEditDraftData.productCategory.name, value: getEditDraftData.productCategory._id }
                                                : undefined
                                        }
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={true}
                                                {...field}
                                                options={options}
                                                menuPortalTarget={document.body} // Render dropdown outside its container
                                                styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure dropdown appears above other elements
                                                    control: (base, state) => ({
                                                        ...base,
                                                        borderColor: errors.productCategory ? 'red' : base.borderColor,
                                                    }),
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.productCategory && (
                                        <FormFeedback style={{ display: 'block' }}>
                                            {errors.productCategory.message}
                                        </FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>New Product Name <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='name'
                                        id='name'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                style={{ height: '44px' }}
                                                type="text"
                                                placeholder="Enter new product name"
                                                invalid={errors.name && true}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length === 1) {
                                                        // Only letters and spaces for the first character
                                                        e.target.value = inputValue.replace(/[^A-Za-z ]/g, '');
                                                    } else {
                                                        // Alphanumeric and spaces for subsequent characters
                                                        e.target.value = inputValue.replace(/[^A-Za-z0-9 ]/g, '');
                                                    }
                                                    field.onChange(e); // Update Controller's value
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                                </div>
                            </Col>
                            {/* <Col sm={12} md={12} lg={12}>
                                <div className='mb-3 s'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Full Description</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='fullDescription'
                                        id='fullDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input type="textarea" rows={5} cols={60} {...field} invalid={errors.fullDescription && true} placeholder="Enter Full Description" />)}
                                    />
                                    {errors.fullDescription && <FormFeedback>{errors.fullDescription.message}</FormFeedback>}
                                </div>
                            </Col> */}
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Full Description <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='fullDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <div>
                                                {/* <RichTextEditor
                                                    value={editorValue}
                                                    onChange={(value) => {
                                                        setEditorValue(value);
                                                        // Update the field with the HTML string
                                                        field.onChange(value.toString('html'));
                                                    }}
                                                    placeholder="Enter Full Description"
                                                    editorClassName="custom-richtext-editor" // Add custom class for styling if needed
                                                    style={{ minHeight: '200px' }}
                                                /> */}
                                                <ReactQuill modules={module} theme='snow' value={editorValue} onChange={setEditorValue} />
                                                {errors.fullDescription && (
                                                    <FormFeedback style={{ display: 'block' }}>
                                                        {errors.fullDescription.message}
                                                    </FormFeedback>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3'>
                                    <Controller
                                        name='description'
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <div>
                                                <Row>
                                                    {field.value.map((item, index) => (
                                                        <Col key={index} sm={12} md={6} lg={4}>
                                                            <div className='mb-3 d-flex flex-column'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div>Description {index + 1}</div>
                                                                        <div className='justify-content-end ms-2 mt-1'
                                                                            color='danger'
                                                                            size='sm'
                                                                            style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                                                            onClick={() => {
                                                                                const updatedValues = [...field.value];
                                                                                updatedValues.splice(index, 1);
                                                                                field.onChange(updatedValues);
                                                                            }}
                                                                        >
                                                                            <Trash2 />
                                                                        </div>
                                                                    </div>
                                                                </Label>
                                                                <Input
                                                                    type="textarea" rows={5} cols={60}
                                                                    name={`description[${index}].description`}
                                                                    value={field.value[index]?.description || ''}
                                                                    invalid={errors.description && errors.description[index] && errors.description[index].description && true}
                                                                    placeholder={`Enter product description`}
                                                                    onChange={(e) => {
                                                                        const updatedValues = [...field.value];
                                                                        updatedValues[index] = { description: e.target.value };
                                                                        field.onChange(updatedValues);
                                                                    }}
                                                                />
                                                                {errors.description && errors.description[index] && errors.description[index].description && (
                                                                    <FormFeedback>{errors.description[index].description.message}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Button
                                                    className='overview-btn'
                                                    size='sm'
                                                    onClick={() => {
                                                        field.onChange([...field.value, { description: '' }]);
                                                    }}
                                                >
                                                    <PlusCircle /> Add Description
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Product Overview <span style={{ color: 'red' }}>*</span></h1>
                        <Row >
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3'>
                                    <Controller
                                        name='productOverview'
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <div>
                                                <Row>
                                                    {field.value.map((item, index) => (
                                                        <Col key={index} sm={12} md={6} lg={4}>
                                                            <div className='mb-3 d-flex flex-column'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div>Product Overview {index + 1}</div>
                                                                        <div className='justify-content-end ms-2 mt-1'
                                                                            color='danger'
                                                                            size='sm'
                                                                            style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                                                            onClick={() => {
                                                                                const updatedValues = [...field.value];
                                                                                updatedValues.splice(index, 1);
                                                                                field.onChange(updatedValues);
                                                                            }}
                                                                        >
                                                                            <Trash2 />
                                                                        </div>
                                                                    </div>
                                                                </Label>
                                                                <Input
                                                                    type="textarea" rows={5} cols={60}
                                                                    name={`productOverview[${index}].description`}
                                                                    value={field.value[index]?.description || ''}
                                                                    invalid={errors.productOverview && errors.productOverview[index] && errors.productOverview[index].description && true}
                                                                    placeholder={`Enter product overview`}
                                                                    onChange={(e) => {
                                                                        const updatedValues = [...field.value];
                                                                        updatedValues[index] = { description: e.target.value };
                                                                        field.onChange(updatedValues);
                                                                    }}
                                                                />
                                                                {errors.productOverview && errors.productOverview[index] && errors.productOverview[index].description && (
                                                                    <FormFeedback>{errors.productOverview[index].description.message}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Button
                                                    className='overview-btn'
                                                    size='sm'
                                                    onClick={() => {
                                                        field.onChange([...field.value, { description: '' }]);
                                                    }}
                                                >
                                                    <PlusCircle /> Add Overview
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className='mt-2 mb-2' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                        <h1 className='fs-3 mb-4 mt-4'>Upload Images</h1>
                        <Row>

                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Icon <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='icon'
                                        id='icon'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="icon"
                                                    type="file"
                                                    {...field}
                                                    accept=".jpeg,.png,.jpg,.jfif"
                                                    invalid={errors.icon && true}
                                                    ref={imageRef1}
                                                    onChange={handleIconUpload}
                                                />
                                                {loads ? (
                                                    <Spinner color='primary' />
                                                ) : (
                                                    <Card style={{ width: "100%", height: "160px", justifyContent: "center", alignItems: "center", border: '1px dashed #E4510B' }} onClick={() => imageRef1?.current?.click()}>
                                                        {icon ? (
                                                            <>
                                                                {/* <img
                                                                    alt="uploaded icon"

                                                                    src={icon}
                                                                    style={{ width: '140px', objectFit: 'cover' }}
                                                                />
                                                                <div
                                                                    className='image-remove'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '1px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={() => setIcon(null)}                                                                    >
                                                                    <X />
                                                                </div> */}
                                                                <div className="m-2" style={{ position: 'relative' }}>
                                                                    <img
                                                                        alt="uploaded"
                                                                        src={icon}
                                                                        style={{
                                                                            width: '100px',
                                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className='image-remove'
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '5px',
                                                                            right: '1px',
                                                                            cursor: 'pointer',
                                                                            color: 'red',
                                                                        }}
                                                                        onClick={() => setIcon(null)}                                                                >
                                                                        <X />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className='text-primary text-center'>
                                                                <h1 style={{ color: "primary" }}><RiImageAddFill /></h1>
                                                                <p>*Upload your product icon</p>
                                                            </div>
                                                        )}
                                                    </Card>

                                                )}
                                                {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Image <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='image'
                                        id='image'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="image"
                                                    type="file"
                                                    {...field}
                                                    accept=".jpeg,.png,.jpg,.jfif"
                                                    invalid={errors.image && true}
                                                    ref={imageRef}
                                                    onChange={handleProfileUpload}
                                                />
                                                {loader ? (
                                                    <Spinner color='primary' />
                                                ) : (
                                                    <Card style={{ width: "100%", height: "160px", border: '1px dashed #E4510B', justifyContent: "center", alignItems: "center" }} onClick={() => imageRef?.current?.click()}>
                                                        {image ? (
                                                            <>
                                                                {/* <img
                                                                    alt="uploaded"
                                                                    src={image}
                                                                    style={{ width: '140px', objectFit: 'cover' }}
                                                                />
                                                                <div
                                                                    className='image-remove'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '1px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={() => setImage(null)}                                                                    >
                                                                    <X />
                                                                </div> */}
                                                                <div className="m-2" style={{ position: 'relative' }}>
                                                                    <img
                                                                        alt="uploaded"
                                                                        src={image}
                                                                        style={{
                                                                            width: '100px',
                                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                    <div
                                                                        className='image-remove'
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '5px',
                                                                            right: '1px',
                                                                            cursor: 'pointer',
                                                                            color: 'red',
                                                                        }}
                                                                        onClick={() => setImage(null)}                                                                >
                                                                        <X />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className='text-primary text-center'>
                                                                <h1 style={{ color: "primary" }}><RiImageAddFill /></h1>
                                                                <p>*Upload your Cover Images</p>
                                                            </div>
                                                        )}
                                                    </Card>

                                                )}
                                                {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4} >
                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                    <div className='d-flex'>
                                        <div>Product Gallery Image <span style={{ color: 'red' }}>*</span></div>
                                    </div>
                                </Label>
                                <Card className='p-3' style={{ display: 'flex', border: '1px dashed #E4510B', width: "100%", justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="">
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {profileImages.map((image, index) => (
                                                <div key={index} className="m-2" style={{ position: 'relative' }}>
                                                    <img
                                                        alt={`preview ${index + 1}`}
                                                        src={image}
                                                        style={{
                                                            width: '100px',
                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                            cursor: 'pointer',
                                                        }}
                                                    // onClick={() => setSelectedImageIndex(index)}
                                                    />
                                                    <div className="cursor-pointer image-close" style={{ position: 'absolute', top: '1px', right: '1px', cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                                        <X style={{ width: '16px', height: '16px' }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                            <input style={{ display: 'none' }} type="file" ref={inputRef1} accept=".jpeg,.png,.jpg,.jfif" onChange={handleImageUpload} multiple />
                                            {loading ? (
                                                <Spinner />
                                            ) : (
                                                <div className='text-center text-primary' onClick={() => inputRef1?.current?.click()}>
                                                    {profileImages.length === 0 ? (
                                                        <>
                                                            <h1> <RiImageAddFill /></h1>
                                                            <p>*Upload your Gallery Images</p>
                                                            <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h1> <RiImageAddFill /></h1>
                                                            <p>*Add more images</p>
                                                            <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>GST Details</h1>
                        <Row >
                            <Col sm={12} md={6} lg={4}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>GSTIN Type <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='gstType'
                                        id='gstType'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={false}
                                                type="select"

                                                {...field}
                                                options={options1}
                                                invalid={errors.gstType && true}
                                            >
                                            </Select>
                                        )}
                                    />
                                    {errors.gstType && <FormFeedback>{errors.gstType.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3 ms-2">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>GSTIN Percentage <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='gstPercentage'
                                        id='gstPercentage'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.gstPercentage && true} placeholder="Enter Your GST Percentage" />
                                        )} />
                                    {errors.gstPercentage && (
                                        <FormFeedback>{errors.gstPercentage.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Product Fields </h1>
                        <Col lg={6} sm={12}>
                            <div className='mb-3'>
                                <Label className='modal-label me-2 s'>
                                    Enable Combo Tees?{'  '}
                                </Label>
                                <Input
                                    type="switch"
                                    checked={isComboTees}
                                    onClick={() => {
                                        setIsComboTees(!isComboTees);
                                    }}
                                />
                            </div>
                        </Col>
                        {fieldTypesFields.map((field, index) => (
                            <div key={field.id}>
                                <Row>
                                    <Col sm={12} md={5}>
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Type</Label>
                                            <Controller
                                                name={`fieldType[${index}].name`}
                                                id='options'
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        isSearchable={false}
                                                        id='fieldType'
                                                        options={getOptionsFieldType}
                                                        value={field.value}
                                                        onChange={(selectedOption) => {
                                                            getFieldOption(selectedOption.value);
                                                            field.onChange(selectedOption);
                                                            getDatas(selectedOption.value, index)
                                                            handleTypeChange(selectedOption, index)
                                                        }}
                                                    />
                                                )} />

                                        </div>
                                    </Col>
                                    <Col sm={5} md={5}>
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Options</Label>
                                            <Controller
                                                name={`fieldType[${index}].options`}
                                                id='options'
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <Select
                                                        // styles={{ width: '100%', height: '100%' }}
                                                        {...field}
                                                        options={fieldoption}
                                                        styles={customStyles(true)}
                                                        isMulti={true}
                                                        closeMenuOnSelect={false}
                                                        isSearchable={false}
                                                        invalid={errors.fieldType && true}
                                                    />
                                                )}
                                            />
                                            {errors.fieldType && errors.fieldType[index]?.options && (
                                                <FormFeedback>{errors.fieldType[index]?.options.message}</FormFeedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={2} md={2}>
                                        <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={(e) => {
                                            getFieldType(typeIds[index], index);
                                            removeFieldType(index)
                                        }}>
                                            <Trash2 />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        ))}
                        <div className='d-flex'>
                            <Button type="button" className='overview-btn' onClick={() => appendFieldType({ name: '', options: [] })}>
                                <PlusCircle /> Add Field
                            </Button>
                        </div>
                        <div className='mt-2 mb-2 mt-4' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                        <h1 className='fs-3 mb-4 mt-4'>Product Specifications</h1>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Product Basic Amount <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='amount'
                                        id='amount'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number" {...field} invalid={errors.amount && true} placeholder="Enter your Product Basic Amount" />)} />
                                    {errors.amount && <FormFeedback>{errors.amount.message}</FormFeedback>}
                                </div>
                            </Col>
                            {!isComboTees && (
                                <>
                                    <Col sm={12} md={6} lg={3}>
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity Type <span style={{ color: 'red' }}>*</span></Label>
                                            <Controller
                                                name='quantityType'
                                                id='quantityType'
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <Select
                                                        isSearchable={false}
                                                        type="select"
                                                        {...field}
                                                        options={quantityTypeDrop}
                                                        invalid={errors.quantityType && true} >
                                                    </Select>
                                                )} />
                                            {errors.quantityType && <FormFeedback>{errors.quantityType.message}</FormFeedback>}
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} lg={3} >
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Minimum Quantity <span style={{ color: 'red' }}>*</span></Label>
                                            <Controller
                                                name='minimunQuantity'
                                                id='minimunQuantity'
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (<Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.minimunQuantity && true} placeholder="Enter Minimun Quantity" />)} />
                                            {errors.minimunQuantity && <FormFeedback>{errors.minimunQuantity.message}</FormFeedback>}
                                        </div>
                                    </Col>
                                    <Col sm={12} md={6} lg={3} >
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Maximum Quantity <span style={{ color: 'red' }}>*</span></Label>
                                            <Controller
                                                name='maximunQuantity'
                                                id='maximumQuantity'
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (<Input style={{ height: '44px' }} type="number" {...field} invalid={errors.maximunQuantity && true} placeholder="Enter Maximun Quantity" />)} />
                                            {errors.maximunQuantity && <FormFeedback>{errors.maximunQuantity.message}</FormFeedback>}
                                        </div>
                                    </Col>

                                    <Col sm={12} md={12} lg={6}>
                                        <div className="col-span-12 md:col-span-12 lg:col-span-6 intro-y">
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Size</Label>
                                            <div className="mt-1">
                                                <TagsInput
                                                    placeHolder="Enter Product size"
                                                    onChange={(value) => handleSizeChange(value.map((v) => v.toUpperCase()))} // Convert each value to uppercase
                                                    value={customizeImageSize}
                                                />
                                                <span>Press enter the size of the product</span>
                                                {errors.customizeImageSize && (
                                                    <div className="mt-2 text-danger">
                                                        {errors.customizeImageSize.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            )}
                            <Col sm={4} md={4} lg={6}>
                                <div className="mb-1">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Weight <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <div className='d-flex'>
                                        <div>
                                            <Controller
                                                name='productWeight'
                                                id='productWeight'
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <Input style={{ height: '44px', width: '300px' }} type="number"  {...field} invalid={errors.productWeight && true} placeholder="Enter Your Product Weight" />
                                                )} />
                                            {errors.productWeight && (
                                                <FormFeedback>{errors.productWeight.message}</FormFeedback>
                                            )}

                                        </div>
                                        <div className='d-flex kg-gm-con'>
                                            <div onClick={() => setIsKg('kg')} className={`${isKg === 'kg' ? 'active-con' : ''} con-width`}>
                                                <h1 className='fs-6'>kg</h1>
                                            </div>
                                            <div onClick={() => setIsKg('gm')} className={`${isKg === 'gm' ? 'active-con' : ''} con-width`}>
                                                <h1 className='fs-6'>gm</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} md={4} lg={6}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>No Shipping Charge Above (Amount) <span style={{ color: 'red' }}>*</span></div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='noShipChargeAbove'
                                        id='noShipChargeAbove'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.noShipChargeAbove && true} placeholder="Enter Your amount" />
                                        )} />
                                    {errors.noShipChargeAbove && (
                                        <FormFeedback>{errors.noShipChargeAbove.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label className='w-100' style={{ fontWeight: "500", color: '#262525' }}>
                                        Length
                                    </Label>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <Controller
                                            name='length'
                                            control={control}
                                            defaultValue='' // Ensure it's a number
                                            render={({ field }) => (
                                                <Input
                                                    className='w-100'
                                                    {...field}
                                                    type="number"
                                                    min='0'
                                                    style={{
                                                        paddingRight: '40px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #DFDFDF',
                                                        background: 'white',
                                                    }}
                                                    invalid={errors.length && true}
                                                    placeholder="Enter Length"
                                                    onChange={(e) => field.onChange(parseFloat(e.target.value))} // Convert to number
                                                />
                                            )}
                                        />
                                        {errors.length && <FormFeedback>{errors.length.message}</FormFeedback>}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                color: '#999999',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            cm
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label className='w-100' style={{ fontWeight: "500", color: '#262525' }}>
                                        Width
                                    </Label>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <Controller
                                            name='width'
                                            control={control}
                                            defaultValue='' // Ensure it's a number
                                            render={({ field }) => (
                                                <Input
                                                    className='w-100'
                                                    {...field}
                                                    type="number"
                                                    min='0'
                                                    style={{
                                                        paddingRight: '40px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #DFDFDF',
                                                        background: 'white',
                                                    }}
                                                    invalid={errors.width && true}
                                                    placeholder="Enter width"
                                                    onChange={(e) => field.onChange(parseFloat(e.target.value))} // Convert to number
                                                />
                                            )}
                                        />
                                        {errors.width && <FormFeedback>{errors.width.message}</FormFeedback>}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                color: '#999999',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            cm
                                        </span>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2} md={2} lg={2}>
                                <div className='mb-2 d-flex flex-column justify-content-center w-100'>
                                    <Label className='w-100' style={{ fontWeight: "500", color: '#262525' }}>
                                        Height
                                    </Label>
                                    <div style={{ position: 'relative', width: '100%' }}>
                                        <Controller
                                            name='height'
                                            control={control}
                                            defaultValue='' // Ensure it's a number
                                            render={({ field }) => (
                                                <Input
                                                    className='w-100'
                                                    {...field}
                                                    type="number"
                                                    min='0'
                                                    style={{
                                                        paddingRight: '40px',
                                                        borderRadius: '5px',
                                                        border: '1px solid #DFDFDF',
                                                        background: 'white',
                                                    }}
                                                    invalid={errors.height && true}
                                                    placeholder="Enter Height"
                                                    onChange={(e) => field.onChange(parseFloat(e.target.value))} // Convert to number
                                                />
                                            )}
                                        />
                                        {errors.height && <FormFeedback>{errors.height.message}</FormFeedback>}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                color: '#999999',
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            cm
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    {!isComboTees && (
                        <Card className='p-4 mt-3'>
                            <h1 className='fs-3 mb-4'>Quantity Based Amount <span style={{ color: 'red' }}>*</span></h1>

                            <div className='mt-3'>
                                {quantityBasedAmountFields.map((field, index) => (
                                    <div key={field.id}>
                                        <Row>
                                            <Col sm={12} md={12} lg={5}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity From</Label>
                                                    <Controller

                                                        name={`quantityBasedAmount[${index}].from`}
                                                        id='from'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Input type="number" style={{ height: '44px' }}
                                                                {...field}
                                                                invalid={errors.from && true}
                                                                placeholder="Enter Quantity From"
                                                                onChange={(e) => {
                                                                    const value = e.target.value ? Number(e.target.value) : '';  // Convert the value to a number
                                                                    field.onChange(value);  // Pass the value to react-hook-form
                                                                }}
                                                            />)} />
                                                    {errors.quantityBasedAmount && errors.quantityBasedAmount[index]?.from && (
                                                        <FormFeedback>{errors.quantityBasedAmount[index]?.from.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>

                                            <Col sm={12} md={6} lg={5}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity To</Label>
                                                    <Controller
                                                        name={`quantityBasedAmount[${index}].to`}
                                                        id='to'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number"
                                                            {...field} invalid={errors.to && true} placeholder="Enter Quantity To"
                                                            onChange={(e) => {
                                                                const value = e.target.value ? Number(e.target.value) : '';  // Convert the value to a number
                                                                field.onChange(value);  // Pass the value to react-hook-form
                                                            }}
                                                        />)} />
                                                    {errors.quantityBasedAmount && errors.quantityBasedAmount[index]?.to && (
                                                        <FormFeedback>{errors.quantityBasedAmount[index]?.to.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6} lg={5}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Amount</Label>
                                                    <Controller
                                                        name={`quantityBasedAmount[${index}].amount`}
                                                        id='amount'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number" {...field} invalid={errors.amount && true} placeholder="Enter Your Amount" />)} />
                                                    {errors.quantityBasedAmount && errors.quantityBasedAmount[index]?.amount && (
                                                        <FormFeedback>{errors.quantityBasedAmount[index]?.amount.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12} lg={5}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Type</Label>
                                                    <Controller
                                                        name={`quantityBasedAmount[${index}].type`}
                                                        id='type'
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                isSearchable={false}
                                                                options={quantityBasedType}
                                                                invalid={errors.form && true}

                                                            />
                                                        )}
                                                    />
                                                    {errors.quantityBasedAmount && errors.quantityBasedAmount[index]?.type && (
                                                        <FormFeedback>{errors.quantityBasedAmount[index]?.type.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6} lg={2}>
                                                <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => { removeQuantityBasedAmount(index); }}>
                                                    <Trash2 />
                                                </div>
                                            </Col>
                                        </Row>
                                        <hr />
                                    </div>
                                ))}
                                <div className='d-flex py-3'>
                                    <Button type="button" className='overview-btn' onClick={() => appendQuantityBasedAmount({})}>
                                        <PlusCircle /> Add Field
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    )}
                    {!isComboTees && (
                        <Card className='p-4 mt-3'>
                            <h1 className='fs-3 mb-4'>Customization Details</h1>
                            <Row>
                                <>
                                    <Col sm={12}>
                                        <div className='mb-1 d-flex flex-column'>
                                            <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "500", color: '#262525' }}>
                                                Upload Users Design <span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Controller
                                                name='upload'
                                                id='upload'
                                                control={control}
                                                defaultValue=''
                                                render={({ field }) => (
                                                    <div className="d-flex">
                                                        {/* True Option */}
                                                        <div>
                                                            <label
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '5px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '3px',
                                                                    backgroundColor: field.value === true ? '#007bff' : 'transparent',
                                                                    color: field.value === true ? '#fff' : '#000',
                                                                    width: "100px",
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    {...field}
                                                                    value={true} // Value is boolean `true`
                                                                    checked={field.value === true} // Ensure correct radio is selected
                                                                    onChange={() => {
                                                                        field.onChange(true); // id explicitly as boolean `true`
                                                                    }}
                                                                />
                                                                <span className='ml-1'>True</span>
                                                            </label>
                                                        </div>

                                                        {/* False Option */}
                                                        <div className='ms-3'>
                                                            <label
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '5px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '3px',
                                                                    backgroundColor: field.value === false ? '#007bff' : 'transparent',
                                                                    color: field.value === false ? '#fff' : '#000',
                                                                    width: "100px",
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    {...field}
                                                                    value={false} // Value is boolean `false`
                                                                    checked={field.value === false} // Ensure correct radio is selected
                                                                    onChange={() => {
                                                                        field.onChange(false); // Set value explicitly as boolean `false`
                                                                    }}
                                                                />
                                                                <span className='ml-1'>False</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            {errors.upload && <FormFeedback>{errors.upload.message}</FormFeedback>}
                                        </div>
                                    </Col>
                                    <div className='mt-2 mb-2' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                                    <Col sm={12}>
                                        <div className='mb-1 d-flex flex-column'>
                                            <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "500", color: '#262525' }}>
                                                Product Customization <span style={{ color: 'red' }}>*</span>
                                            </Label>
                                            <Controller
                                                name="customizeProduct"
                                                id="customizeProduct"
                                                control={control}
                                                defaultValue='' // Make sure the default value is a boolean (false in this case)
                                                render={({ field }) => (
                                                    <div className="d-flex">
                                                        <div>
                                                            <label
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '5px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '3px',
                                                                    backgroundColor: field.value === true ? '#007bff' : 'transparent',
                                                                    color: field.value === true ? '#fff' : '#000',
                                                                    width: "100px",
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    {...field}
                                                                    value={true}  // Value is a boolean (true)
                                                                    checked={field.value === true}  // This makes sure the radio button is checked when value is true
                                                                    onChange={() => {
                                                                        field.onChange(true); // Set value explicitly as boolean `false`
                                                                        setShowCustomUpload(true);
                                                                    }}
                                                                />
                                                                <span className="ml-1">True</span>
                                                            </label>
                                                        </div>
                                                        <div className="ms-3">
                                                            <label
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '5px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '3px',
                                                                    backgroundColor: field.value === false ? '#007bff' : 'transparent',
                                                                    color: field.value === false ? '#fff' : 'black',
                                                                    width: "100px",
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    {...field}
                                                                    value={false}  // Value is a boolean (false)
                                                                    checked={field.value === false}  // This makes sure the radio button is checked when value is false
                                                                    onChange={() => {
                                                                        field.onChange(false); // Set value explicitly as boolean `false`
                                                                        setShowCustomUpload(false);
                                                                    }}
                                                                />
                                                                <span className="ml-1">False</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                            {errors.customizeProduct && <FormFeedback>{errors.customizeProduct.message}</FormFeedback>}
                                        </div>
                                    </Col>
                                    {showCustomUpload && (
                                        <Col sm={12} md={6} lg={12}>
                                            <h1 className='fs-3 mb-4 mt-4'>Upload Customization Image <span style={{ color: 'red' }}>*</span></h1>

                                            {colorProducts.map((colorProduct, colorIndex) => (
                                                <div key={colorIndex} className='mt-3'>
                                                    <Row className='mb-3'>
                                                        <Col sm={12} md={6} lg={6}>
                                                            <Label style={{ fontWeight: "500", color: "#262525" }}>Color Code</Label>
                                                            <div className="position-relative d-flex" style={{ height: "44px" }}>
                                                                {/* Input Box */}
                                                                <Input
                                                                    type="text"
                                                                    style={{ height: "44px", paddingRight: "50px" }} // Leave space for color picker
                                                                    value={colorProduct.colourCode}
                                                                    onChange={(e) => {
                                                                        const updatedColorProducts = [...colorProducts];
                                                                        updatedColorProducts[colorIndex].colourCode = e.target.value;
                                                                        setColorProducts(updatedColorProducts);
                                                                    }}
                                                                    placeholder="Enter Color Code"
                                                                />
                                                                {/* Color Picker */}
                                                                <input
                                                                    type="color"
                                                                    id={`colorPicker-${colorIndex}`}
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "50px", // Position it inside the input box
                                                                        top: "50%",
                                                                        transform: "translateY(-50%)",
                                                                        width: "40px",
                                                                        height: "40px",
                                                                        border: "none",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    value={colorProduct.colourCode} // Sync with input value
                                                                    onChange={(e) => {
                                                                        const updatedColorProducts = [...colorProducts];
                                                                        updatedColorProducts[colorIndex].colourCode = e.target.value; // Set the selected color
                                                                        setColorProducts(updatedColorProducts);
                                                                    }}
                                                                />
                                                                <div
                                                                    className="ms-2"
                                                                    style={{ cursor: "pointer", color: "#AF1B1B" }}
                                                                    onClick={() => {
                                                                        const updatedColorProducts = colorProducts.filter((_, i) => i !== colorIndex);
                                                                        setColorProducts(updatedColorProducts);
                                                                    }}
                                                                >
                                                                    <Trash2 />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {colorProduct.images.map((imageData, imageIndex) => (
                                                        <div key={imageIndex} className="mt-3">
                                                            <Row className="d-flex justify-content-center">
                                                                <Col sm={12} md={5}>
                                                                    <div className="mb-3">
                                                                        <Label style={{ fontWeight: "500", color: "#262525" }}>Name</Label>
                                                                        <Controller
                                                                            name="imageName"
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <Select
                                                                                    isSearchable={true}
                                                                                    id="fieldTypes"
                                                                                    options={filterOptions(colorIndex, imageIndex)} // Filtered options
                                                                                    value={customSide.find((option) => option.value === imageData.name)} // Map ID to label
                                                                                    onChange={(e) => {
                                                                                        const updatedColorProducts = [...colorProducts];
                                                                                        updatedColorProducts[colorIndex].images[imageIndex].name = e.value; // Store the selected ID
                                                                                        setColorProducts(updatedColorProducts);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3} md={3} lg={3}>
                                                                    <div className="mb-3">
                                                                        <Label style={{ fontWeight: "500", color: "#262525" }}>Image</Label>
                                                                        <div className="d-flex" style={{ cursor: "pointer" }}>
                                                                            <input
                                                                                style={{ display: "none" }}
                                                                                type="file"
                                                                                accept=".jpeg,.png,.jpg,.jfif"
                                                                                ref={(el) => (imageRefs.current[colorIndex * 100 + imageIndex] = el)}
                                                                                onChange={(e) => {
                                                                                    const updatedColorProducts = [...colorProducts];
                                                                                    updatedColorProducts[colorIndex].images[imageIndex].loading = true; // Set loading state
                                                                                    setColorProducts(updatedColorProducts);

                                                                                    handleCustomImageUpload(e, colorIndex, imageIndex).then(() => {
                                                                                        updatedColorProducts[colorIndex].images[imageIndex].loading = false; // Reset loading
                                                                                        setColorProducts(updatedColorProducts);
                                                                                    });
                                                                                }}
                                                                            />
                                                                            {imageData.loading ? (
                                                                                <Spinner color="primary" />
                                                                            ) : (
                                                                                <Card
                                                                                    style={{ width: "100px", height: "100px", justifyContent: "center", alignItems: "center" }}
                                                                                    onClick={() => imageRefs.current[colorIndex * 100 + imageIndex]?.click()}
                                                                                >
                                                                                    {imageData.image ? (
                                                                                        <>
                                                                                            <img
                                                                                                alt="uploaded"
                                                                                                src={imageData.image}
                                                                                                style={{ width: "60px", objectFit: "cover" }}
                                                                                            />
                                                                                            <div
                                                                                                className="image-remove"
                                                                                                style={{
                                                                                                    position: "absolute",
                                                                                                    top: "5px",
                                                                                                    right: "1px",
                                                                                                    cursor: "pointer",
                                                                                                    color: "red",
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation(); // Prevent triggering the file input click
                                                                                                    const updatedColorProducts = [...colorProducts];
                                                                                                    updatedColorProducts[colorIndex].images[imageIndex].image = "";
                                                                                                    setColorProducts(updatedColorProducts);
                                                                                                }}
                                                                                            >
                                                                                                <X />
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className="text-primary d-flex align-items-center w-75 h-75">
                                                                                            <div className="text-center">
                                                                                                <h1 style={{ color: "primary" }}>
                                                                                                    <RiImageAddFill size={20} />
                                                                                                </h1>
                                                                                                <p style={{ fontSize: "12px" }}>*Upload your Custom Image</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </Card>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </Col>

                                                                <Col sm={2} md={2} lg={2}>
                                                                    <div
                                                                        className="mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3"
                                                                        style={{ cursor: "pointer", color: "#AF1B1B" }}
                                                                        onClick={() => {
                                                                            const updatedColorProducts = [...colorProducts];
                                                                            updatedColorProducts[colorIndex].images = updatedColorProducts[colorIndex].images.filter(
                                                                                (_, i) => i !== imageIndex
                                                                            );
                                                                            setColorProducts(updatedColorProducts);
                                                                        }}
                                                                    >
                                                                        <Trash2 />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                        </div>
                                                    ))}

                                                    <div className='d-flex'>
                                                        <Button
                                                            type="button"
                                                            className='overview-btn'
                                                            onClick={() => {
                                                                const updatedColorProducts = [...colorProducts];
                                                                updatedColorProducts[colorIndex].images.push({ name: '', image: '' });
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                        >
                                                            <PlusCircle /> Add Custom Image
                                                        </Button>
                                                    </div>
                                                    <hr />
                                                </div>
                                            ))}

                                            <div className='d-flex mt-3'>
                                                <Button
                                                    type="button"
                                                    className='overview-btn'
                                                    onClick={() => setColorProducts([...colorProducts, { colourCode: '', images: [{ name: '', image: '' }] }])}
                                                >
                                                    <PlusCircle /> Add Color Product
                                                </Button>
                                            </div>
                                        </Col>

                                    )}

                                </>
                            </Row>
                        </Card>
                    )}
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Delivery Locations</h1>

                        <Col sm={12}>
                            <div className='mb-1 d-flex flex-column'>
                                <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "500", color: '#262525' }}>
                                    Choose Delivery Method <span style={{ color: 'red' }}>*</span>
                                </Label>
                                <Controller
                                    name='customizeLocations'
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <div className="d-flex">
                                            {/* Manual Locations */}
                                            <label
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '5px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '3px',
                                                    backgroundColor: field.value === true ? '#007bff' : 'transparent',
                                                    color: field.value === true ? '#fff' : '#000',
                                                    width: "180px",
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    {...field}
                                                    value={true}
                                                    checked={field.value === true} // Ensure the correct radio is selected
                                                    onChange={(e) => {
                                                        setIsManualLocations(true); // Show manual locations form
                                                        field.onChange(true); // Set value explicitly as boolean
                                                    }}
                                                />
                                                <span className='ms-1'>Manual Locations</span>
                                            </label>

                                            {/* All Locations */}
                                            <label
                                                className='ms-3'
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '5px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '3px',
                                                    backgroundColor: field.value === false ? '#007bff' : 'transparent',
                                                    color: field.value === false ? '#fff' : '#000',
                                                    width: "150px",
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    {...field}
                                                    value={false}
                                                    checked={field.value === false} // Ensure the correct radio is selected
                                                    onChange={(e) => {
                                                        // Reset all selected states, districts, and pincodes
                                                        setSelectedState([]);
                                                        setSelectedDistricts([]);
                                                        setSelectedPincodes([]);
                                                        setIsManualLocations(false);
                                                        resetField('deliveryLocation'); // Hide manual locations form
                                                        field.onChange(false); // Set value explicitly as boolean
                                                    }}
                                                />
                                                <span className='ms-1'>All Locations</span>
                                            </label>
                                        </div>
                                    )}
                                />
                                {errors.customizeLocations && <FormFeedback>{errors.customizeLocations.message}</FormFeedback>}
                            </div>
                        </Col>

                        {/* Render state and district fields conditionally */}
                        {isManualLocations && (
                            <>
                                {deliveryLocationFields.map((field, index) => (
                                    <div className='mt-2' key={field.id}>
                                        <Row>
                                            <Col sm={12} md={3}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "bolder" }}>Select Delivery Available State <span style={{ color: 'red' }}>*</span></Label>
                                                    <Controller
                                                        name={`deliveryLocation[${index}].state`}
                                                        control={control}
                                                        defaultValue={[]}  // Ensure defaultValue is an array of objects (label, value)
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={getState}  // Ensure options have the correct format (label, value)
                                                                isMulti={false}
                                                                placeholder="Select State"
                                                                closeMenuOnSelect={true}
                                                                isSearchable={true}
                                                                onInputChange={(inputValue) => {
                                                                    getStateOption(inputValue, index); // Fetch districts based on input and current index
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    handleStateChange1(selectedOption, index);
                                                                    field.onChange(selectedOption); // Pass the entire object
                                                                }}
                                                                value={field.value}  // Set value directly from field.value (must match option format)
                                                                invalid={errors?.deliveryLocation?.[index]?.state && true}
                                                            />
                                                        )}
                                                    />
                                                    {errors?.deliveryLocation?.[index]?.state && (
                                                        <FormFeedback>{errors.deliveryLocation[index].state.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "bolder" }}>Select Delivery Available Districts <span style={{ color: 'red' }}>*</span></Label>
                                                    <Controller
                                                        name={`deliveryLocation[${index}].district`}  // Ensure unique names for each field
                                                        control={control}
                                                        defaultValue={[]}  // Ensure defaultValue is an array
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={getDistrict}  // Filtered district options
                                                                isMulti={true}
                                                                closeMenuOnSelect={false}
                                                                placeholder='Select Districts'
                                                                isSearchable={true}
                                                                menuPortalTarget={document.body} // To render menu in the body
                                                                styles={customStyles(true)}
                                                                onInputChange={(inputValue) => {
                                                                    getDistrictOption(inputValue, index); // Fetch districts based on input and current index
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    handleDistrictChange(selectedOption, index); // Update selected districts
                                                                    field.onChange(selectedOption); // Always set value as array
                                                                }}
                                                                value={field.value}  // Ensure field.value is an array before using includes
                                                                invalid={errors?.deliveryLocation?.[index]?.district && true}
                                                            />
                                                        )}
                                                    />
                                                    {errors?.deliveryLocation?.[index]?.district && (
                                                        <FormFeedback>{errors.deliveryLocation[index].district.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "bolder" }}>Select Unavailable Pincode <span style={{ color: 'red' }}>*</span></Label>
                                                    {/* <Controller
                                                        name={`deliveryLocation[${index}].pincode`}  // Ensure unique names for each state field
                                                        control={control}
                                                        defaultValue={[]}  // Ensure defaultValue is an array
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={getPincode}  // Use the updated state containing pincodes
                                                                isMulti={true}
                                                                placeholder='Select Unavailable Pincodes'
                                                                closeMenuOnSelect={false}
                                                                isSearchable={true}
                                                                menuPortalTarget={document.body} // To render menu in the body
                                                                styles={customStyles(true)}
                                                                onInputChange={(inputValue) => {
                                                                    getPincodeOption(selectedDistricts[index], inputValue, index); // Fetch pincodes based on selected districts
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    handlePincodeChange(selectedOption, index); // Update selected pincodes
                                                                    field.onChange(selectedOption); // Always set value as array
                                                                }}
                                                                value={field.value}  // Ensure field.value is an array before using includes
                                                                invalid={errors?.deliveryLocation?.[index]?.pincode && true}
                                                            />
                                                        )}
                                                    /> */}
                                                    <Controller
                                                        name={`deliveryLocation[${index}].pincode`} // Ensure unique names for each state field
                                                        control={control}
                                                        defaultValue={[]} // Ensure defaultValue is an array
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={getPincode} // Use the updated state containing pincodes
                                                                isMulti={true}
                                                                placeholder="Select Unavailable Pincodes"
                                                                closeMenuOnSelect={false}
                                                                isSearchable={true}
                                                                menuPortalTarget={document.body} // To render menu in the body
                                                                styles={customStyles(true)}
                                                                onInputChange={(inputValue) => {
                                                                    // Call the API to fetch matching pincodes based on input
                                                                    getPincodeOption(selectedDistricts, inputValue, index);
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    handlePincodeChange(selectedOption, index); // Update selected pincodes
                                                                    field.onChange(selectedOption); // Always set value as array
                                                                }}
                                                                value={field.value} // Ensure field.value is an array before using includes
                                                                invalid={errors?.deliveryLocation?.[index]?.pincode && true}
                                                            />
                                                        )}
                                                    />
                                                    {errors?.deliveryLocation?.[index]?.pincode && (
                                                        <FormFeedback>{errors.deliveryLocation[index].pincode.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>

                                            <Col sm={2} md={1}>
                                                <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => {
                                                    removeDeliveryLocation(index);
                                                }}>
                                                    <Trash2 />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <div>
                                    <hr />
                                </div>
                                <div className='d-flex'>
                                    <Button type="button" className='overview-btn' onClick={() => appendDeliveryLocation({ state: [], district: [], pincode: [] })}>
                                        <PlusCircle /> Add Field
                                    </Button>
                                </div>
                            </>
                        )}
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Meta Details</h1>
                        <Row>
                            <Col sm={12} md={12} lg={6}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Title <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='metaTitle'
                                        id='metaTitle'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input
                                                style={{ height: '44px' }}
                                                type="text" {...field}
                                                invalid={errors.metaTitle && true}
                                                placeholder="Enter your Meta Title"
                                            />
                                        )} />
                                    {errors.metaTitle && <FormFeedback>{errors.metaTitle.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={6}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Description <span style={{ color: 'red' }}>*</span></Label>
                                    <Controller
                                        name='metaDescription'
                                        id='metaDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.metaDescription && true} placeholder="Enter your Meta Descritpion" />)} />
                                    {errors.metaDescription && <FormFeedback>{errors.metaDescription.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <div className="">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Keywords <span style={{ color: 'red' }}>*</span></Label>
                                    <div className=" w-100">
                                        <Controller
                                            name='metaKeywords'
                                            id='metaKeywords'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (<Input style={{ height: '70px' }} type="textarea" {...field} invalid={errors.metaKeywords && true} placeholder="Enter your Meta Keywords" />)} />
                                        {errors.metaKeywords && <FormFeedback>{errors.metaKeywords.message}</FormFeedback>}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <div className='d-flex fixed-button justify-content-end mt-2'>
                        <Button
                            onClick={() => setModal(true)}
                            disabled={!isValid}
                            className='overview-btn' type='button'>Create Product</Button>
                    </div>
                </Form >
            </div >
            <Modal isOpen={modal} toggle={toggleModal} className="modal-dialog-centered modal-xs">
                <ModalHeader toggle={toggleModal}>
                    Confirmation
                </ModalHeader>
                <ModalBody style={{ paddingTop: '30px', paddingBottom: '30px', fontWeight: '600', fontSize: '14px' }}>
                    <label>
                        Grouped changes for?
                    </label>
                    <select
                        value={groupChange}
                        onChange={(e) => setGroupChange(e.target.value)}
                        className='form-control mt-1'
                    >
                        <option value='all'>All Product</option>
                        <option value='single'>Single Product</option>
                    </select>

                </ModalBody>
                <div className="modal-footer d-flex justify-content-end">
                    <Button style={{ backgroundColor: "#E4510B", border: 'none' }} onClick={triggerSubmit} >
                        Confirm
                    </Button>
                    <Button color="secondary" onClick={toggleModal} >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </>
    )
}

export default AddProductIndex
