import React from 'react'
import logo from '../../../assets/images/logo/printon.png'
import './index.css'
import { LuShirt } from 'react-icons/lu'
import { IoShapesOutline } from 'react-icons/io5'
import { RxText } from 'react-icons/rx'
import { PiUploadLight } from 'react-icons/pi'
import { FaRegCommentDots } from 'react-icons/fa'
import { IoMdImages } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'

function CustomizationSidebar(props) {
    const { activeTab, handleActiveTab, handleSideSwitch, activeSide, templateDetails, isNameNumber, isUnSaved } = props
    const navigate = useNavigate();

    const handleNameNumberTab = () => {
        if (activeSide === 'back') {
            handleActiveTab('namesAndNumbers')
        } else {
            handleActiveTab('namesAndNumbers')
            handleSideSwitch('back')
        }
    }
    const handleTemplateTab = () => {
        if (activeSide === 'front') {
            handleActiveTab('template')
        } else {
            handleActiveTab('template')
            handleSideSwitch('front')
        }
    }
    return (
        <div className='customization-sidebar shadow'>
            <div
                onClick={() => {
                    isUnSaved()
                    navigate('/')
                }}
                className='d-flex justify-content-center pt-2 cursor-pointer'
            >
                <img alt='logo' className='w-75 img-fluid' src={logo} />
            </div>
            <div className='position-relative mt-3'>
                <div onClick={() => handleActiveTab('product')} className={`tools-tab ${activeTab === 'product' ? 'tools-tab-active shadow' : ''}`}>
                    <span><LuShirt size={27} /></span>
                    <h1 className='tools-tab-title m-0 p-0 mt-2'>Product</h1>
                </div>
                <div onClick={() => handleActiveTab('addText')} className={`tools-tab ${activeTab === 'addText' ? 'tools-tab-active shadow' : ''}`}>
                    <span><RxText size={27} /></span>
                    <h1 className='tools-tab-title m-0 p-0 mt-2'>Add Text</h1>
                </div>
                <div onClick={() => handleActiveTab('upload')} className={`tools-tab ${activeTab === 'upload' ? 'tools-tab-active shadow' : ''}`}>
                    <span><PiUploadLight size={27} /></span>
                    <h1 className='tools-tab-title m-0 p-0 mt-2'>Upload Art</h1>
                </div>
                <div onClick={() => handleActiveTab('clipArt')} className={`tools-tab ${activeTab === 'clipArt' ? 'tools-tab-active shadow' : ''}`}>
                    <span><IoMdImages size={27} /></span>
                    <h1 className='tools-tab-title m-0 p-0 mt-2'>Add Clipart</h1>
                </div>
                {isNameNumber && (
                    <div onClick={handleNameNumberTab}
                        className={`tools-tab ${activeTab === 'namesAndNumbers' ? 'tools-tab-active shadow' : ''}`}>
                        <span><FaRegCommentDots size={27} /></span>
                        <h1 className='tools-tab-title m-0 p-0 mt-2'>Names & Numbers</h1>
                    </div>
                )}
                {templateDetails.length > 0 && (
                    <div onClick={handleTemplateTab} className={`tools-tab ${activeTab === 'template' ? 'tools-tab-active shadow' : ''}`}>
                        <span><IoShapesOutline size={27} /></span>
                        <h1 className='tools-tab-title m-0 p-0 mt-2'>Template</h1>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CustomizationSidebar
