import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, FormFeedback, Input, Label, InputGroup, InputGroupText, Spinner } from 'reactstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { forgetPassword, otpSend, signIn } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
// import MobileOTPModal from './mobileOTP';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../finalAuth/index.css'


function SignIn({ toggleAuth1, toggle, value, boolean, setCartKey }) {
    // const [isUser, setIsUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const [mobile1, setMobile1] = useState('');

    useEffect(() => {
        setInputValue(value || inputValue); // Set inputValue to mob value or empty string
    }, [value, inputValue]);
    const inputRef = useRef(null); // Create a ref for the input element


    useEffect(() => {
        if (boolean === true) {
            const storedMobile1 = localStorage.getItem('SignInMobileOrEmail');
            setMobile1(storedMobile1);
            setIsPassword(true);
            setIsLogin(false);
        } else {
            setIsPassword(false);
            setIsLogin(true);
        }
    }, [boolean]);

    // Combined display value
    const displayValue = inputValue || mobile1;


    const SignInEmailSchema = yup.object().shape({
        email: yup
            .string()
            .required('Enter Mobile Number')
            .test('emailOrMobile', 'Please Enter a valid  Mobile Number', (value) => {
                // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                // const emailRegex = /^[^\s@]+@(gmail\.com|hotmail\.com|yahoo\.com|[^\s@]+\.(co\.in|in))$/;
                const mobileRegex = /^[6-9]\d{9}$/; // Updated to ensure mobile number starts with 6-9
                return mobileRegex.test(value);
            }),
        password: yup.string().min(4, 'Password must be at least 4 characters').required('Enter Your Password'),
    });

    const {
        handleSubmit: handleEmailFormSubmit,
        control: emailControl,
        watch,
        setValue: setEmailValue,
        formState: { errors: emailErrors },
    } = useForm({ mode: 'onChange', resolver: yupResolver(SignInEmailSchema) });

    const email = watch('email');
    const isEmailValid = SignInEmailSchema.fields.email.isValidSync(email);

    const handleEmailSignIn = async (data) => {
        const mobile1 = localStorage.getItem('SignInMobile');
        const payload = {
            ...data,
            email: inputValue || mobile1,
            ip: '12',
        };

        try {
            const response = await axios.post(signIn, payload);
            localStorage.setItem('token', response?.data?.result?.tokens?.accessToken);
            localStorage.setItem('refreshToken', response?.data?.result?.tokens?.refreshToken);
            localStorage.setItem('role', response?.data?.result?.user?.role?.name);
            localStorage.setItem('userId', response?.data?.result?.user?._id);
            localStorage.setItem('name', response?.data?.result?.user?.name);
            localStorage.setItem('mobile', response?.data?.result?.user?.mobile);
            localStorage.removeItem('signUpEmail');
            localStorage.removeItem('signUpPassword');
            if (setCartKey === 'yes') {
                localStorage.setItem('AddCart', true);
            }
            toggle();
        } catch (error) {
            toast.error("User Name or Password dosen't exist or Invalid");
            // setIsUser(true);
        }
    };

    const [isLogin, setIsLogin] = useState(true);
    const [isPassword, setIsPassword] = useState(false);

    const handleLoginPage = () => {
        setIsLogin(true);
        setIsPassword(false);
    };

    const handlePasswordPage = () => {
        setIsPassword(true);
        setIsLogin(false);
        localStorage.setItem('SignInMobileOrEmail', email);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        if (isEmailValid) {
            localStorage.setItem('SignInMobileOrEmail', value);
        }
    };

    const preventEnterSubmission = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();  // Prevent form submission
        }
    };

    const handleInputBlur = (e) => {
        const value = e.target.value;

        // Store in local storage if valid
        if (isEmailValid) {
            localStorage.setItem('SignInMobileOrEmail', value);
        }
    };

    const mobile = localStorage.getItem('SignInMobileOrEmail');

    const SendOTP = async () => {
        const payload = {
            mobile: inputValue,
        };

        try {
            setLoading(true)
            const response = await axios.post(otpSend, payload);
            toast.success(response?.data?.msg);
            localStorage.setItem('SignInMobile', mobile);
            toggleAuth1();
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false)
        }
    };

    const forgetPassword1 = async () => {
        const payload = {
            value: inputValue,
        };

        try {
            const response = await axios.post(forgetPassword, payload);
            localStorage.setItem('SignInMobile', inputValue);
            toast.success(response?.data?.msg);
            toggle();
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleContinue = () => {
        if (/^\d+$/.test(inputValue)) {
            // If the input is a mobile number, execute the SendOTP function
            SendOTP();
        } else {
            // If it's an email, execute the handlePasswordPage function
            handlePasswordPage();
        }
    };

    useEffect(() => {
        setEmailValue('email', localStorage.getItem('signUpEmail'));
        setEmailValue('password', localStorage.getItem('signUpPassword'));
    }, [setEmailValue]);

    useEffect(() => {
        // Focus the input when the component mounts
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    return (
        <>
            <div>
                <div className=" text-center">
                    {/* <img src={logo} alt="logo" height={60} className="me-2" /> */}
                    <h1 className="auth-head mt-2">SignIn/SignUp</h1>

                </div>
                <Form onSubmit={(e) => {
                    e.preventDefault(); // Prevent the default form submission behavior
                    handleContinue(); // Trigger the desired function
                }}>
                    <div className='ps-3 pe-3 mb-3 mt-4'>
                        {isLogin && (
                            <>
                                <div className='mb-2'>
                                    <Label style={{ fontWeight: '500' }}>
                                        Mobile
                                        <p className='p-0 m-0 text-secondary' style={{ fontSize: '12px' }}> (New users can sign up as a guest using their mobile number)</p>
                                    </Label>
                                    <Controller
                                        name='email'
                                        id='email'
                                        control={emailControl}
                                        defaultValue={inputValue}
                                        render={({ field }) => (
                                            <Input
                                                innerRef={inputRef}
                                                type='number'
                                                {...field}
                                                value={inputValue}  // Bind the input value to the state
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    // Check if input is numeric (mobile number) and enforce the 10-character limit
                                                    if (/^[6-9]\d*$/.test(value) && value.length > 10) {
                                                        return; // Ignore updates if mobile number exceeds 10 characters
                                                    }
                                                    field.onChange(e);
                                                    handleInputChange(e);
                                                }}
                                                onBlur={handleInputBlur}
                                                onKeyDown={preventEnterSubmission}   // Handle the blur event to store the value in local storage
                                                invalid={emailErrors.email && true}
                                                placeholder='Enter your mobile number'
                                            />
                                        )}
                                    />
                                    {emailErrors.email && <FormFeedback>{emailErrors.email.message}</FormFeedback>}
                                </div>
                                <div>
                                    <p style={{ fontSize: '14px', fontFamily: 'Lato', fontWeight: '400' }}>
                                        By continuing, you agree to Printon’s{' '}
                                        <span
                                            style={{ color: '#E45D25', textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => navigate('/Terms')}
                                        >
                                            Terms of Use
                                        </span>{' '}
                                        and{' '}
                                        <span
                                            style={{ color: '#E45D25', textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => navigate('/privacyPolicy')}
                                        >
                                            Privacy Policy
                                        </span>
                                    </p>
                                </div>
                                <div className='p-3'>

                                    {loading ?
                                        (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Spinner
                                                    color="#E4510B"
                                                >
                                                    Loading...
                                                </Spinner>
                                            </div>
                                        )
                                        :
                                        (
                                            <button
                                                className="auth-button1"
                                                type="submit"
                                                disabled={!isEmailValid}
                                                style={{
                                                    backgroundColor: isEmailValid ? '#E4510B' : '#DFDFDF',
                                                    color: isEmailValid ? 'white' : '#666666',
                                                    cursor: isEmailValid ? 'pointer' : 'not-allowed',
                                                }}
                                            >
                                                Continue
                                            </button>)
                                    }
                                </div>
                                {/* <div style={{ width: '100%' }} className='d-flex'>
                                    <hr style={{ width: '100%' }} />
                                    <p
                                        className='ms-1 me-1'
                                        style={{
                                            color: '#333333D1',
                                            minWidth: 'fit-content',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        New to Printon?
                                    </p>
                                    <hr style={{ width: '100%' }} />
                                </div>
                                <div className='p-3'>
                                    <button
                                        style={{
                                            height: '45px',
                                            width: '100%',
                                            border: '1px solid #C6C6C6',
                                            borderRadius: '5px',
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            background: 'transparent',
                                        }}
                                        onClick={toggleAuth}
                                    >
                                        Create an account
                                    </button>
                                </div> */}
                            </>
                        )}
                        {/* {isPassword && (
                            <>
                                <div className='text-center'>
                                    <p>
                                        {displayValue}{' '}
                                        <span
                                            onClick={handleLoginPage}
                                            style={{ color: '#E45D25', textDecoration: 'underline', cursor: 'pointer' }}
                                        >
                                            change
                                        </span>
                                    </p>
                                </div>
                                <div className='mb-2'>
                                    <Label>Password </Label>
                                    <Controller
                                        name='password'
                                        id='password'
                                        control={emailControl}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <InputGroup>
                                                <Input
                                                    type={showPassword ? 'text' : 'password'} // Toggle input type between 'text' and 'password'
                                                    {...field}
                                                    invalid={emailErrors.password && true}
                                                    placeholder='Enter Your Password'
                                                />
                                                <InputGroupText onClick={togglePasswordVisibility} style={{ cursor: 'pointer', height: '40px' }}>
                                                    {showPassword ? <FaEyeSlash style={{ color: '#E45D25' }} /> : <FaEye style={{ color: '#E45D25' }} />}
                                                </InputGroupText>
                                            </InputGroup>
                                        )}
                                    />
                                    {emailErrors.password && <FormFeedback>{emailErrors.password.message}</FormFeedback>}
                                </div>
                                <div>
                                    <p>
                                        <span
                                            style={{
                                                color: '#E45D25',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                            }}
                                            onClick={forgetPassword1}
                                        >
                                            Forgot password?
                                        </span>
                                    </p>
                                </div>
                                <div className='p-3'>
                                    <button className='auth-button1' type='submit'>
                                        Login
                                    </button>
                                </div>
                            </>
                        )} */}
                    </div>
                </Form>
            </div>
        </>
    );
}

export default SignIn;
