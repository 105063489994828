import React from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'

function CreateTemplate({ openModal, handleModal, templateTitle, setTemplateTitle, finalSubmit, isLoading, templateTypes }) {

    return (
        <Modal isOpen={openModal} className="modal-dialog-centered modal-xs">
            <ModalHeader toggle={handleModal}>
                Create Template
            </ModalHeader>
            <ModalBody style={{ fontSize: '20px', paddingTop: '30px', fontWeight: '400' }}>
                <FormGroup>
                    <Label for="title">
                        Template Title
                    </Label>
                    <Input
                        id="title"
                        name="title"
                        value={templateTitle}
                        onChange={(e) => setTemplateTitle(e.target.value)}
                        placeholder="Enter Template Title"
                        type="text"
                    />
                </FormGroup>
                {/* <FormGroup>
                    <Label for="title">
                        Template Type
                    </Label>
                    <Input
                        id="title"
                        name="type"
                        value={templateTitle}
                        placeholder="Enter Template Title"
                        type="text"
                    />
                </FormGroup> */}
            </ModalBody>
            <div style={{ justifyContent: 'end' }} className="modal-footer">
                <Button onClick={finalSubmit} disabled={!templateTitle || isLoading} style={{ backgroundColor: "#E4510B", border: 'none' }} >
                    {isLoading && <Spinner size={'sm'}>
                        Loading...
                    </Spinner>}
                    <span className='ms-2'>Submit</span>
                </Button>
                <Button color="secondary" onClick={handleModal} >
                    Cancel
                </Button>
            </div>
        </Modal>
    )
}

export default CreateTemplate
