import React from 'react'
import Navbar from '../../navbar'
import FooterSection from '..'
// import './index.css';
import { useNavigate } from 'react-router-dom';

function ReturnPolicy() {
    const navigate = useNavigate()


    const navToHome = () => {
        navigate('/')
    }
    // const navToPrivacyPolicy = () => {
    //     navigate('/returnPolicy')
    // }


    return (
        <div>
            <Navbar />
            <div className='pt-3' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                <div className='d-flex fw-medium mt-4' style={{ color: '#E4510B' }} ><p style={{ cursor: 'pointer', color: '#333333' }} onClick={navToHome}>Home  {`>`}</p>Return Policy</div>
                <div>
                    <div style={{ width: '100%' }} className='d-flex align-items-center mt-2'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Return Policy</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                </div>
                <p class="privacy-para">
                    At Printon, we strive to deliver high-quality, customized printing solutions to meet your needs. However, due to the personalized nature of our products, we have specific return and refund policies to ensure fairness for both parties.
                </p>
                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Eligibility for Returns and Refunds</h1>
                <div className='ms-3'>
                    <p className='privacy-para  mt-4'>
                        Since our products are custom-made, returns are generally not accepted except in the following cases:</p>
                    <p class="bullet-point">
                        Defective Printing: If the product arrives with printing errors due to a mistake on our part (e.g., incorrect design, poor print quality).
                    </p>
                    <p class="bullet-point">
                        Damage During Transit: If the product is damaged during shipping, you must report it within [24-48 hours] of delivery with photographic evidence.
                    </p>
                    <p class="bullet-point">
                        Incorrect Item: If you receive an item that does not match your approved design or order specifications.
                    </p>
                </div>

                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>  Non-Returnable Items</h1>
                <div className='ms-3'>
                    <p class="bullet-point">
                        Custom-printed products based on designs provided or approved by the customer.
                    </p>
                    <p class="bullet-point">
                        Errors resulting from incorrect information or designs submitted by the customer.
                    </p>
                    <p class="bullet-point">
                        How to Initiate a Claim
                    </p>
                </div>

                <h1 className='product-overall-title' style={{ font: 'Manrope', }}>How to Initiate a Claim</h1>
                <div className='ms-3'>
                    <p class="bullet-point">
                        Contact Us: Email us at [support@printon.co.in] or call [customer support number] with your order ID, a detailed explanation, and photos of the issue.
                    </p>
                    <p class="bullet-point">
                        Review: Our quality assurance team will review your claim within [2-3 business days].
                    </p>
                    <p class="bullet-point">
                        Resolution: If the issue is confirmed, we will offer:
                    </p>
                    <p class="bullet-point">
                        A free reprint of the order.
                    </p>
                    <p class="bullet-point">
                        A partial or full refund, depending on the nature of the issue.
                    </p>
                </div>

                <h1 className='product-overall-title' style={{ font: 'Manrope', }}> Order Cancellations</h1>
                <div className='ms-3'>
                    <p class="bullet-point">
                        Orders can be canceled within [1-2 hours] of placement, provided printing has not started. Once production begins, cancellations are not possible.
                    </p>
                    <h1 className='product-overall-title' style={{ font: 'Manrope', }}> Shipping Costs</h1>
                    <p class="bullet-point">
                        For approved claims (defective, damaged, or incorrect items), we will bear the cost of reprinting and shipping.
                    </p>
                    <p class="bullet-point">
                        Returns or claims not meeting the criteria above will not be eligible for refunds, and shipping costs will not be refunded.
                    </p>
                </div>
            </div>





            <FooterSection />
        </div >
    )
}

export default ReturnPolicy