import React, { useCallback, useEffect, useState } from 'react'
// import FooterSection from '../../footer'
import Navbar from '../../navbar'
import TitleComponent from '../../homeNew/TitleComponent'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { Eye, Plus, RotateCcw } from 'react-feather'
import { Spinner } from 'reactstrap'
import { categories } from '../../../ApiConfigs/ApiConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { customStyles, truncateDescription } from '../offerProduct'
import DataTable from 'react-data-table-component'
import AddSliderSection from './AddSliderSection'

function SliderSectionIndex() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [sorts, setSorts] = useState('')
    const [searchKey, setSearchKey] = useState('');
    const [addModal, setAddModal] = useState(false);
    const handleAdd = () => setAddModal(!addModal)
    const navigate = useNavigate()


    // product overall get
    const categoryData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${categories}`);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        categoryData();
    }, [categoryData, sorts, searchKey]); // You can add any dependencies here if necessary, but in this case, it's empty

    useEffect(() => {
        const token = localStorage.getItem('token'); // Extract token outside the useEffect
        if (token) {
            categoryData(); // Call memoized categoryData
        }
    }, [categoryData]);

    const additionalTable = [
        {
            name: 'S No',
            minWidth: '50px',
            cell: (row, i) =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{(i + 1).toString().padStart(2, '0')}</p>
                </>

        },
        // {
        //     name: 'Image',
        //     sortable: false,
        //     minWidth: '150px',
        //     cell: row => <img src={row?.product?.image} height={100} width={150} alt="" style={{ padding: '5px' }} />
        // },
        {
            name: 'Product Category',
            sortable: false,
            minWidth: '250px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.name}</p>
                </>
        },
        {
            name: 'Product Image & Description',
            sortable: false,
            minWidth: '400px',
            cell: row =>
                <>
                    <div className='d-flex'>
                        <img className='d-flex align-items-center' src={row?.image} height={60} width={60} alt="" style={{ padding: '5px', borderRadius: '5px' }} />
                        <span className='mt-2 mb-2' style={{ cursor: 'pointer' }}>
                            {false ? row?.description : truncateDescription(row?.description, 40)}</span>
                    </div>
                </>,
        },
        {
            name: 'Base Price',
            sortable: false,
            minWidth: '80px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.minimumPrice}</p>
                </>
        },
        {
            name: "Action",
            minWidth: "200px",
            cell: (row) => (
                <>
                    {/* <button className='ms-2' style={{ background: '#EEEEEE', border: 'none', width: '40px', height: '40px' }}
                    // onClick={() => openEditModal(row?._id)}
                    >
                        <BiEditAlt color='#4E4E4E' size={25} />
                    </button> */}
                    <button
                        className='' style={{ background: '#D5383826', border: 'none', width: '40px', height: '40px' }}
                    // onClick={() => openDeleteModal(row?._id)}
                    >
                        <Eye color='#E42525' size={25} />
                    </button>
                </>

            )

        }
    ]
    return (
        <div>
            <Navbar />
            <div className='pt-5' style={{ marginLeft: '8%', marginRight: '8%', paddingBottom: '10%' }}>
                <TitleComponent
                    title={`Slider Section Management`}
                    isDesc={true}
                    desc={`Manage the slider section to showcase key highlights, promotions, or featured content. Add, update, or remove slides to create an engaging visual experience for your users.`}
                />
                <div>
                    <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Slider Section</span></p>
                </div>

                <div className='mt-4 order-table-con  shadow-sm'>
                    <div className='d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <div className='red-line me-2'></div>
                            <h1 className='p-0 m-0 h5'>Slider List</h1>
                        </div>
                        <span
                            // onClick={handleReset}
                            className='cursor-pointer'><RotateCcw color='#E4510B' /></span>
                    </div>
                    <div className='line-hr-new'></div>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <div className='d-flex gap-3'>
                                <div className='d-flex flex-column'>
                                    <label style={{ color: '#333333' }} className='fw-medium'>Search</label>
                                    <input
                                        type='text'
                                        className='search-order'
                                        placeholder='Search by category name'
                                        value={searchKey}
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex flex-column'>
                                        <label style={{ color: '#333333' }} className='fw-medium'>Sort By</label>
                                        <select
                                            className='select-order'
                                            value={sorts}
                                            onChange={(e) => setSorts(e.target.value)}
                                        >
                                            <option value='latest'>Latest</option>
                                            <option value='oldest'>Oldest</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <button className='productCategoryBtn mt-3'
                                onClick={handleAdd}
                            ><Plus size={20} /> Add/Edit List</button>
                        </div>
                        {loading ? (
                            <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner
                                    color="primary"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <DataTable
                                // pagination
                                // paginationServer
                                noHeader
                                highlightOnHover
                                fixedHeader
                                fixedHeaderScrollHeight='130vh'
                                data={data}
                                columns={additionalTable}
                                // paginationDefaultPage={currentPage}
                                customStyles={customStyles}
                            // paginationComponent={() => CustomPagination(data?.pagination?.pages)}
                            />
                        )}
                    </div>
                </div>

                {/* add modal */}
                <AddSliderSection open={addModal} handleAdd={handleAdd} refetch={categoryData} data={data} />
            </div>
            {/* <FooterSection /> */}
        </div>
    )
}

export default SliderSectionIndex
