import React, { useCallback, useEffect, useRef, useState } from 'react'
import TitleComponent from '../TitleComponent';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import ProductCard from '../ProductCard';
import { ArrowRight } from 'react-feather';
import { product } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SliderSection({ data, isBg }) {
    const carouselRef = useRef(null);
    const navigate = useNavigate();
    // const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);

    const additionalData = useCallback(async () => {
        try {
            // setLoading(true);
            let url = product;
            const response = await axios.get(`${url}?productCategory=${data?._id}`);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            // setLoading(false);
        }
    }, [data?._id]); // Memoize the function based on data?._id and product

    useEffect(() => {
        if (data?._id) { // Ensure API is called only when data?._id exists
            additionalData();
        }
    }, [data?._id, additionalData]); // Add additionalData as a dependency    

    const responsiveCards = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className={`py-5 container-custom ${isBg ? 'bg-add' : ''} `}>
            <TitleComponent title={data?.name} />
            <div className='mt-3 mt-lg-5'>
                <Carousel
                    ref={carouselRef}
                    responsive={responsiveCards}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    arrows={false}
                    autoPlaySpeed={2000}
                    infinite={true}
                    dotListClass="custom-dot-list-product"
                    itemClass="carousel-item-padding-40-px"
                    className=''
                >
                    {dataa?.map((item, i) => (
                        <div key={i} className='m-2'>
                            <ProductCard data={item} />
                        </div>
                    ))}
                </Carousel>
            </div>
            <div>
                <div className='d-flex justify-content-between justify-content-md-center align-items-center mt-3'>
                    <button onClick={() => navigate(`/${data?.category_url}/all`)} className='explore-btn'>Explore All <ArrowRight size={18} /></button>
                    <div style={{ right: "5%" }} className='d-flex align-items-center gap-3 position-absolute'>
                        <img alt='group ' onClick={() => carouselRef.current.previous()} className='cursor-pointer left-arrow' height={50} draggable={false} src={'https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241119121936218/Group%2033714.png'} />
                        <img alt='group 2' onClick={() => carouselRef.current.next()} className='cursor-pointer right-arrow' height={50} draggable={false} src={'https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241119121936218/Group%2033713.png'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SliderSection
