import React from 'react'
import './index.css'
import { ArrowRight, Download, Save, Tag } from 'react-feather'

function CustomizationFooter({ handleNextStepFun, activeStepsTab, saveDesign, isLogin, toggleTemplate, isModified }) {
    return (
        <div className='customization-footer gap-4 shadow'>
            {activeStepsTab === 'one' && (
                <>
                    {isLogin === 'USER' ? (
                        <>
                            <button onClick={handleNextStepFun} className='next-step-btn-outline d-none d-lg-block'><Tag size={20} /> GET PRICE</button>
                            <button onClick={saveDesign} className='next-step-btn-outline d-none d-lg-block'><Save size={20} /> SAVE DESIGN</button>
                            <button onClick={handleNextStepFun} className='next-step-btn'>NEXT STEP <ArrowRight size={20} /></button>
                        </>
                    ) : (
                        <>
                            {/* <button className='next-step-btn-outline'><Download size={20} /> DOWNLOAD</button> */}
                            <button onClick={toggleTemplate} disabled={!isModified} className={`next-step-btn ${!isModified ? 'text-add-btn-unactive' : ''}`}>CREATE TEMPLATE <ArrowRight size={20} /></button>
                        </>
                    )}

                </>
            )
            }
        </div >
    )
}

export default CustomizationFooter