import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { topProductsAuth } from '../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import ProductCard from './ProductCard';
import TitleComponent from './TitleComponent';
import { ArrowRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

function TopProductSection() {
    // const [loading, setLoading] = useState(false);
    const [dataa, setData] = useState([]);
    const navigate = useNavigate()

    const topProductData = async () => {
        try {
            // setLoading(true);
            const response = await axios.get(topProductsAuth);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            // setLoading(false);
        }
    };
    useEffect(() => {
        topProductData()
    }, [])
    return (
        <div className='py-5 container-custom'>
            <TitleComponent title={`Top Products`} />
            <div className='mt-3 mt-md-5'>
                <Row>
                    {dataa?.slice(0, 8)?.map((item, i) => (
                        <Col key={i} sm={12} md={6} lg={4} xl={3}>
                            <ProductCard data={item} />
                        </Col>
                    ))}
                </Row>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-3'>
                <button onClick={() => navigate('/topProducts')} className='explore-btn'>Explore All <ArrowRight size={18} /></button>
            </div>
        </div>
    )
}

export default TopProductSection
