import React, { useEffect, useState } from 'react'
import './index.css'
import whatsappIcon from "../../../assets/images/landing/icons/ic_baseline-whatsapp.png"
import chervonIcon from "../../../assets/images/landing/icons/gridicons_dropdown.png"
import userIcon from "../../../assets/images/landing/icons/iconamoon_profile-light.png"
import cartIcon from "../../../assets/images/landing/icons/fluent_cart-24-regular.png"
import toast from 'react-hot-toast'

function CustomizationHeader(props) {
    const { activeTab, handleActiveTab } = props

    const [name, setName] = useState()
    const [mobile, setMobile] = useState()

    useEffect(() => {
        const name = localStorage.getItem('name');
        const mobile = localStorage.getItem('mobile');

        setName(name);
        setMobile(mobile);
    }, []);  // Empty dependency array means this will only run once when the component mounts    

    const handleCheck = () => {
        toast.success('working')
    }

    return (
        <div className='customization-header shadow-sm'>
            <div className='ps-5'>
                <div className='d-flex align-item-center gap-4'>
                    <div className='position-relative'>
                        <div onClick={() => handleActiveTab('one')} className='d-flex align-item-center gap-2 step-tab cursor-action'>
                            <div className={`step-tab-count ${activeTab === 'one' ? 'step-tab-count-active' : ''}`}>
                                1
                            </div>
                            <h1 className={`step-tab-title ${activeTab === 'one' ? 'step-tab-title-active' : ''}`}>DESIGN</h1>
                        </div>
                        <div className={`step-tab-bar ${activeTab === 'one' ? 'step-tab-bar-active' : ''}`}></div>
                    </div>
                    <div onClick={() => handleActiveTab('two')} className='position-relative'>
                        <div className='d-flex align-item-center gap-2 step-tab cursor-action'>
                            <div className={`step-tab-count ${activeTab === 'two' ? 'step-tab-count-active' : ''}`}>
                                2
                            </div>
                            <h1 className={`step-tab-title ${activeTab === 'two' ? 'step-tab-title-active' : ''}`}>QUANTITY & SIZES</h1>
                        </div>
                        <div className={`step-tab-bar ${activeTab === 'two' ? 'step-tab-bar-active' : ''}`}></div>
                    </div>
                    <div className='position-relative'>
                        <div className='d-flex align-item-center gap-2 step-tab cursor-action'>
                            <div className={`step-tab-count ${activeTab === 'three' ? 'step-tab-count-active' : ''}`}>
                                3
                            </div>
                            <h1 className={`step-tab-title ${activeTab === 'three' ? 'step-tab-title-active' : ''}`}>REVIEW</h1>
                        </div>
                        <div className={`step-tab-bar ${activeTab === 'three' ? 'step-tab-bar-active' : ''}`}></div>
                    </div>
                </div>
            </div>
            <div className='d-none d-lg-block'>
                <div className='d-flex align-item-center gap-3 pe-4'>
                    <div className='cursor-action'>
                        <div className='d-flex align-items-center'>
                            <p style={{ color: '#E45D25' }} className='nav-list-new p-0 m-0 '>Design Support</p>
                            <img alt='' height={25} src={chervonIcon} />
                        </div>
                        <div className='d-flex gap-1 align-items-center'>
                            <img alt='' height={26} src={whatsappIcon} />
                            <p className='m-0 p-0 wht-app-no'>+91 7708517447</p>
                        </div>
                    </div>
                    <div className='d-flex gap-1 align-items-center cursor-action'>
                        <img alt='' height={25} src={cartIcon} />
                        <p className='nav-list-new p-0 m-0 '>Cart</p>
                    </div>
                    <div style={{ padding: '5px 10px 5px 10px', height: '55px' }} className='d-flex gap-1 align-items-center justify-content-center cursor-action'>
                        <img alt='' height={25} src={userIcon} />
                        {localStorage.getItem('token') ? (
                            <p className='nav-list-new m-0 p-0'>
                                {!name ? (`***${mobile?.slice(6) || ''}`) : (name || '')}
                            </p>
                        ) : (
                            <p className='nav-list-new m-0 p-0'>
                                Login
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CustomizationHeader
