import React from 'react'
import { FaRegCommentDots } from 'react-icons/fa';
import { IoMdImages } from 'react-icons/io';
import { IoShapesOutline } from 'react-icons/io5';
import { LuShirt } from 'react-icons/lu';
import { PiUploadLight } from 'react-icons/pi';
import { RxText } from 'react-icons/rx';
import './index.css'

function MobileToolsList({ activeTab, activeSide, handleActiveTab, isNameNumber, templateDetails, handleSideSwitch }) {

    const handleNameNumberTab = () => {
        if (activeSide === 'back') {
            handleActiveTab('namesAndNumbers')
        } else {
            handleActiveTab('namesAndNumbers')
            handleSideSwitch('back')
        }
    }
    const handleTemplateTab = () => {
        if (activeSide === 'front') {
            handleActiveTab('template')
        } else {
            handleActiveTab('template')
            handleSideSwitch('front')
        }
    }
    return (
        <div className='mobile-customization-footer'>
            <div style={{ backgroundColor: 'white' }} className=' overflow-auto p-2 ps-3 pe-3 d-flex justify-content-between align-items-center mobile-tools-list position-relative'>
                <div onClick={() => handleActiveTab('addText')} className={`cus-tab-1 ${activeTab === 'text' ? 'cus-tab-1-active' : ''}`}>
                    <span><RxText size={20} /></span>
                    <p className='mobile-tool-label p-0 m-0 mt-1 '>Add Text</p>
                </div>
                <div onClick={() => handleActiveTab('upload')} className={`cus-tab-1 ${activeTab === 'upload' ? 'cus-tab-1-active' : ''}`}>
                    <span><PiUploadLight size={20} /></span>
                    <p className='mobile-tool-label p-0 m-0 mt-1'>Upload Art</p>
                </div>
                {isNameNumber && (
                    <div onClick={handleNameNumberTab} className={`cus-tab-1 ${activeTab === 'Names & Numbers' ? 'cus-tab-1-active' : ''}`}>
                        <span><FaRegCommentDots size={20} /></span>
                        <p className='mobile-tool-label p-0 m-0 mt-1'>Names & Numbers</p>
                    </div>
                )}
                {templateDetails.length > 0 && (
                    <div onClick={handleTemplateTab} className={`cus-tab-1 ${activeTab === 'Names & Numbers' ? 'cus-tab-1-active' : ''}`}>
                        <span><IoShapesOutline size={20} /></span>
                        <p className='mobile-tool-label p-0 m-0 mt-1'>Template</p>
                    </div>
                )}
                <div onClick={() => handleActiveTab('clipArt')} className={`cus-tab-1 ${activeTab === 'clip-art' ? 'cus-tab-1-active' : ''}`}>
                    <span><IoMdImages size={20} /></span>
                    <p className='mobile-tool-label p-0 m-0 mt-1'>Add Clipart</p>
                </div>
                <div onClick={() => handleActiveTab('product')} className={`cus-tab-1 ${activeTab === 'product' ? 'cus-tab-1-active' : ''}`}>
                    <span><LuShirt size={20} /></span>
                    <p className='mobile-tool-label p-0 m-0 mt-1'>Product</p>
                </div>
            </div>
        </div>
    )
}

export default MobileToolsList
