import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'

function SaveDraftModal({ openModal, handleModal, loadSavedCanvas }) {
    return (
        <Modal isOpen={openModal} className="modal-dialog-centered modal-xs">
            <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>Start Where you left off! continue editing...</ModalBody>
            <div style={{ justifyContent: 'center' }} className="modal-footer">
                <Button onClick={loadSavedCanvas} style={{ backgroundColor: "#E4510B", border: 'none' }} >
                    Continue Editing
                </Button>
                <Button color="secondary" onClick={handleModal} >
                    Start From Scratch
                </Button>
            </div>
        </Modal>
    )
}

export default SaveDraftModal