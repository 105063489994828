import React from 'react'
import './index.css';
import { Link, useNavigate } from 'react-router-dom';

function ProductCategoryCard({ data }) {
    const navigate = useNavigate();

    return (
        <Link to={`/${data?.category_url}/all`}>
            <div className='cursor-pointer product-card-new shadow-sm mb-3 mb-lg-5'>

                <img alt='' className='img-fluid rounded-4' src={data?.image} />

                <div className='product-card-new-btm p-3 pt-5'>
                    <div className='d-flex align-items-center gap-1'>
                        <img alt='' height={16} src={`https://printontesting.s3.ap-south-1.amazonaws.com/printon/common_20241118164415138/Star%205.png`} />
                        <p className='p-0 m-0'>4.1</p>
                    </div>
                    <h1 className='fs-5 m-0 p-0 mt-1'>{data?.name}</h1>
                    {/* <p className='m-0 p-0 mt-1'>
                    {data?.description
                        ? data.description.length > 35
                            ? `${data.description.substring(0, 35)}...`
                            : data.description
                        : "No description available"}
                </p> */}
                </div>
            </div>
        </Link>
    )
}

export default ProductCategoryCard
