import React from 'react'
import line from '../../assets/images/landing/Line 2.png'
import "./index.css"

function TitleComponent({ title, desc, isDesc }) {
    return (
        // <div className='d-flex justify-content-center align-items-center w-100'>
        //     <div>
        //         <div className='d-flex align-items-center justify-content-center gap-3'>
        //             <img alt='' className='d-none d-md-block line-bar-new' src={line} />
        //             <h1 style={{ color: '#414141' }} className='text-center'>{title}</h1>
        //             <img alt='' className='d-none d-md-block line-bar-new' src={line} />
        <>
            <div className='d-flex justify-content-center align-items-center w-100'>
                <div>
                    <div className='d-flex align-items-center justify-content-center gap-3'>
                        <img alt='line-bar' className='d-none d-md-block line-bar-new' src={line} />
                        <h1 style={{ color: '#414141' }} className='text-center'>{title}</h1>
                        <img alt='line-bar' className='d-none d-md-block line-bar-new' src={line} />
                    </div>
                </div>
            </div>
            {isDesc && (
                <div className='w-100 d-flex align-items-center justify-content-center'>
                    <div className='w-75'>
                        <p style={{ color: "#898989" }} className='text-center'>{desc}</p>
                    </div>
                </div>
            )}
        </>
    )
}

export default TitleComponent
