// // CommonLayout.js
// import React from "react";
// import { Helmet } from "react-helmet";
// import { useLocation } from "react-router-dom"; // Import useLocation hook
// import { domainUrl } from "../../ApiConfigs/ApiConfig";

// const CommonLayout = () => {
//   const location = useLocation();

//   // Construct the canonical URL based on current pathname
//   const canonicalUrl = `${domainUrl}${location.pathname}`;
//   return (
//     <Helmet>
//       <link rel="canonical" href={canonicalUrl} />
//     </Helmet>
//   );
// };

// export const HtmlHeaderElements = ({ title, description, keywords }) => {
//   const defaultContent =
//     "High-Quality Online Printing Services in Bangalore | Business Cards, Flyers, Banners, Invitations";
//   return (
//     <Helmet>
//       <title>{title || defaultContent}</title>
//       <meta name="description" content={description || defaultContent}></meta>
//       <meta name="keywords" content={keywords || defaultContent}></meta>
//     </Helmet>
//   );
// };
// export default CommonLayout;

// CommonLayout.js
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import { domainUrl, metaData } from "../../ApiConfigs/ApiConfig";
import {
  DEFAULT_META_TITLE,
  DEFAULT_META_DESCRIPTION,
  DEFAULT_META_KEYWORDS,
} from "../../constants/constants.js";

const CommonLayout = () => {
  const [metaData1, setMetaData] = useState({
    title: DEFAULT_META_TITLE,
    description: DEFAULT_META_DESCRIPTION,
    keywords: DEFAULT_META_KEYWORDS,
    loading: true,
    error: null,
  });
  const token = localStorage.getItem("token");
  const location = useLocation();
  // Function to fetch meta data based on the current route
  const fetchMetaData = useCallback(async (path) => {
    //Eg: path = /product-details
    try {
      const response = await fetch(`${metaData}?path=${path.slice(1) === "" ? '/' : path.slice(1)}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch meta data");
      }

      const data1 = await response.json();
      const data = data1?.result?.rows[0];

      setMetaData({
        title: data.title || DEFAULT_META_TITLE,
        description: data.description || DEFAULT_META_DESCRIPTION,
        keywords: data.keywords || DEFAULT_META_KEYWORDS,
        loading: false,
        error: null,
      });
    } catch (err) {
      setMetaData({
        title: DEFAULT_META_TITLE,
        description: DEFAULT_META_DESCRIPTION,
        keywords: DEFAULT_META_KEYWORDS,
        loading: false,
        error: err.message,
      });
    }
  }, [token]);

  // Construct the canonical URL based on current pathname
  const canonicalUrl = `${domainUrl}${location.pathname}`;
  // Trigger the API call whenever the route changes
  useEffect(() => {
    setMetaData((prevData) => ({ ...prevData, loading: true }));
    fetchMetaData(`${location.pathname}`);
  }, [location.pathname, fetchMetaData]);

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
      <title>{metaData1.loading ? DEFAULT_META_TITLE : metaData1.title}</title>
      <meta
        name="description"
        content={
          metaData1.loading ? DEFAULT_META_DESCRIPTION : metaData1.description
        }
      />
      <meta
        name="keywords"
        content={metaData1.loading ? DEFAULT_META_KEYWORDS : metaData1.keywords}
      />
      <meta name="robots" content="index, follow" />
    </Helmet>
  );
};

export const HtmlHeaderElements = ({ title, description, keywords }) => {
  const defaultContent =
    "High-Quality Online Printing Services in Bangalore | Business Cards, Flyers, Banners, Invitations";
  return (
    <Helmet>
      <title>{title || defaultContent}</title>
      <meta name="description" content={description || defaultContent}></meta>
      <meta name="keywords" content={keywords || defaultContent}></meta>
    </Helmet>
  );
};
export default CommonLayout;

