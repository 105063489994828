import React, { useState } from 'react'
import { ArrowLeft, ArrowRight, X } from 'react-feather'
import { Spinner } from 'reactstrap'

function FinalReviewStep({ handleSaveAndUploadAllCanvasStates, data, setActiveStepsTab, selectedDetails, sizeQuantities, sizeQuantities1, quantities, totalQuantity, manualQuantity, selectedColorIndex }) {
    const [isLoading, setIsLoading] = useState(false)
    return (
        <div className=''>
            <h1 className='tool-title'>REVIEW YOUR ORDER</h1>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center gap-2'>
                    <span onClick={() => setActiveStepsTab('two')} className='cursor-action'><ArrowLeft /></span>
                    <h1 className='tool-sub-title'>Your Product & Pricing</h1>
                </div>
                <span onClick={() => setActiveStepsTab('one')} className='cursor-action'><X /></span>
            </div>
            <div className='tool-bar-line mt-2'></div>
            <div>
                <div className='d-flex flex-wrap gap-3 mt-4'>
                    {selectedDetails?.map((item, i) => (
                        <div key={i} className='shadow-sm selected-con'>
                            <p className='selected-type-value'>{item?.fieldName}</p>
                            <hr className='m-0 p-0' />
                            <div className='selected-type-option-value'>
                                {item?.selectedOption}</div>
                        </div>
                    ))}
                    <div className='shadow-sm selected-con'>
                        <p className='selected-type-value'>{`Quantity`}</p>
                        <hr className='m-0 p-0' />
                        <div className='selected-type-option-value'>
                            {sizeQuantities.length > 0 ? (`${totalQuantity}`) : (`${manualQuantity}`)}</div>
                    </div>
                </div>
            </div>
            <div className='tool-bar-line mt-4 mb-3'></div>
            <div>
                <p><span className='summary-label'>Summary</span> ({sizeQuantities.length > 0 ? (`${totalQuantity}`) : (`${manualQuantity}`)} items)</p>
                <div className='d-flex justify-content-between flex-wrap gap-2'>
                    <div className='d-flex gap-2'>
                        <div className='tool-product-img-con'>
                            <img className='img-fluid tool-product-img' src={data?.customizeImages?.[selectedColorIndex]?.images?.[0]?.image} alt='product' />
                        </div>
                        <div>
                            <h1 className='tool-product-name'>{data?.name}</h1>
                            <div className='d-flex align-items-center gap-4'>
                                <div className='d-flex align-items-center gap-1'>
                                    <div
                                        className={`${selectedColorIndex ? 'clr-con-active' : 'clr-con'}`}
                                    >
                                        <div
                                            style={{ backgroundColor: data?.customizeImages?.[selectedColorIndex]?.colourCode, width: '100%', height: '100%', borderRadius: '50%' }}></div>
                                    </div>
                                    <span>{data?.customizeImages?.[selectedColorIndex]?.colourCode}</span>
                                </div>
                                <div>|</div>
                                <div style={{ fontWeight: '600' }}>
                                    {sizeQuantities.length > 0 ? (`${totalQuantity} items`) : (`${manualQuantity} items`)}
                                </div>
                            </div>
                            <div className='d-flex gap-3 flex-wrap mt-2'>
                                {sizeQuantities1 && sizeQuantities1.length > 0 && (
                                    <>
                                        {sizeQuantities1?.map((item, i) => (
                                            <div key={i} className='size-con'>
                                                {item?.size} - {item?.quantity}
                                            </div>
                                        ))}
                                    </>

                                )}
                                {quantities && Object.entries(quantities).length > 0 && (
                                    <>
                                        {Object.entries(quantities).map(([size, quantity], i) => (
                                            <div key={i} className="size-con">
                                                {size} - {quantity}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        $47.68 each
                    </div>
                </div>
            </div>
            <div className='tool-bar-line mt-4'></div>
            <div className='d-flex justify-content-center align-items-center mt-4'>
                <div>
                    <button
                        onClick={() => {
                            handleSaveAndUploadAllCanvasStates()
                            setIsLoading(true)
                        }} disabled={isLoading}
                        className='next-step-btn-1'
                    >
                        {isLoading && (
                            <Spinner size="sm">
                                Loading...
                            </Spinner>
                        )}
                        ADD TO CART
                        <ArrowRight size={20} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FinalReviewStep
