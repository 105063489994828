import React, { useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const BlockNavigation = ({ when, message }) => {
    const { navigator } = React.useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        const pushState = navigator.push;
        navigator.push = (...args) => {
            if (window.confirm(message)) {
                pushState.apply(navigator, args);
            }
        };

        return () => {
            navigator.push = pushState;
        };
    }, [when, message, navigator]);

    return null;
};

export default BlockNavigation;
