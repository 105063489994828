import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import { ChevronDown, X, ArrowRight, Edit3 } from 'react-feather'
import "./index.css"
import { useLocation, useNavigate } from 'react-router-dom'
import axios from "axios"
import { addCart, CheckPincode, CheckPincode1, getCartRate, product, productOG, profile, quantityCountApi, ratingReview, wishList } from '../../ApiConfigs/ApiConfig'
// import * as yup from "yup";
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import img1 from "../../assets/images/banner/gallery1.jpg"
// import img2 from "../../assets/images/banner/gallery2.jpg"
// import img3 from "../../assets/images/banner/gallery3.jpg"
import star from "../../assets/images/logo/Star 2.png"
import star5 from "../../assets/images/logo/Star 5.png"
import emptyStarIcon from "../../assets/images/logo/emptystar.svg"
import line from "../../assets/images/logo/Line 1.png"
import { IoIosAddCircle } from "react-icons/io";
// import man from '../../assets/images/banner/man.jpg'
// import man1 from '../../assets/images/banner/man1.jpg'
// import man2 from '../../assets/images/banner/man2.jpg'
// import man3 from '../../assets/images/banner/man3.jpg'
// import man4 from '../../assets/images/banner/man4.jpg'
// import man5 from '../../assets/images/banner/serious-man-looking-camera_23-2147799044.avif'
import review1 from "../../assets/images/banner/review1.png";
// import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import { FiShoppingCart } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import { FiUpload } from "react-icons/fi";
import { multiImageUpload } from '../admin/upload'
import toast from 'react-hot-toast'
import Navbar from '../navbar'
import FooterSection from '../footer'
import ImageSlider from './productImage'
import heart from "../../assets/images/logo/heart.png"
import { MdVerified } from "react-icons/md";
import { RiImageAddFill } from "react-icons/ri";
import { HiInformationCircle } from "react-icons/hi";
import { TbTruckDelivery } from "react-icons/tb";
// import { HtmlHeaderElements } from '../common/CommonLayout'
// import Slider from 'react-slick'
import FinalAuthModal from '../navbar/finalAuth'
// import { FaAngleRight } from "react-icons/fa6";
import ProductCard from '../homeNew/ProductCard'
import TitleComponent from '../homeNew/TitleComponent'
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import he from 'he';
import { IoShapesOutline } from 'react-icons/io5'
// import { HtmlHeaderElements } from '../common/CommonLayout'


function ProductView() {

    // const inputRef = useRef(null);
    const inputRef1 = useRef(null);
    const [profileImages, setProfileImages] = useState([]);
    // const [mainImage, setMainImage] = useState("");
    const [selectedColorIndex, setSelectedColorIndex] = useState(null);
    localStorage.setItem('selectedColorIndex', selectedColorIndex);
    // const [selectedImageIndex1, setSelectedImageIndex1] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [load, setLoad] = useState(false);
    const [dataa, setData] = useState();
    const [inActive, setInActive] = useState(true)
    const [cmActive, setCmActive] = useState(false)
    const [CardImgName1, setCardImgName] = useState([]);
    const [dataa1, setData1] = useState();
    // const { id } = useParams();
    const [quantity, setQuantity] = useState(1);
    const [quantityCount, setQauntityData] = useState();
    const [quantityRate, setQuantityRate] = useState();
    // const [payloadData, setPayloadData] = useState({})
    const [errorMessage, setErrorMessage] = useState("");
    // const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [selectedSize, setSelectedSize] = useState('');
    // const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [minimumQuantity, setMinimumQuantity] = useState()
    const [maximumQuantity, setMaximumQuantity] = useState()
    const [quantityError, setQuantityError] = useState('');
    const [open, setOpen] = useState('1');
    const location = useLocation();
    const toggle1 = () => setModal1(!modal1);
    const [isDescription, setIsDescription] = useState(true);
    const [isFaq, setIsFaq] = useState(false);
    const [isReview, setIsReview] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [isToggle, setToggle] = useState(false)
    const [wishlistData, setWishlistData] = useState()
    const [review, setReview] = useState()
    const [isExpanded, setIsExpanded] = useState(false);
    const [sizeQuantities, setSizeQuantities] = useState([]);
    const [sizeQuantities1, setSizeQuantities1] = useState([]);
    console.log(sizeQuantities1);

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };
    const carouselRef = useRef(null);
    const wishlist = useCallback(async () => {
        try {
            const response = await axios.get(`${wishList}`)
            setWishlistData(response?.data?.result)
        } catch (error) {

        }
    }, [])
    const [addCouponCodeModal, setAddCouponCodeModal] = useState(false);
    const [isMultiSize, setIsMultiSize] = useState(true);
    // const [couponCodeError, setCouponCodeError] = useState(null);
    const [quantities, setQuantities] = useState({});
    const [totalQuantity, setTotalQuantity] = useState()
    console.log(totalQuantity);
    useEffect(() => {
        const manualQuantityValue = sizeQuantities1.reduce((acc, item) => acc + item.quantity, 0);
        setTotalQuantity(manualQuantityValue)
    }, [sizeQuantities1])

    const openCouponCodeModal = () => {
        setAddCouponCodeModal(true);
    }
    const closeCouponCodeModal = () => {
        setAddCouponCodeModal(false);
        // setCouponCodeError(null);
    };

    const handleInSizeTable = () => {
        setInActive(true);
        setCmActive(false)
    }

    const handleCmSizeTable = () => {
        setCmActive(true);
        setInActive(false);
    }
    useEffect(() => {
        const calculateSizeQuantities = (sizeQuantities) => {
            const sizeCounts = {};

            sizeQuantities.forEach(({ size }) => {
                sizeCounts[size] = (sizeCounts[size] || 0) + 1;
            });

            return sizeCounts;
        };
        const calculatedQuantities = calculateSizeQuantities(sizeQuantities1);
        setQuantities(calculatedQuantities);
    }, [sizeQuantities1]);

    const handleManualQuantityChange = async (size, value) => {
        const quantity = Number(value) || 0; // Convert to number or default to 0

        const updatedSizeQuantities = sizeQuantities1.map(entry =>
            entry.size === size ? { ...entry, quantity } : entry
        );
        if (!updatedSizeQuantities.some(entry => entry.size === size)) {
            updatedSizeQuantities.push({ size, quantity });
        }
        // Update the sizeQuantities array
        setSizeQuantities1(prevSizeQuantities => {
            const existingEntry = prevSizeQuantities.find(entry => entry.size === size);
            if (existingEntry) {
                // Update existing entry
                return prevSizeQuantities.map(entry =>
                    entry.size === size ? { ...entry, quantity } : entry
                );
            } else {
                // If not found, add new entry
                return [...prevSizeQuantities, { size, quantity }];
            }
        });
        if (true) {
            const payload = {
                product: dataa?._id,
                sizeWithQuantity: updatedSizeQuantities,
                field: dataa?.fieldType?.map((eachItem) => ({
                    fieldType: eachItem?.name?._id,
                    option: selectedOptions[eachItem?.name?._id] || null,
                })),
            };

            try {
                setLoadings(true)
                const response = await axios.post(getCartRate, payload);
                setQuantityRate(response?.data?.result);
            } catch (error) {
                console.error('Error fetching rate', error);
            }
            finally {
                setLoadings(false)
            }
        }
        localStorage.setItem('selectedSizeQuantity', JSON.stringify(updatedSizeQuantities));
    };
    useEffect(() => {
        if (Array.isArray(dataa?.customizeImages) && dataa.customizeImages.length > 0) {
            // Only add the 0th image's URLs as the default images
            const defaultImage = dataa.customizeImages[0].images.map(image => image.image);
            setCardImgName(defaultImage);
            // setMainImage(defaultImage[0]);  // Set the first image of the 0th color as the main image
            setSelectedColorIndex(0);
        } else {
            // If customizeImages is not an array or is empty, set an empty array
            setCardImgName([]);
        }
    }, [dataa?.customizeImages]);

    useEffect(() => {
        wishlist()
    }, [wishlist])


    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const toggleEmail = () => {
        setToggle(!isToggle)
    }

    const productName = window.location.pathname.split('/').pop();

    const CardImg = [
        ...(dataa?.image ? [dataa.image] : []),
        ...(dataa?.galleryImages ?? [])
    ]

    const CardImg1 = [
        ...CardImgName1
    ]

    const [totalFinalAmount, setTotalFinalAmount] = useState()

    useEffect(() => {
        const amount = quantityRate?.amount
        if (isMultiSize) {
            const totalAmount = amount * totalQuantity;
            setTotalFinalAmount(totalAmount)
        } else {
            const totalAmount = amount * quantity;
            setTotalFinalAmount(totalAmount)
        }
    }, [totalQuantity, quantity, quantityRate, isMultiSize])
    const amount = quantityRate?.amount
    const totalAmount = amount * quantity;
    const actualAmount = dataa?.amount * quantity;
    const offerAmount = amount * quantity;

    // const final = quantity * amount;


    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [selectedOptions, setSelectedOptions] = useState({});

    const handleOptionChange = async (fieldType, selectedOption) => {
        setSelectedOptions(prevState => {
            const updatedOptions = {
                ...prevState,
                [fieldType]: selectedOption
            };
            // Convert updatedOptions to an array with fieldType included
            const optionsArray = Object.entries(updatedOptions).map(([fieldType, option]) => ({
                fieldType,
                option
            }));

            // Store as an array in localStorage
            localStorage.setItem('selectedOptions', JSON.stringify(optionsArray));
            return updatedOptions;
        });
    };

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
        localStorage.setItem('customizeImageSize', event.target.value);
    };

    useEffect(() => {

        const handleOnChange = async () => {
            if (!dataa || !dataa._id || !dataa.fieldType) {
                return; // Exit early if dataa or its required properties are undefined
            }

            const payload = {
                product: dataa?._id,
                quantity: quantity,
                field: dataa?.fieldType?.map((eachItem) => (
                    {
                        fieldType: eachItem?.name?._id,
                        option: selectedOptions[eachItem?.name?._id] || null,
                    }
                )),
            };
            try {
                const response = await axios.post(getCartRate, payload);
                setQuantityRate(response?.data?.result)
            } catch (error) {
            }
        }

        handleOnChange();
        const optionsArray = dataa?.fieldType?.map((eachItem) => (
            {
                fieldType: eachItem?.name?._id,
                option: selectedOptions[eachItem?.name?._id] || null,
            }
        ));

        localStorage.setItem('selectedOptions', JSON.stringify(optionsArray)); // Save selected options to localStorage
        localStorage.setItem('quantity', JSON.stringify(quantity));
    }, [dataa, quantity, selectedOptions])

    useEffect(() => {
        if (dataa?.customizeImageSize && dataa.customizeImageSize.length > 0) {
            setSelectedSize(dataa.customizeImageSize[0]);
            localStorage.setItem('customizeImageSize', dataa.customizeImageSize[0]);
        }
    }, [dataa]);

    // const handleQuantityInput = (event) => {
    //     const value = event.target.value;

    //     if (value.length >= 2) {
    //         handleQuantityChange(event); // Trigger the main function only after two digits
    //     } else {
    //         setQuantity(value); // Update the state immediately with the input value
    //     }
    // };

    // const handleQuantityInput = (event) => {
    //     const value = event.target.value;

    //     // Clear the error message as user starts typing
    //     setQuantityError("");

    //     // Condition: Clear the field when entering value manually (empty to allow manual input)
    //     if (value === "") {
    //         setQuantity("");
    //     } else if (value.length <= 1) {
    //         handleQuantityChange(event);  // Update state for every input
    //     } else {
    //         handleQuantityChange(event); // Call main function after minimum length reached
    //     }
    // };



    // const handleQuantityChange = async (event) => {

    //     const newQuantity = parseInt(event.target.value);

    //     // Clear the input field if quantity type is manual and the new value is invalid
    //     if (dataa?.quantityType !== 'dropdown' && isNaN(newQuantity)) {
    //         setQuantity(''); // Clear the input box for manual input
    //         setQuantityError(''); // Clear error when the field is cleared
    //         localStorage.removeItem('quantity');
    //         return;
    //     }

    //     if (newQuantity <= 0) {
    //         setQuantity(0);
    //         localStorage.setItem('quantity', 0);
    //         return;

    //     }
    //     if (newQuantity >= minimumQuantity && newQuantity <= maximumQuantity) {
    //         setQuantity(newQuantity);
    //         localStorage.setItem('quantity', newQuantity);

    //         const payload = {
    //             product: dataa?._id,
    //             quantity: newQuantity,
    //             field: dataa?.fieldType?.map((eachItem) => ({
    //                 fieldType: eachItem?.name?._id,
    //                 option: selectedOptions[eachItem?.name?._id] || null,
    //             })),
    //         };

    //         try {
    //             const response = await axios.post(getCartRate, payload);
    //             setQuantityRate(response?.data?.result);
    //         } catch (error) {
    //         }
    //     } else {
    //         if (newQuantity < minimumQuantity) {
    //             setQuantityError(`Minimum quantity allowed is ${minimumQuantity}`);
    //         } else if (newQuantity > maximumQuantity) {
    //             setQuantityError(`Maximum quantity allowed is ${maximumQuantity}`);
    //         }
    //     }
    // };

    const handleQuantityChange = async (event) => {
        const newQuantity = parseInt(event.target.value);

        // Clear the input field if quantity type is manual and the new value is invalid
        if (dataa?.quantityType !== 'dropdown' && isNaN(newQuantity)) {
            setQuantity(''); // Clear the input box for manual input
            setQuantityError(''); // Clear error when the field is cleared
            localStorage.removeItem('quantity');
            return;
        }

        // Set the quantity when a valid number is entered (allow all numbers)
        if (!isNaN(newQuantity)) {
            setQuantity(newQuantity);
            localStorage.setItem('quantity', newQuantity);
        }

        // Check for range errors: Ensure quantity is within the valid range
        if (newQuantity < minimumQuantity) {
            setQuantityError(`Minimum quantity allowed is ${minimumQuantity}`);
        } else if (newQuantity > maximumQuantity) {
            setQuantityError(`Maximum quantity allowed is ${maximumQuantity}`);
        } else {
            setQuantityError(''); // Clear error when quantity is within valid range
        }

        // Only make the API call if the quantity is within the valid range
        if (newQuantity >= minimumQuantity && newQuantity <= maximumQuantity) {
            const payload = {
                product: dataa?._id,
                quantity: newQuantity,
                field: dataa?.fieldType?.map((eachItem) => ({
                    fieldType: eachItem?.name?._id,
                    option: selectedOptions[eachItem?.name?._id] || null,
                })),
            };

            try {
                setLoadings(true)
                const response = await axios.post(getCartRate, payload);
                setQuantityRate(response?.data?.result);
            } catch (error) {
                console.error('Error fetching rate', error);
            }
            finally {
                setLoadings(false)
            }
        }
    };

    const handleQuantityInput = (event) => {
        const inputValue = event.target.value;
        const numericValue = parseInt(inputValue, 10);

        // Prevent input if the value is below minimum or above maximum
        if (numericValue < minimumQuantity || numericValue > maximumQuantity) {
            // If the value is outside the range, prevent updating
            event.preventDefault();
        }
    };

    const handleKeyDown = (event) => {
        const key = event.key;

        // Prevent non-numeric keys except for Backspace, Delete, etc.
        if (
            (key < '0' || key > '9') && // Not a number
            key !== 'Backspace' && // Allow Backspace
            key !== 'Delete' && // Allow Delete
            key !== 'ArrowLeft' && // Allow Left Arrow
            key !== 'ArrowRight' // Allow Right Arrow
        ) {
            event.preventDefault(); // Prevent typing other characters
        }
    };

    useEffect(() => {
        if (dataa?.fieldType.length > 0) {
            if (Object.keys(selectedOptions).length === 0) {
                const initialOptions = {};
                dataa?.fieldType?.forEach((eachItem) => {
                    const fieldType = eachItem?.name?._id;
                    const firstOption = eachItem?.options[0]?._id;
                    initialOptions[fieldType] = firstOption;
                });

                setSelectedOptions(initialOptions);
            }
        }
    }, [dataa, selectedOptions]);

    const isLogin = localStorage.getItem('role')

    // const checkUserLogin = () => {

    //     inputRef?.current?.click();

    // };

    // const navToCustomize = () => {
    //     navigate('/products/customizeProduct')
    // }


    // const handleProfileUpload = async (e) => {
    //     if (e.target.files) {
    //         try {
    //             setLoader(true)
    //             const formData = new FormData();
    //             const uploadedFiles = [];

    //             for (let i = 0; i < e.target.files.length; i++) {
    //                 formData.append('files', e.target.files[i]);
    //             }

    //             const uploadData = await multiImageUpload(formData);

    //             if (uploadData && uploadData.result && uploadData.result.length) {
    //                 uploadData.result.forEach((file) => {
    //                     uploadedFiles.push({
    //                         name: file.originalname,
    //                         url: file.location,
    //                     });
    //                 });

    //                 setProfileImages([...profileImages, ...uploadedFiles]);
    //                 setIsImageUploaded(true);
    //                 setModal1(false)
    //             }
    //         } catch (error) {
    //             toast.error('Something went wrong...');
    //         } finally {
    //             setLoader(false)

    //         }
    //     }
    // };

    // const handleProfileUpload = async (e) => {
    //     if (e.target.files) {
    //         try {
    //             setLoader(true);
    //             const uploadedFiles = [];

    //             const files = Array.from(e.target.files);
    //             const formData = new FormData();

    //             // Optionally compress or process images here before uploading
    //             files.forEach(file => {
    //                 formData.append('files', file);
    //             });

    //             // Uploading the files in parallel
    //             const uploadData = await multiImageUpload(formData);

    //             if (uploadData && uploadData.result && uploadData.result.length) {
    //                 uploadData.result.forEach((file) => {
    //                     uploadedFiles.push({
    //                         name: file.originalname,
    //                         url: file.location,
    //                     });
    //                 });

    //                 setProfileImages(prevImages => [...prevImages, ...uploadedFiles]);
    //                 setIsImageUploaded(true);
    //                 setModal1(false);
    //             }
    //         } catch (error) {
    //             toast.error('Something went wrong...');
    //         } finally {
    //             setLoader(false);
    //         }
    //     }
    // };
    const handleProfileUpload = async (e) => {
        if (e.target.files) {
            const maxFileSizeMB = 5; // Maximum file size in MB
            const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024; // Convert MB to bytes

            try {
                setLoader(true);
                const uploadedFiles = [];

                const files = Array.from(e.target.files);
                const oversizedFiles = files.filter(file => file.size > maxFileSizeBytes);

                // Check if any file exceeds the allowed size
                if (oversizedFiles.length > 0) {
                    const oversizedFileNames = oversizedFiles.map(file => file.name).join(', ');
                    toast.error(`The following files are too large (maximum ${maxFileSizeMB} MB): ${oversizedFileNames}`);
                    return; // Exit the function without processing oversized files
                }

                const formData = new FormData();

                // Append valid files to the form data
                files.forEach(file => {
                    formData.append('files', file);
                });

                // Uploading the files in parallel
                const uploadData = await multiImageUpload(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    uploadData.result.forEach((file) => {
                        uploadedFiles.push({
                            name: file.originalname,
                            url: file.location,
                        });
                    });

                    setProfileImages(prevImages => [...prevImages, ...uploadedFiles]);
                    // setIsImageUploaded(true);
                    setModal1(false);
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoader(false);
            }
        }
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        // setSelectedImageIndex(null);
    };



    const token = localStorage.getItem('token')

    const toggleUpload = () => {
        if (token) {
            toggle1()
        } else {
            toggleEmail()
        }
    }

    const additionalData = useCallback(async () => {
        try {
            setLoading(true);
            if (token) {
                const response = await axios.get(`${productOG}/${productName}`)
                setData(response?.data?.result)
                if (response?.data?.result?.comboTees) {
                    setIsMultiSize(false)
                }
            } else {
                const response = await axios.get(`${product}/${productName}`)
                setData(response?.data?.result)
                if (response?.data?.result?.comboTees) {
                    setIsMultiSize(false)
                }
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }, [productName, token])

    // profile get
    const getProfile = async () => {
        try {
            const response = await axios.get(profile)
            setInputValue(response?.data?.result?.pin_code)
            checkPincode(response?.data?.result?.pin_code)
        } catch (error) {
            console.log(error.response);

        }
    }

    useEffect(() => {
        additionalData();
    }, [location.pathname, additionalData]);
    useEffect(() => {
        getProfile();
    }, [location.pathname, dataa]);


    const rating = useCallback(async () => {
        try {
            const response = await axios.get(`${ratingReview}?product_id=${dataa?._id}`)
            setReview(response?.data)
        } catch (error) {

        }
    }, [dataa?._id])
    useEffect(() => {
        rating()
    }, [dataa?._id, rating])


    const additionalData1 = useCallback(async () => {
        try {
            setLoading(true);
            let url = product;
            const response = await axios.get(`${url}?productCategory=${dataa?.productCategory?._id ? dataa?.productCategory?._id : ''}`);
            setData1(response?.data?.result);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }, [dataa?.productCategory?._id])
    useEffect(() => {
        additionalData1();
    }, [additionalData1]);


    const quantityData = useCallback(async () => {
        try {
            const response = await axios.get(`${quantityCountApi}/${productName}`);
            setQauntityData(response?.data?.result);

            const min = dataa?.quantityType === "dropdown" ? (response?.data?.result?.[0]?.to ?? 1) : (response?.data?.result?.[0]?.from ?? 1);
            const lastIndex = response?.data?.result?.length - 1;
            const max = response?.data?.result?.[lastIndex]?.to;

            setMinimumQuantity(min);
            setMaximumQuantity(max);
            setQuantity(min);
        } catch (error) {
        }
    }, [dataa?.quantityType, productName])

    useEffect(() => {
        quantityData(dataa?._id)
    }, [quantityData, dataa?._id])

    // const formSchema = yup.object().shape({

    //     image: yup.string().required('Please Upload Your Design'),
    // })
    // const {
    //     reset,
    //     // control,
    //     // handleSubmit,
    //     formState: { }
    // } = useForm({ mode: 'onChange' });

    // const handleRest = () => {
    //     reset({

    //     })
    // }
    const likeProduct = async (id) => {
        if (!localStorage.getItem('token')) {
            toggle();
        }

        try {
            const response = await axios.post(wishList, { product: id });
            toast.success(response?.data?.msg);
            wishlist()
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    // const likeProduct1 = async (id) => {
    //     if (!id) {
    //         console.error(id, "Invalid product ID");
    //         return;
    //     } else {
    //     }

    //     if (!localStorage.getItem('token')) {
    //         toggle();
    //     }

    //     try {
    //         const response = await axios.post(wishList, { product: id });
    //         toast.success(response?.data?.msg);
    //     } catch (error) {
    //         toast.error(error?.response?.data?.msg);
    //     }
    // };

    const submitForm = async () => {
        // Validate quantity
        // if (dataa?.quantityType !== 'dropdown' && quantity < minimumQuantity) {
        //     toast.error(`Minimum Quantity is ${minimumQuantity}`);
        //     return;
        // }

        // Construct payload
        if (isLogin) {
            const payload = {
                product: dataa?._id,
                quantity: quantity === 0 ? 1 : quantity,
                field: dataa?.fieldType?.map((eachItem) => ({
                    fieldType: eachItem?.name?._id,
                    option: selectedOptions[eachItem?.name?._id] || null,
                })),
                size: selectedSize,
                file: profileImages?.map((el) => el?.url),
                comboTees: dataa?.comboTees
            };
            const payload2 = {
                product: dataa?._id,
                field: dataa?.fieldType?.map((eachItem) => ({
                    fieldType: eachItem?.name?._id,
                    option: selectedOptions[eachItem?.name?._id] || null,
                })),
                sizeWithQuantity: sizeQuantities1,
                file: profileImages?.map((el) => el?.url),
            };

            const sendingPayload = isMultiSize ? payload2 : payload
            // Check login status

            if (isLogin === 'USER') {
                try {
                    const response = await axios.post(addCart, sendingPayload);
                    toast.success(response?.data?.msg);

                    // Clean up local storage
                    localStorage.removeItem('AddCart');
                    localStorage.removeItem('selectedOptions');
                    localStorage.removeItem('quantity');

                    // Navigate to cart page
                    navigate('/myCart');
                } catch (error) {
                    const errorMsg = error?.response?.data?.msg || 'Failed to add to cart';
                    setErrorMessage(errorMsg);
                }
            } else {
                const errorMsg = 'You are not sigin as a User';
                setErrorMessage(errorMsg);
            }
        } else {
            toggleEmail(); // Trigger login or alternate flow
        }
    };

    // const submitForm = async () => {
    //     // Validate quantity
    //     if (dataa?.quantityType !== 'dropdown' && quantity < minimumQuantity) {
    //         toast.error(`Minimum Quantity is ${minimumQuantity}`);
    //         return;
    //     }

    //     // Check login status
    //     if (!isLogin) {
    //         toggleEmail(); // Trigger login or alternate flow
    //         return;
    //     }

    //     // Construct payload
    //     const payload = {
    //         product: dataa?._id,
    //         quantity: quantity === 0 ? 1 : quantity,
    //         field: dataa?.fieldType?.map((eachItem) => ({
    //             fieldType: eachItem?.name?._id,
    //             option: selectedOptions[eachItem?.name?._id] || null,
    //         })),
    //         size: selectedSize,
    //         file: profileImages?.map((el) => el?.url),
    //     };

    //     if (isLogin === 'USER') {
    //         try {
    //             const response = await axios.post(addCart, payload);
    //             toast.success(response?.data?.msg);

    //             // Clean up local storage
    //             localStorage.removeItem('AddCart');
    //             localStorage.removeItem('selectedOptions');
    //             localStorage.removeItem('quantity');

    //             // Navigate to cart page
    //             navigate('/myCart');
    //         } catch (error) {
    //             const errorMsg = error?.response?.data?.msg || 'Failed to add to cart';
    //             setErrorMessage(errorMsg);
    //         }
    //     } else {
    //         const errorMsg = 'You are not signed in as a User';
    //         setErrorMessage(errorMsg);
    //     }
    // };


    // const addCartKey = localStorage.getItem('AddCart')


    const checkpincodeApi = token ? CheckPincode : CheckPincode1
    const checkPincode = async (pincode) => {
        setLoad(true);
        try {
            const response = await axios.get(
                `${checkpincodeApi}/${dataa?._id}?pincode=${pincode}&quantity=${quantity}`,
                {}
            );
            // Handle response
            setDeliveryCheck(response?.data?.result)
        } catch (error) {
            // toast.errsor(error?.response?.data?.msg || 'Error checking pincode');
        } finally {
            setLoad(false);
        }
    };
    // useEffect(() => {

    //     if (addCartKey == 'true') {
    //         autoAddCartFn()
    //     }

    // }, [addCartKey == 'true'])


    // const dataImg = [
    //     img1,
    //     img2,
    //     img3,
    // ]

    const responsiveCards = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const navigate = useNavigate()

    const navToProduct = (category_url) => {
        navigate(`/${dataa?.productCategory?.category_url}/all`)
    }

    const navToHome = () => {
        navigate(`/`)
    }

    // const navToProducts = () => {
    //     navigate('/products')
    // }

    // const [visibleImages, setVisibleImages] = useState(3);


    // const handleShowMore = () => {
    //     setVisibleImages(CardImg.length);
    // };

    const typeSpellings = {
        'general': 'General',
        'recemended': 'Recommended',
        'bulckprice': 'Bulk Price',
        'bestprice': 'Best Price'
    };

    const [inputValue, setInputValue] = useState('');
    const [deliveryCheck, setDeliveryCheck] = useState('');
    const expectedDelivery = deliveryCheck?.expectedDelivery;
    const formattedDate = expectedDelivery
        ? new Date(expectedDelivery).toLocaleDateString('en-GB')
        : '';
    // const handleChange = (e) => {
    //     setInputValue(e.target.value);
    //     checkpincode()
    // };

    const handleChange = (e) => {
        const pincode = e.target.value;

        // Allow only numeric values and ensure the input is not more than 6 digits
        if (/^\d*$/.test(pincode) && pincode.length <= 6) {
            setInputValue(pincode);

            // Call checkPincode only when exactly 6 digits are entered
            if (pincode.length === 6) {
                checkPincode(pincode);
                setDeliveryCheck()  // API call only when length is 6
            }
        }
    };


    const truncateTitle = (title) => {
        return title.length > 70 ? `${title.slice(0, 70)}...` : title;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setLoader(true);
        const files = Array.from(event.dataTransfer.files);
        const newImages = files.map(file => ({
            url: URL.createObjectURL(file),
            file
        }));
        setProfileImages([...profileImages, ...newImages]);
        setLoader(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const [showAll, setShowAll] = useState(false);
    const [showAll2, setShowAll2] = useState(false);

    const handleToggleShowAll = () => {
        setShowAll(!showAll);
    };
    const handleToggleShowAll2 = () => {
        setShowAll2(!showAll2);
    };

    const descriptions = dataa?.productOverview || [];
    const displayedDescriptions = showAll ? descriptions : descriptions.slice(0, 1.5);
    const descriptions1 = dataa?.description || [];
    const displayedDescriptions2 = showAll2 ? descriptions1 : descriptions1.slice(0, 1.5);
    const [activeTab, setActiveTab] = useState('description');

    const handleDescriptionClick = () => {
        setIsDescription(true);
        setIsFaq(false);
        setIsReview(false);
        setActiveTab('description');
    };

    const handleFaqClick = () => {
        setIsFaq(!isFaq);
        setIsDescription(false);
        setIsReview(false);
        setActiveTab('faq');
    };

    const handleReviewClick = () => {
        setIsReview(!isReview);
        setIsFaq(false);
        setIsDescription(false);
        setActiveTab('review');
    };

    const getTabStyle = (tab) => ({
        color: activeTab === tab ? '#E4510B' : '#000',
        borderBottom: activeTab === tab ? '2px solid #E4510B' : 'none',
        cursor: 'pointer'
    });

    // const navToViewPage = (product_url) => {
    //     navigate(`/${product_url}`);
    // };

    // const [showAll1, setShowAll1] = useState(false);

    const handleViewAll = () => {
        // setShowAll1(true);
        navToProduct()
    };

    const handleClick = () => {
        if (!isLogin) {
            // toast.error('Please login');
            toggleEmail();
        } else {
            navigate(`/products/productCustomize/${dataa?._id}`);
        }
    };

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 1000,
    //     slidesToShow: 3,
    //     centerMode: false,
    //     arrow: false,
    //     autoplay: true,
    //     responsive: [
    //         {
    //             breakpoint: 992,
    //             settings: {
    //                 arrows: false,
    //                 slidesToShow: 2,
    //             },
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 arrows: false,
    //                 slidesToShow: 1,
    //             },
    //         },
    //     ],

    // };

    // const sliderRef = useRef(null);

    // const nextSlide = () => {
    //     sliderRef.current.slickNext();
    // };

    // const prevSlide = () => {
    //     sliderRef.current.slickPrev();
    // };

    const reviews = review?.result || [];

    // Calculate total number of reviews
    const reviewCount = reviews.length;

    // Check if there are any reviews before calculating the average rating
    let averageRating = 0;
    if (reviewCount > 0) {
        averageRating = (reviews.reduce((acc, review) => acc + review.rating, 0) / reviewCount).toFixed(1);
    }

    // Generate stars based on the average rating
    const fullStars = Math.floor(averageRating); // Full stars based on average rating
    const halfStar = averageRating - fullStars >= 0.5; // Check if we need a half star
    const totalStars = 5; // Total number of stars


    // const fullStars1 = Math.floor(review?.rating || 0);  // Safely access rating, default to 0
    // const totalStars1 = 5;  // Assuming a 5-star rating system
    // const emptyStars = totalStars1 - fullStars1;


    const getInitials = (name) => {
        if (!name) return '';

        const words = name.trim().split(' '); // Split the name into words
        if (words.length === 1) {
            // If there is only one word, return the first letter
            return words[0][0].toUpperCase();
        } else {
            // If there are multiple words, return the first letters of the first two words
            return (words[0][0] + words[1][0]).toUpperCase();
        }
    };

    const getColorFromName = (name) => {
        if (!name) return '#808080'; // Default gray if no name

        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash += name.charCodeAt(i);
        }

        // Generate a color based on the hash
        const hue = hash % 360; // Get a hue value from 0 to 360
        return `hsl(${hue}, 70%, 50%)`; // Return HSL color
    };

    const handleColorSelect = useCallback((colorIndex) => {
        const selectedColorImages = dataa?.customizeImages[colorIndex]?.images;
        if (selectedColorImages && selectedColorImages.length > 0) {
            // setMainImage(selectedColorImages[0].image);
            setCardImgName(selectedColorImages.map(image => image.image));
            // setSelectedImageIndex1(0);
            setSelectedColorIndex(colorIndex); // Update the selected color index
        }
    }, [dataa])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const color = queryParams.get('color');
        if (color) {
            handleColorSelect(parseInt(color, 10)); // Convert the color value to a number
        }
    }, [location.search, handleColorSelect])

    const defaultPincode = localStorage.getItem('pincode')
    console.log(defaultPincode);

    const productSize = [
        ...(dataa?.customizeImageSize ?? [])
    ]

    const comboAmount = dataa?.amount * quantity

    return (
        <>
            {/* <HtmlHeaderElements
                title={dataa?.metaTitle}
                description={dataa?.metaDescription}
                keywords={dataa?.metaKeywords}
            /> */}
            <Navbar wishlistProps={wishlistData} />
            {loading ? (
                <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner
                        color="primary"
                        size=""
                    >
                        Loading...
                    </Spinner>
                </div>
            ) : (
                <div className='pt-3 pb-5' style={{ paddingLeft: '5%', paddingRight: '5%' }}>

                    <p className='pt-lg-3' style={{ cursor: 'pointer', color: '#333333', opacity: '0.8' }}><span onClick={navToHome}>Home</span> / <span onClick={navToProduct}>{dataa?.productCategory?.name}</span > / <span style={{ color: '#E4510B' }}>{dataa?.name}</span> </p>
                    <div className='d-lg-none mb-3'>
                        <div>
                            <h1 className=' text-center fs-4 mb-1 mt-3 mt-lg-0'>{dataa?.name && dataa?.name?.length > 35 ? `${dataa?.name?.substring(0, 40)}...` : dataa?.name}</h1>
                        </div>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <div className=''>
                                {/* Render full stars */}
                                {[...Array(fullStars)].map((_, index) => (
                                    <img key={index} src={star} alt={`star${index + 1}`} className='ms-1' />
                                ))}

                                {/* Render half star if necessary */}
                                {halfStar && <img src={star5} alt="half-star" className='ms-1' />}

                                {/* Render remaining empty stars */}
                                {[...Array(totalStars - fullStars - (halfStar ? 1 : 0))].map((_, index) => (
                                    <img key={index} src={emptyStarIcon} alt={`empty-star${index + 1}`} className='ms-1' />
                                ))}

                                {/* Display the number of customer reviews */}
                                <span
                                    style={{
                                        color: '#858585',
                                        font: 'Lato',
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        marginLeft: '7px'
                                    }}
                                >
                                    {reviewCount} reviews
                                </span>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col sm={12} lg={6}>
                            {dataa?.customizeProduct && dataa?.customizeImages?.length > 1 ? (
                                <Card style={{ border: 'none', width: '100%', height: 'auto' }} className='mb-3'>
                                    <ImageSlider images={CardImg1} />
                                </Card>
                            ) : (
                                <Card style={{ border: 'none', width: '100%', height: 'auto' }} className='mb-3'>
                                    <ImageSlider images={CardImg} />
                                </Card>
                            )}
                            {/* <Card style={{ border: 'none', width: '100%', height: 'auto' }} className='mb-3'>

                                <ImageSlider images={CardImg} />

                            </Card> */}
                            {/* <div className='d-none d-lg-block'>
                                <p className='pt-0' style={{ cursor: 'pointer' }}>
                                    <span>{dataa?.fullDescription}</span>
                                </p>
                            </div> */}
                            {/* <div >
                                <Accordion open={open} toggle={toggleAccordion}>
                                    <Row>
                                        {dataa?.description?.map((detail, i) => (
                                            <div key={detail?.id}>
                                                <AccordionItem>
                                                    <AccordionHeader targetId={i}><h5>{truncateTitle(detail?.title)}</h5></AccordionHeader>
                                                    <AccordionBody accordionId={i}>
                                                        {detail?.description}
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </div>
                                        ))}
                                    </Row>
                                </Accordion>
                            </div> */}
                        </Col>

                        <Col sm={12} lg={6}>
                            <Card style={{ border: 'none' }}>
                                <div style={{ justifyContent: 'space-between' }} className='d-flex'>
                                    <div className='d-none d-lg-block'>
                                        <div>
                                            <h1 style={{ fontSize: '32px', fontWeight: '400', font: 'Lato' }} className='mb-2 mt-3 mt-lg-0'>{dataa?.name && dataa?.name?.length > 30 ? `${dataa?.name?.substring(0, 30)}...` : dataa?.name}</h1>
                                        </div>
                                        <div className='mt-sm-3 mt-lg-0'>
                                            {/* Render full stars */}
                                            {[...Array(fullStars)].map((_, index) => (
                                                <img key={index} src={star} alt={`star${index + 1}`} className='ms-1' />
                                            ))}

                                            {/* Render half star if necessary */}
                                            {halfStar && <img src={star5} alt="half-star" className='ms-1' />}

                                            {/* Render remaining empty stars */}
                                            {[...Array(totalStars - fullStars - (halfStar ? 1 : 0))].map((_, index) => (
                                                <img key={index} src={emptyStarIcon} alt={`empty-star${index + 1}`} className='ms-1' />
                                            ))}

                                            {/* Display the number of customer reviews */}
                                            <span
                                                style={{
                                                    color: '#858585',
                                                    font: 'Lato',
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    lineHeight: '20px',
                                                    marginLeft: '7px'
                                                }}
                                            >
                                                ({reviewCount} customer reviews)
                                            </span>
                                        </div>
                                    </div>
                                    <div className='d-none d-lg-block'>
                                        {isLogin === 'ADMIN' ? (
                                            <div className='ms-3 cursor-pointer'>
                                                <a style={{ textDecoration: 'none', color: 'inherit' }} rel="noreferrer" target='_blank' href={`/admin/editProduct/${dataa?._id}`}>
                                                    <Edit3 />
                                                </a>
                                            </div>
                                        ) : (
                                            <div className='ms-3' style={{ width: '50px', height: '50px' }}>
                                                {
                                                    isLogin ?
                                                        <div className="con-like">
                                                            <input
                                                                className="like"
                                                                defaultChecked={dataa?.wishList}
                                                                onClick={() => likeProduct(dataa?._id)}
                                                                type="checkbox"
                                                                title="like"
                                                            />
                                                            <div className="checkmark">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="outline" viewBox="0 0 24 24">
                                                                    <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="filled" viewBox="0 0 24 24">
                                                                    <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="100" width="100" className="celebrate">
                                                                    <polygon className="poly" points="10,10 20,20"></polygon>
                                                                    <polygon className="poly" points="10,50 20,50"></polygon>
                                                                    <polygon className="poly" points="20,80 30,70"></polygon>
                                                                    <polygon className="poly" points="90,10 80,20"></polygon>
                                                                    <polygon className="poly" points="90,50 80,50"></polygon>
                                                                    <polygon className="poly" points="80,80 70,70"></polygon>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='heartEmoji ms-1 mt-1 cursor-pointer' onClick={toggleEmail}>
                                                            <img alt='heart' src={heart} height={40} width={40} />
                                                        </div>

                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* <div className='d-none d-lg-block mt-3'>
                                    {dataa?.productOverview?.map((eachItem, i) => (
                                        <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#858585' }} className='pt-0'><span style={{ color: '#E4510B' }}><GoDotFill /></span> {eachItem?.description}</p>
                                    ))}
                                </div> */}
                                <div className='d-none d-lg-block mt-2'>
                                    {displayedDescriptions.map((eachItem, i) => (
                                        <div key={i} style={{ display: 'flex', alignItems: 'start' }}>
                                            <span style={{ color: '#E4510B', marginRight: '8px' }}><GoDotFill /></span>
                                            <p style={{ fontFamily: 'Lato', fontWeight: '400', fontSize: '16px', color: '#858585', margin: 0 }} className='pt-0'>
                                                {eachItem?.description}
                                            </p>
                                        </div>
                                    ))}
                                    {descriptions.length > 2 && (
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                            <button style={{ border: 'none', background: 'none', textDecoration: 'underline', color: '#E4510B', fontsize: '14px' }} onClick={handleToggleShowAll}>
                                                {showAll ? 'See Less' : 'See More'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='d-none mt-2'>
                                    {displayedDescriptions.map((eachItem, i) => (
                                        <p key={i} style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#858585' }} className='pt-0'>
                                            <span style={{ color: '#E4510B' }}><GoDotFill /></span> {eachItem?.description}
                                        </p>
                                    ))}
                                    {descriptions.length > 2 && (
                                        <button style={{ border: 'none', background: 'none', textDecoration: 'underline', color: '#E4510B', fontsize: '14px' }} onClick={handleToggleShowAll}>
                                            {showAll ? 'See Less' : 'See More'}
                                        </button>
                                    )}
                                </div>
                                <Col sm={12}>
                                    <div className='d-none d-lg-block'><img style={{ width: '100%', height: '1px' }} src={line} alt="line" /></div>
                                </Col>
                                <div>
                                    <Row>
                                        {dataa?.customizeImages?.length > 1 ? (
                                            <Col lg={6} className='d-lg-none'>
                                                <div>
                                                    <Col className='w-100 position-relative mb-3' sm={8}>
                                                        <div className='d-flex justify-content-center gap-2 mt-2'>
                                                            {dataa?.customizeImages?.map((color, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={`${index === selectedColorIndex ? 'clr-con-active' : 'clr-con'}`}
                                                                    onClick={() => handleColorSelect(index)}
                                                                >
                                                                    <div
                                                                        style={{ backgroundColor: color.colourCode, width: '100%', height: '100%', borderRadius: '50%' }}></div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                        ) : (null)}
                                        {dataa?.customizeImages?.length > 0 ? (
                                            <div className='mt-2 mb-2 button d-lg-none' style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center', textAlign: "end", gap: '8px' }}>
                                                <button style={{ width: '320px', height: '50px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: '#E4510B', background: 'transparant', border: '1px solid #E4510B' }} className='btn' onClick={handleClick} > <span className='me-2'><BiEditAlt size={25} /></span> Create Your Design</button>
                                                {dataa?.template && (<button onClick={() => navigate(`/browseDesign/${productName}?category=${dataa?.productCategory?.category_url}`)} style={{ width: '320px', height: '50px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: 'white', background: '#E4510B', border: '1px solid #E4510B' }} className='btn'> <span className='me-2'><IoShapesOutline size={25} /></span> Browse Designs</button>)}
                                            </div>
                                        ) : (null)}
                                        {dataa?.fieldType?.map((eachItem, i) => (
                                            <Col sm={12} lg={6}>
                                                <React.Fragment key={i}>
                                                    <div className='d-lg-block'>
                                                        <Col sm={8}>
                                                            <h4 className='m-0 mt-2 mb-2 mb-lg-mb-0' style={{ fontWeight: '400', fontSize: '18px', font: 'Lato', color: '#262525' }}>
                                                                {eachItem?.name?.name}
                                                            </h4>
                                                        </Col>
                                                        <Col sm={8} className="position-relative w-100 mb-2 mb-lg-2">
                                                            <div className="position-relative">
                                                                {/* Select element */}
                                                                <select
                                                                    name={eachItem?.name?._id}
                                                                    className="form-control mt-md-3 pr-6"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        appearance: 'none', // Hides the default arrow
                                                                        paddingRight: '2rem', // Space for the Chevron icon
                                                                    }}
                                                                    onChange={(e) =>
                                                                        handleOptionChange(eachItem?.name?._id, e.target.value)
                                                                    }
                                                                >
                                                                    {eachItem?.options.map((each, k) => (
                                                                        <option key={k} value={each?._id}>
                                                                            {each?.name}
                                                                        </option>
                                                                    ))}
                                                                </select>

                                                                {/* ChevronDown icon */}
                                                                <i
                                                                    className="bi bi-caret-down dropicon position-absolute translate-middle-y"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        right: '10px',
                                                                        top: '50%',
                                                                        transform: 'translateY(-50%)',
                                                                        fontSize: '1rem',
                                                                        pointerEvents: 'none', // Prevent interference with dropdown click
                                                                    }}
                                                                >
                                                                    <ChevronDown />
                                                                </i>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </React.Fragment>
                                            </Col>
                                        ))}

                                        {!dataa?.comboTees && (
                                            <>

                                                {!isMultiSize && (
                                                    <>
                                                        {dataa?.customizeImageSize?.length > 0 && (
                                                            <Col lg={6}>
                                                                <div>
                                                                    <Col className='d-flex' sm={4} lg={6}>
                                                                        <h4 className='m-0 mt-2' style={{ fontWeight: '400', fontSize: '18px', fontFamily: 'Lato', color: '#262525' }}>Size </h4>
                                                                        {dataa?.sizeChartInch?.length > 1 && <button style={{ fontsize: '10px' }} onClick={openCouponCodeModal} className='coupon ms-2 mt-1'>Size Chart</button>}
                                                                    </Col>
                                                                    <Col className='w-100 position-relative' sm={8}>
                                                                        <div>
                                                                            <select
                                                                                name='quantityCounter'
                                                                                className='form-control mt-1 mt-md-3'
                                                                                onChange={handleSizeChange}
                                                                                value={selectedSize}
                                                                            >
                                                                                {dataa?.customizeImageSize?.map((size, index) => (
                                                                                    <option key={index} value={size}>
                                                                                        {size}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            <i className="dropicon bi bi-caret-down position-absolute translate-middle-y">
                                                                                <ChevronDown />
                                                                            </i>
                                                                        </div>
                                                                    </Col>
                                                                </div>




                                                                {/* ) : (
                                                    <div>
                                                        <Col sm={8}>
                                                            <h4 className='m-0 mt-2 mt-lg-2' style={{ fontWeight: '400', fontSize: '18px', fontFamily: 'Lato', color: '#262525' }}>Available Sizes</h4>
                                                        </Col>
                                                        <Col lg={6} className='mt-3 w-100 position-relative' sm={8}>
                                                            <div className='d-flex flex-wrap'>
                                                                {dataa?.customizeImageSize?.map((size, index) => (
                                                                    <div className='d-flex' key={index}>
                                                                        <p className={`ms-3 size d-flex justify-content-center align-items-center`}>
                                                                            {size}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Col>
                                                    </div> */}
                                                                {/* )} */}
                                                                {/* <div>
                                                    <Col sm={4}>
                                                        <h4 className='m-0 mt-2' style={{ fontWeight: '400', fontSize: '18px', fontFamily: 'Lato', color: '#262525' }}>Size</h4>
                                                    </Col>
                                                    <Col className='w-100 position-relative' sm={8}>
                                                        <div>
                                                            <select
                                                                name='quantityCounter'
                                                                className='form-control mt-1 mt-md-3'
                                                                onChange={handleSizeChange}
                                                                value={selectedSize}
                                                            >
                                                                {dataa?.customizeImageSize?.map((size, index) => (
                                                                    <option key={index} value={size}>
                                                                        {size}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <i className="dropicon bi bi-caret-down position-absolute translate-middle-y">
                                                                <ChevronDown />
                                                            </i>
                                                        </div>
                                                    </Col>
                                                </div> */}
                                                            </Col>
                                                        )}
                                                    </>
                                                )}

                                            </>
                                        )}
                                        {!isMultiSize && (
                                            <Col lg={6}>
                                                <div>
                                                    <Col sm={4}>
                                                        <h4 className='m-0 mt-2 mb-2 mb-lg-0' style={{ fontWeight: '400', fontSize: '18px', font: 'Lato', color: '#262525' }}>Quantity</h4>
                                                    </Col>
                                                    <Col className='w-100 mb-3' sm={8}>
                                                        <div>

                                                            {dataa?.quantityType === 'dropdown' ? (
                                                                <select
                                                                    name='quantityCounter'
                                                                    className='form-control mt-1 mt-md-3'
                                                                    onChange={handleQuantityChange}
                                                                >

                                                                    {quantityCount?.map((eachQuantity, i) => (
                                                                        <option key={i} value={eachQuantity?.to}>
                                                                            {eachQuantity?.to}
                                                                            {eachQuantity?.type !== 'general' && (
                                                                                <span className='ms-4'> ({typeSpellings[eachQuantity?.type] || eachQuantity?.type})</span>
                                                                            )}
                                                                        </option>
                                                                    ))}

                                                                </select>
                                                            ) : (
                                                                <div>
                                                                    <input
                                                                        type="number"
                                                                        name="quantity"
                                                                        style={{ border: '1px solid #D9D9D9' }}
                                                                        className="form-control mt-1 mt-md-3"
                                                                        value={quantity}
                                                                        onChange={handleQuantityChange}
                                                                        pattern="[0-9]+"
                                                                        min={minimumQuantity}
                                                                        max={maximumQuantity}
                                                                        onInput={handleQuantityInput}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                    {quantityError && <span className="text-danger">{quantityError}</span>}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                        )}

                                        {isMultiSize && (
                                            <Col sm={12}>
                                                <div className='overflow-auto hide-scrollbar'>
                                                    <div className='d-flex mt-2 mb-3'>
                                                        {productSize?.map((size) => (
                                                            <div className="d-block mt-2 mb-2" key={size}>
                                                                <p
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={`ms-3 me-3 size d-flex justify-content-center align-items-center ${true ? 'selected-size' : ''
                                                                        }`}
                                                                // onClick={() => handleSizeClick1(size)}
                                                                >
                                                                    {size}
                                                                </p>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        value={!sizeQuantities.length > 0 ? (sizeQuantities1.find(item => item.size === size)?.quantity || '') : ''}
                                                                        // readOnly={sizeQuantities.length > 0}
                                                                        onChange={(e) => handleManualQuantityChange(size, e.target.value)}
                                                                        style={{ width: '50px', height: '50px', marginLeft: '10px', borderRadius: '8px', textAlign: 'center' }}
                                                                        placeholder="Qty"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Col>
                                        )}


                                        {!dataa?.comboTees && (
                                            <Col sm={12}>
                                                <FormGroup switch className='mb-3 d-flex align-items-center gap-1'>
                                                    <Input
                                                        style={{ width: '45px', height: '25px' }}
                                                        type="switch"
                                                        checked={isMultiSize}
                                                        onClick={() => {
                                                            setIsMultiSize(!isMultiSize);
                                                        }}
                                                        className="custom-switch"
                                                    />
                                                    <h4 className='m-0 p-0 mt-1 mb-lg-0' style={{ fontWeight: '400', fontSize: '18px', font: 'Lato', color: '#262525' }}>
                                                        Choose multiple sizes and quantities?
                                                    </h4>
                                                </FormGroup>
                                            </Col>
                                        )}
                                        {dataa?.customizeImages?.length > 1 ? (
                                            <Col lg={6} className='d-none d-lg-block'>
                                                <div>
                                                    <Col sm={4}>
                                                        <h4 className='m-0 mt-2' style={{ fontWeight: '400', fontSize: '18px', fontFamily: 'Lato', color: '#262525' }}>Color</h4>
                                                    </Col>
                                                    <Col className='w-100 position-relative' sm={8}>
                                                        <div className='d-flex gap-2 flex-wrap mt-3'>
                                                            {dataa?.customizeImages?.map((color, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={`${index === selectedColorIndex ? 'clr-con-active' : 'clr-con'}`}
                                                                    onClick={() => handleColorSelect(index)}
                                                                    style={{
                                                                        width: '30px', // Fixed width for the color circle
                                                                        height: '30px', // Fixed height for the color circle
                                                                        margin: '2px'  // Space between the color circles
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: color.colourCode,
                                                                            width: '100%',
                                                                            height: '100%',
                                                                            borderRadius: '50%',
                                                                        }}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Col>
                                        ) : (null)}

                                        {/* <Col sm={12} md={6}>
                                            <div className='ms-2'>
                                                <Controller
                                                    name='image'
                                                    id='image'
                                                    control={control}
                                                    defaultValue=''
                                                    render={({ field }) => (<div className="d-flex" style={{ cursor: 'pointer' }}>
                                                        <input style={{ display: 'none' }} id="image" type="file" {...field} invalid={errors.image && true} ref={inputRef} onChange={handleProfileUpload} />
                                                        {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                                    </div>)} />
                                            </div>
                                        </Col> */}
                                    </Row>
                                </div>
                                <Row>
                                    <div className='d-lg-flex d-md-flex d-sm-block'>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className='d-block'>
                                                {loadings ?
                                                    (
                                                        <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Spinner
                                                                color="primary"
                                                                size=""
                                                            >
                                                                Loading...
                                                            </Spinner>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div>
                                                            {!dataa?.comboTees && (
                                                                <>
                                                                    {totalAmount ? (
                                                                        <>
                                                                            <h5 className='price-head mt-3'>
                                                                                {dataa?.offer ? (
                                                                                    <>
                                                                                        <span className='text-muted' style={{ textDecoration: 'line-through' }}>{actualAmount?.toLocaleString('en-IN', {
                                                                                            maximumFractionDigits: 2,
                                                                                            style: 'currency',
                                                                                            currency: 'INR'
                                                                                        })}</span>
                                                                                        <span className='fw-bolder ms-1' style={{ color: 'green', fontSize: '22px' }}>{offerAmount?.toLocaleString('en-IN', {
                                                                                            maximumFractionDigits: 2,
                                                                                            style: 'currency',
                                                                                            currency: 'INR'
                                                                                        })}</span>
                                                                                    </>
                                                                                ) : (
                                                                                    <span className='fw-bolder' style={{ color: '#E4510B', fontSize: '22px', lineHeight: '21px' }}>{totalFinalAmount?.toLocaleString('en-IN', {
                                                                                        maximumFractionDigits: 2,
                                                                                        style: 'currency',
                                                                                        currency: 'INR'
                                                                                    })}</span>
                                                                                )}<br />
                                                                                <span style={{ fontWeight: '400', marginLeft: "1px", fontSize: '14px', color: '#464646' }}>{dataa?.gstType} of all taxes </span>
                                                                                <span style={{ fontWeight: '400', fontSize: '14px', color: '#464646' }}>
                                                                                    for <span className='fw-bold'>{isMultiSize ? totalQuantity : quantity}</span> Qty (<span className='fw-bold'>{amount?.toLocaleString('en-IN', {
                                                                                        maximumFractionDigits: 2,
                                                                                        style: 'currency',
                                                                                        currency: 'INR'
                                                                                    })}</span> / piece)
                                                                                </span>

                                                                                {quantityRate?.savings?.length > 0 && (
                                                                                    <>
                                                                                        <br />
                                                                                        <span style={{ fontSize: '20px' }} className=''>₹{quantityRate?.savings?.toLocaleString('en-IN', {
                                                                                            maximumFractionDigits: 2,
                                                                                            style: 'currency',
                                                                                            currency: 'INR'
                                                                                        })}</span>
                                                                                        <span style={{ fontSize: '16px', marginTop: '-10px' }} className='fw-normal'> - savings</span>
                                                                                    </>
                                                                                )}
                                                                            </h5>
                                                                            <div className="product-bulk">
                                                                                <div class="myDIV">
                                                                                    <p className='product-bulk-content'>
                                                                                        Buy in bulk and save
                                                                                        <span className="hover-icon ms-2">
                                                                                            <HiInformationCircle size={20} />
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div class="hide">
                                                                                    <p>Bulk Quantities of Product at Discounted Rates</p>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    ) : (
                                                                        null
                                                                    )}
                                                                </>
                                                            )}
                                                            {dataa?.comboTees && (
                                                                <div className='mb-3 mt-2'>
                                                                    <span className='fw-bolder' style={{ color: '#E4510B', fontSize: '22px', lineHeight: '21px' }}>{comboAmount.toLocaleString('en-IN', {
                                                                        maximumFractionDigits: 2,
                                                                        style: 'currency',
                                                                        currency: 'INR'
                                                                    })}</span><br />
                                                                    <span style={{ fontWeight: '400', marginLeft: "1px", fontSize: '14px', color: '#464646' }}>{dataa?.gstType} of all taxes </span>
                                                                    <span style={{ fontWeight: '400', fontSize: '14px', color: '#464646' }}>
                                                                        for <span className='fw-bold'>{quantity}</span> Qty (<span className='fw-bold'>{dataa?.amount?.toLocaleString('en-IN', {
                                                                            maximumFractionDigits: 2,
                                                                            style: 'currency',
                                                                            currency: 'INR'
                                                                        })}</span> / piece)
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                                {isLogin !== "ADMIN" &&
                                                    <>
                                                        <div className='d-block mb-3'>
                                                            <Col lg={12} md={12} sm={12}>
                                                                <div className='mt-1'>
                                                                    <div className='d-flex'><h4 style={{ fontWeight: '400', fontSize: '18px', font: 'Lato', color: '#262525' }}>Check Delivery</h4></div>
                                                                    <div className='mt-1 w-100'>
                                                                        <Input
                                                                            type='tel'
                                                                            value={inputValue}
                                                                            onChange={handleChange}
                                                                            style={{ width: '100%', color: '#858585' }}
                                                                            placeholder="Enter 6-digit Pincode"
                                                                            maxLength="6"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12} md={12} sm={12}>
                                                                {load ? (
                                                                    <Spinner className='mt-1'>loading...</Spinner>
                                                                ) : (
                                                                    inputValue?.length === 6 && (
                                                                        deliveryCheck?.deliveryCharge ? (
                                                                            <>
                                                                                <h6 className='mt-2'>
                                                                                    <TbTruckDelivery style={{ width: '30px', height: '30px' }} />
                                                                                    Standard Delivery :<br />
                                                                                    <span style={{ color: 'grey' }}>
                                                                                        Estimated delivery by {formattedDate}
                                                                                    </span>
                                                                                </h6>
                                                                            </>
                                                                        ) : (
                                                                            <p className="text-danger mt-2">Delivery not available for this location</p>
                                                                        )
                                                                    )
                                                                )}
                                                            </Col>
                                                        </div>
                                                        <div className='d-flex'>
                                                            {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }} >
                                                        {dataa?.upload ? (
                                                            <>
                                                                <button className='viewButton' disabled={!profileImages} onClick={submitForm}>Add to Cart <FiShoppingCart size={20} /></button>
                                                                <div>
                                                                    {!profileImages && <p className='mt-2' style={{ width: '250px', fontSize: '12px' }}><span className='text-danger'>*</span>Upload your Custom design to proceed!</p>}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <button className='viewButton' disabled={!profileImages} onClick={submitForm}>Add to Cart <FiShoppingCart /></button>
                                                        )}
                                                    </div> */}
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                                                <button
                                                                    className='viewButton'
                                                                    disabled={!deliveryCheck?.deliveryCharge} // Disable if delivery charge is not available
                                                                    onClick={submitForm} // Call the function directly
                                                                    style={{
                                                                        backgroundColor: (!deliveryCheck?.deliveryCharge) ? '#d3d3d3' : '#E4510B', // Gray when disabled, original color when enabled
                                                                        color: (!deliveryCheck?.deliveryCharge) ? '#888' : '#fff', // Adjust text color
                                                                        cursor: (!deliveryCheck?.deliveryCharge) ? 'not-allowed' : 'pointer', // Change cursor
                                                                    }}
                                                                >
                                                                    Add to Cart <FiShoppingCart size={20} />
                                                                </button>
                                                                {errorMessage && (
                                                                    <div style={{ color: 'red', marginTop: '8px', fontSize: '14px' }}>
                                                                        {errorMessage}
                                                                    </div>
                                                                )}
                                                                {/* <div>
                                                            {!profileImages && (
                                                                <p className='mt-2' style={{ width: '250px', fontSize: '12px' }}>
                                                                    <span className='text-danger'>*</span> Upload your Custom design to proceed!
                                                                </p>
                                                            )}
                                                        </div> */}
                                                            </div>


                                                            {/* <Link to='/myCart'>
                                                        <div className='ms-3' style={{ border: '1px solid #C8C7C7', width: '50px', height: '50px', borderRadius: '4px', cursor: 'pointer' }}>
                                                            <span style={{ color: '#515151', fontSize: '32px', display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '9px' }}><FiShoppingCart /></span>
                                                        </div>
                                                    </Link> */}
                                                            {/* <div className='ms-3' style={{ width: '50px', height: '50px', border: '1px solid #C8C7C7', borderRadius: '4px' }}>
                                                        {
                                                            isLogin ?
                                                                <div className="con-like">
                                                                    <input
                                                                        className="like"
                                                                        defaultChecked={dataa?.wishList}
                                                                        onClick={() => likeProduct(dataa?._id)}
                                                                        type="checkbox"
                                                                        title="like"
                                                                    />
                                                                    <div className="checkmark">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="outline" viewBox="0 0 24 24">
                                                                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"></path>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="filled" viewBox="0 0 24 24">
                                                                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"></path>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="100" width="100" className="celebrate">
                                                                            <polygon className="poly" points="10,10 20,20"></polygon>
                                                                            <polygon className="poly" points="10,50 20,50"></polygon>
                                                                            <polygon className="poly" points="20,80 30,70"></polygon>
                                                                            <polygon className="poly" points="90,10 80,20"></polygon>
                                                                            <polygon className="poly" points="90,50 80,50"></polygon>
                                                                            <polygon className="poly" points="80,80 70,70"></polygon>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='heartEmoji ms-1 mt-1 cursor-pointer' onClick={toggleEmail}>
                                                                    <img src={heart} height={40} width={40} />
                                                                </div>

                                                        }
                                                    </div> */}

                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <div className='d-block mt-3'>
                                                {profileImages.length === 0 ? (
                                                    <>
                                                        {dataa?.upload &&
                                                            <>
                                                                <div className=' button' /*style={{ display: 'flex', justifyContent: "end", textAlign: "end" }}*/>
                                                                    <button style={{ width: '320px', height: '50px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: 'white', background: '#E4510B', border: '1px solid #E4510B' }} className='btn' onClick={toggleUpload}> <span><FiUpload /></span> Upload Your Files</button>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                ) : (
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {profileImages.map((image, index) => (
                                                            <div style={{ padding: '5px' }} key={index} className="m-2">
                                                                <div className='d-block ms-1'>
                                                                    <div className='d-flex mt-1'>
                                                                        <img
                                                                            alt={`preview ${index + 1}`}
                                                                            src={image.url.endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : image.url}
                                                                            style={{
                                                                                width: '80px',
                                                                                height: '80px',
                                                                                border: '2px dashed #DFDFDF',
                                                                                borderRadius: '4px',
                                                                                cursor: 'pointer',
                                                                                position: 'relative'
                                                                            }}
                                                                        // onClick={() => setSelectedImageIndex(index)}
                                                                        />
                                                                        <div className="cursor-pointer image-close" style={{ cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                                                            <X style={{ width: '15px', height: '15px', backgroundColor: '#EEEEEE', borderRadius: '2px', position: 'absolute', marginLeft: '-18px', marginTop: '3px' }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div style={{ padding: '5px' }} className="m-2">
                                                            <div className=''>
                                                                <div style={{
                                                                    width: '80px',
                                                                    height: '80px',
                                                                    border: '2px dashed #DFDFDF',
                                                                    borderRadius: '4px',
                                                                    cursor: 'pointer',
                                                                    position: 'relative'
                                                                }}
                                                                    className='d-flex justify-content-center align-items-center mt-1'>

                                                                    <input
                                                                        style={{ display: 'none' }}
                                                                        type="file"
                                                                        ref={inputRef1}
                                                                        accept=".jpeg,.png,.jpg"
                                                                        onChange={handleProfileUpload}
                                                                        multiple
                                                                    />
                                                                    <IoIosAddCircle
                                                                        style={{
                                                                            width: '30px',
                                                                            height: '30px'
                                                                        }}
                                                                        onClick={() => inputRef1?.current?.click()}
                                                                    />
                                                                    {/* <img
                                                                        alt="upload"
                                                                        src={upload}
                                                                        style={{
                                                                            width: '30px',
                                                                            height: '30px'
                                                                        }}
                                                                        onClick={() => inputRef1?.current?.click()}
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {profileImages?.length > 0 ?
                                                    (
                                                        null
                                                    ) : (
                                                        <>
                                                            {/* <div style={{ width: '100%' }} className='d-flex align-items-center justify-content-center'>

                                                                <p
                                                                    className='m-0 mb-1 p-0'
                                                                    style={{
                                                                        color: '#333333D1',
                                                                        minWidth: 'fit-content',
                                                                        fontSize: '20px',
                                                                        fontWeight: '400',
                                                                    }}
                                                                >
                                                                    (or)
                                                                </p>

                                                            </div> */}
                                                            {dataa?.customizeImages?.length > 0 ? (
                                                                <div className='mt-2 button d-none d-lg-block' style={{ display: 'flex', flexDirection: 'column', justifyContent: "end", textAlign: "end", gap: '8px' }}>
                                                                    <button style={{ width: '320px', height: '50px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: '#E4510B', background: 'transparant', border: '1px solid #E4510B' }} className='btn' onClick={handleClick} > <span className='me-2'><BiEditAlt size={25} /></span> Create Your Design</button>
                                                                    {dataa?.template && (<button onClick={() => navigate(`/browseDesign/${productName}?category=${dataa?.productCategory?.category_url}`)} style={{ width: '320px', height: '50px', fontSize: "14px", fontWeight: '700', font: 'Lato', color: 'white', background: '#E4510B', border: '1px solid #E4510B' }} className='btn mt-2'> <span className='me-2'><IoShapesOutline size={25} /></span> Browse Designs</button>)}
                                                                </div>
                                                            ) : (null)}
                                                        </>
                                                    )}


                                            </div>
                                        </Col>
                                    </div>

                                </Row>
                            </Card >
                        </Col >
                    </Row >
                </div >
            )
            }
            <div className='des' style={{ paddingLeft: '5%', paddingRight: '5%', marginTop: '20px' }}>
                <div style={{ border: '1.5px solid #D5D5D5' }}>
                    <div style={{ borderBottom: '1.5px solid #DFDFDF' }} className='d-flex mt-3 mb-3 ms-3 me-3'>
                        <div style={getTabStyle('description')} onClick={handleDescriptionClick}>
                            <h5>Description</h5>
                        </div>
                        <div style={{ ...getTabStyle('faq'), marginLeft: '2rem' }} onClick={handleFaqClick}>
                            <h5>FAQ’s</h5>
                        </div>
                        <div style={{ ...getTabStyle('review'), marginLeft: '2rem' }} onClick={handleReviewClick}>
                            <h5>Review</h5>
                        </div>
                    </div>

                    {isDescription && (
                        <div className='ms-3 mb-3' style={{ marginTop: '20px' }}>
                            <div className='d-lg-block'>
                                {/* <p className='pt-0' style={{ cursor: 'pointer', color: '#858585' }}>
                                    <span>
                                        {isExpanded
                                            ? dataa?.fullDescription.split("\n").map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))
                                            : `${dataa?.fullDescription?.slice(0, 450)}...`}
                                    </span>
                                    <span onClick={toggleDescription} style={{ color: '#E4510B', marginLeft: '5px', textDecoration: 'underline' }}>
                                        {isExpanded ? 'See Less' : 'See More'}
                                    </span>
                                </p> */}
                                <p
                                    className="pt-0"
                                    style={{ cursor: 'pointer', color: '#858585' }}
                                >
                                    {/* Render either full or truncated description */}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: isExpanded
                                                ? he.decode(dataa?.fullDescription || "").replace(/\n/g, '<br />') // Fallback to empty string
                                                : `${he.decode(dataa?.fullDescription || "").slice(0, 450)}...`, // Fallback to empty string
                                        }}
                                    ></span>
                                    {/* Toggle button for description */}
                                    <span
                                        onClick={toggleDescription}
                                        style={{
                                            color: '#E4510B',
                                            marginLeft: '5px',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        {isExpanded ? 'See Less' : 'See More'}
                                    </span>
                                </p>
                            </div>
                            {dataa?.description ? (
                                <div className='d-none d-lg-block mt-2'>
                                    {displayedDescriptions2?.map((eachItem, i) => (
                                        <div key={i} style={{ display: 'flex', alignItems: 'start' }}>
                                            <span style={{ color: '#E4510B', marginRight: '8px' }}><GoDotFill /></span>
                                            <p style={{ fontFamily: 'Lato', fontWeight: '400', fontSize: '16px', color: '#858585', margin: 0 }} className='pt-0'>
                                                {eachItem?.description}
                                            </p>
                                        </div>
                                    ))}
                                    {descriptions1?.length > 2 && (
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                            <button style={{ border: 'none', background: 'none', textDecoration: 'underline', color: '#E4510B', fontsize: '14px' }} onClick={handleToggleShowAll2}>
                                                {showAll2 ? 'See Less' : 'See More'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (null)}
                        </div>
                    )}
                    {isReview && (
                        // <div className='ms-3 mb-3' style={{ marginTop: '20px' }}>
                        //     <div className='d-lg-block'>
                        //         <p className='pt-0' style={{ cursor: 'pointer' }}>
                        //             <span>There is no review for this product</span>
                        //         </p>
                        //     </div>
                        // </div>
                        // <div className='mt-5 position-relative'>
                        //     <Slider {...settings} className="custom-slider" ref={sliderRef}>

                        //     </Slider>
                        //     <div className="custom-nav-buttons mb-5 d-flex justify-content-end me-5">
                        //         <div onClick={prevSlide}>
                        //             <button style={{ background: '#FBD7C6', color: '#E4510B', border: 'none', borderRadius: '50%', width: '50px', height: '50px' }} className="prev-button"><FiArrowLeft className='d-flex align-items-center justify-content-center' size={35} /></button>
                        //         </div>
                        //         <div className='ms-4' onClick={nextSlide}>
                        //             <button style={{ background: '#FBD7C6', color: '#E4510B', border: 'none', borderRadius: '50%', width: '50px', height: '50px' }} className="next-button"><FiArrowRight className='d-flex align-items-center justify-content-center' size={35} /></button>
                        //         </div>
                        //     </div>
                        // </div>
                        <>
                            {review?.result?.length > 0 ? (
                                <>
                                    {review?.result?.map((eachDetail) => {
                                        // Calculate the full stars and empty stars
                                        const fullStars = Math.floor(eachDetail?.rating || 0);
                                        const totalStars = 5; // Assuming 5-star rating system
                                        const emptyStars = totalStars - fullStars;

                                        return (
                                            <div className='pt-5 pb-2' key={eachDetail._id}>
                                                <Card className='testmonialsCard mt-2'>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='d-flex'>
                                                            <div style={{
                                                                height: '40px',
                                                                width: '40px',
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                backgroundColor: getColorFromName(eachDetail?.user?.name), // Use the color function
                                                                color: 'white'
                                                            }}>
                                                                {getInitials(eachDetail?.user?.name)}
                                                            </div>

                                                            <div className='d-block ms-3'>
                                                                <h5 className='ms-2'><b>{eachDetail?.user?.name}</b></h5>
                                                                <p className='testimonialLocation ms-2 mt-1 d-flex'>
                                                                    <span color='#909090' className=''>
                                                                        {new Date(eachDetail.updatedAt).toLocaleDateString('en-GB', {
                                                                            day: '2-digit',
                                                                            month: 'long',
                                                                            year: 'numeric'
                                                                        })}
                                                                    </span>
                                                                </p>
                                                                <div className='testimonialRatings ms-2 mt-1' style={{ marginTop: '-15px' }}>
                                                                    {/* Render full stars */}
                                                                    {[...Array(fullStars)].map((_, index) => (
                                                                        <img
                                                                            key={index}
                                                                            src={star}
                                                                            size={15}
                                                                            alt={`full-star-${index + 1}`}
                                                                            className='ms-1 testimonialRatings1'
                                                                        />
                                                                    ))}

                                                                    {/* Render empty stars */}
                                                                    {[...Array(emptyStars)].map((_, index) => (
                                                                        <img
                                                                            key={index}
                                                                            src={emptyStarIcon}
                                                                            size={15}
                                                                            alt={`empty-star-${index + 1}`}
                                                                            className='ms-1 testimonialRatings1'
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='d-flex w-100'>
                                                        <img style={{ width: '20px', height: '20px' }} src={review1} alt="review attachment" />
                                                        <hr className='ms-3' style={{ width: '83%', height: '2px', backgroundColor: '#2B2B2B', border: 'none' }} />
                                                        <p className='ms-2' style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Manrope', color: '#37A003' }}>
                                                            <MdVerified style={{ color: '#37A003' }} /> Verified Purchase
                                                        </p>
                                                    </div>

                                                    <p className='testimonialDescription'>{eachDetail?.review}</p>
                                                    {eachDetail?.attachments?.length > 0 &&
                                                        <div>
                                                            <img style={{ width: '130px' }} src={eachDetail?.attachments || eachDetail?.attachments?.url} alt="reviewimage" />
                                                        </div>
                                                    }
                                                </Card>
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <p className='text-center'>This product currently has no reviews.</p>
                            )}
                        </>

                    )}
                    {isFaq && (
                        <div className='' style={{ marginTop: '20px', paddingLeft: '2%', paddingRight: '2%' }}>
                            <div className='mb-3'>
                                <Row>
                                    {dataa?.FAQ?.map((details, i) => (
                                        <Col lg={6} md={6} sm={12} key={details?.id} className="mt-4">
                                            <Accordion open={open} toggle={toggleAccordion}>
                                                <AccordionItem>
                                                    <AccordionHeader targetId={i}>
                                                        <h5>{truncateTitle(details?.title)}</h5>
                                                    </AccordionHeader>
                                                    <AccordionBody accordionId={i}>
                                                        {details?.description}
                                                    </AccordionBody>
                                                </AccordionItem>
                                            </Accordion>
                                        </Col>
                                    ))}
                                </Row>

                            </div>
                        </div>
                        // <Col>
                        //     <div style={{  }} >
                        //         {style.map((item) => (
                        //             <Accordion
                        //                 className='mt-4'
                        //                 expanded={expanded === item.id}
                        //                 onChange={handleChange1(item.id)}
                        //                 key={item.id}
                        //             >
                        //                 <AccordionSummary
                        //                     style={{ height: '80px', borderBottom: '1px solid #ddd' }}
                        //                     expandIcon={expanded === item.id ? <FaMinus /> : <FaPlus />}
                        //                     aria-controls={`${item.id}-content`}
                        //                     id={`${item.id}-header`}
                        //                 >
                        //                     <Typography>{item.question}</Typography>
                        //                 </AccordionSummary>
                        //                 <AccordionDetails style={{ padding: '0 10px', borderTop: '1px solid #ddd' }}>
                        //                     <div style={{ padding: '10px 0' }}>
                        //                         <Typography style={{ color: '#666666' }}>{item.answer}</Typography>
                        //                     </div>
                        //                 </AccordionDetails>
                        //             </Accordion>
                        //         ))}
                        //     </div>
                        // </Col>
                    )}
                </div >
            </div >
            <div style={{ paddingBottom: '10%' }} className='mt-5'>
                {
                    dataa1?.length > 1 ? (
                        <div style={{ paddingLeft: '5%', paddingRight: '5%', marginTop: '20px' }}>
                            <TitleComponent title={`Related Products`} />
                            {dataa1?.length === 0 ? (
                                <div>
                                    <h2 className="text-center pt-3 mb-5" style={{ color: "grey" }}>
                                        "Oops! It seems like we're out of stock in this category. <br />{" "}
                                        Explore other categories or visit us again soon."
                                    </h2>
                                </div>
                            ) : (
                                <div>
                                    <div className='mt-3'>
                                        <Carousel
                                            ref={carouselRef}
                                            responsive={responsiveCards}
                                            autoPlay={true}
                                            swipeable={true}
                                            draggable={true}
                                            showDots={false}
                                            arrows={false}
                                            autoPlaySpeed={2000}
                                            infinite={true}
                                            dotListClass=""
                                            itemClass=""
                                            className=''
                                        >
                                            {dataa1?.map((item, i) => (
                                                <div key={i} className='m-2'>
                                                    <ProductCard data={item} />
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between justify-content-md-center align-items-center mt-3'>
                                            <button onClick={handleViewAll} className='explore-btn'>Explore All <ArrowRight size={18} /></button>
                                            <div style={{ right: "5%" }} className='d-flex align-items-center gap-3 position-absolute'>
                                                <img alt='' onClick={() => carouselRef.current.previous()} className='cursor-pointer left-arrow' height={50} draggable={false} src={'https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241119121936218/Group%2033714.png'} />
                                                <img alt='' onClick={() => carouselRef.current.next()} className='cursor-pointer right-arrow' height={50} draggable={false} src={'https://samsel.s3.ap-south-1.amazonaws.com/printon/common_20241119121936218/Group%2033713.png'} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                            }

                        </div >
                    ) : (null)
                }
            </div>
            <Modal isOpen={addCouponCodeModal} toggle={closeCouponCodeModal} size="lg" style={{ borderRadius: '15px' }} className="modal-dialog-centered modal-xs">

                <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: 'white', borderBottom: '1px solid #EEEEEE', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                    <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: 'black' }}>
                        Size Chart
                    </h4>
                    <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={closeCouponCodeModal} />
                </div>
                <ModalBody>
                    <div className=' mh-75'>
                        <Row>
                            <Col lg={6}>
                                <div>
                                    <img className='w-100' src={dataa?.sizeChartImage} alt="Size Chart" />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div>
                                    <div>
                                        <button className={`${inActive ? 'unit-btn' : 'unit-btn1'}`} type='button' onClick={handleInSizeTable}>
                                            IN
                                        </button>
                                        <button className={`${cmActive ? 'unit-btn' : 'unit-btn1'} ms-3`} type='button' onClick={handleCmSizeTable}>
                                            CM
                                        </button>
                                    </div>
                                    <div className='mt-2'>
                                        <p><span className='text-danger'>*</span>{dataa?.sizeChartDescription}</p>
                                    </div>
                                    {inActive &&
                                        <div>
                                            <div className="mt-2">
                                                <table style={{ border: '1px solid #E4510B', width: '100%', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr style={{ background: '#F5F5F5', color: '#333' }}>
                                                            <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '5px', textAlign: 'left' }}>
                                                                <Label>Sizes</Label>
                                                            </th>
                                                            <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '5px', textAlign: 'left' }}>
                                                                <Label>Half Chest (in)</Label>
                                                            </th>
                                                            <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '5px', textAlign: 'left' }}>
                                                                <Label>Body Length (in)</Label>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataa?.sizeChartInch?.map((row, index) => (
                                                            <tr key={index} style={{ borderBottom: '1px solid #E4510B' }}>
                                                                <td style={{ border: '1px solid #E4510B', padding: '5px' }}>
                                                                    <p>{row?.size}</p>
                                                                </td>
                                                                <td style={{ border: '1px solid #E4510B', padding: '5px' }}>
                                                                    <p>{row?.halfChest}</p>
                                                                </td>
                                                                <td style={{ border: '1px solid #E4510B', padding: '5px' }}>
                                                                    <p>{row?.bodyLength}</p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {cmActive &&
                                        <div>
                                            <div className="mt-2">
                                                <table style={{ border: '1px solid #E4510B', width: '100%', borderCollapse: 'collapse' }}>
                                                    <thead>
                                                        <tr style={{ background: '#F5F5F5', color: '#333' }}>
                                                            <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '5px', textAlign: 'left' }}>
                                                                <Label>Sizes</Label>
                                                            </th>
                                                            <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '5px', textAlign: 'left' }}>
                                                                <Label>Half Chest (cm)</Label>
                                                            </th>
                                                            <th style={{ border: '1px solid white', color: 'white', background: '#E4510B', padding: '5px', textAlign: 'left' }}>
                                                                <Label>Body Length (cm)</Label>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataa?.sizeChartCm
                                                            ?.map((row, index) => (
                                                                <tr key={index} style={{ borderBottom: '1px solid #E4510B' }}>
                                                                    <td style={{ border: '1px solid #E4510B', padding: '5px' }}>
                                                                        <p>{row?.size}</p>
                                                                    </td>
                                                                    <td style={{ border: '1px solid #E4510B', padding: '5px' }}>
                                                                        <p>{row?.halfChest}</p>
                                                                    </td>
                                                                    <td style={{ border: '1px solid #E4510B', padding: '5px' }}>
                                                                        <p>{row?.bodyLength}</p>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal >


            {
                modal1 && (
                    <Modal isOpen={modal1} toggle={toggle1} size="lg">
                        <ModalHeader toggle={toggle1} style={{ borderBottom: 'none' }}></ModalHeader>
                        <Form>
                            <ModalBody>
                                <div className='d-block p-5'>
                                    <div>
                                        <h5 style={{ fontweight: '600', font: 'Lato', fontsize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-40px' }}>Upload your files</h5> <br />
                                        <p style={{ font: 'Lato', fontWeight: '400', color: '#858585', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Please Check the guidelines before uploading the files</p> <br />
                                        {/* <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-20px' }}>Supported file formats : PNG, JPG, PSD, AI, PDF, SVG, CDR, DOCX, EPS</p> */}
                                        <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-20px' }}>Supported file formats : PNG, JPG,JPEG,PDF,SVG</p>
                                        <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>(Maximum 5 MB /Minimum 224px x 224px)</p>
                                    </div>
                                    <div>
                                        <Card className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '320px', border: '2px dashed #E4510B', borderRadius: '4px' }} onDrop={handleDrop} onDragOver={handleDragOver}>
                                            {loader ? (
                                                <Spinner color="gray" />
                                            ) : (
                                                <div className="d-block">
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <input
                                                            style={{ display: 'none' }}
                                                            type="file"
                                                            ref={inputRef1}
                                                            // accept=".jpeg,.png,.jpg,.pdf,.doc,.docx,.xls,.xlsx,.txt,.zip,.rar,.csv,.ppt,.pptx,.gif,.svg,.bmp"
                                                            accept=".jpeg,.png,.jpg,.pdf,.svg"
                                                            onChange={handleProfileUpload}
                                                            multiple
                                                        />

                                                        {profileImages.length === 0 ? (
                                                            <div
                                                                style={{ height: '320px', width: '670px' }}
                                                                className='text-center text-primary'
                                                                onClick={() => inputRef1?.current?.click()}
                                                            >
                                                                <>
                                                                    <h1>
                                                                        <RiImageAddFill style={{ color: '#8B8B8B', width: '80px', height: '80px', marginTop: '50px' }} />
                                                                    </h1>
                                                                    <h5 className='mt-3' style={{ color: '#2B2B2D' }}>Drag and drop a file here</h5>
                                                                    <h6 className='mt-3' style={{ color: '#2B2B2D' }}>or</h6>
                                                                    <div className='mt-3' style={{ display: 'flex', justifyContent: "center", textAlign: "center" }}>
                                                                        <h4
                                                                            style={{
                                                                                width: '250px',
                                                                                height: '40px',
                                                                                fontSize: "17px",
                                                                                fontWeight: '700',
                                                                                fontFamily: 'Lato',
                                                                                color: 'white',
                                                                                background: '#E4510B',
                                                                                border: '1px solid #E4510B',
                                                                                lineHeight: '21px'
                                                                            }}
                                                                            className='btn'
                                                                        // onClick={() => inputRef1?.current?.click()}
                                                                        >
                                                                            Select a file
                                                                        </h4>
                                                                    </div>
                                                                </>

                                                            </div>
                                                        ) : (
                                                            <>
                                                                {/* Render the uploaded images or any other content here */}
                                                            </>

                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Card>
                                    </div>
                                </div>
                            </ModalBody >
                        </Form>
                    </Modal >)
            }

            {/* <MobileOTPModal isOpen={modal} toggleOTP={toggle} setCartKey={'yes'} /> */}
            <FinalAuthModal isOpen={isToggle} toggle={toggleEmail} />
            {/* <InteractiveHome /> */}
            <FooterSection />
        </>
    )
}

export default ProductView